<a role="button"
  class="btn btn-outline-primary py-1 fs-6 fw-light"
  *ngIf="book856.length > 0"
  (click)="open(content)"

>
  <i class="fas fa-globe-asia"></i> Electronic Resource
</a>

<p *ngIf="book856.length > 0"></p>

<span
  class="badge bg-light text-dark me-2 my-auto rounded-4 py-1"
  *ngIf="matTypeName"
>
  <img width="20" src="assets/images/Mattype/{{ matTypeName }}.png" />
  <span class="text-center">{{ matTypeName }}</span>
</span>

<!-- <a class="btn btn-light button-radius1 btn-walai-default mr-2" *ngIf="matTypeName"  style="cursor:default;">
  <img width="24" src="assets/images/Mattype/{{matTypeName}}.png" />{{matTypeName}}
</a> -->

<span
  class="badge bg-success fw-light rounded-4 py-2"
  *ngIf="status == 'Available'"
>
  <i class="bi bi-check-circle-fill"></i> Available
</span>

<!-- <a class="btn bg-info button-radius1 btn-walai-default text-white"
        style="cursor:default;"
        *ngIf="status=='Available'">
          Available
        </a> -->

<span
  class="badge bg-warning text-dark fw-normal rounded-4 py-2"
  *ngIf="status == 'Not Available'"
>
  <i class="bi bi-exclamation-circle-fill"></i> Not Available
</span>



<!-- <a class="btn bg-warning  button-radius1 btn-walai-default text-white" 
          style="cursor:default;"
          *ngIf="status=='Not Available'" >
            Not Available
          </a> -->

<ng-template #content let-modal>
  <div class="modal-header py-0">
    <h4 class="modal-title py-0" id="modal-basic-title">Electronic Resource</h4>

    <a
      role="button"
      class="close btn text-primary"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <ng-container *ngFor="let info of book856">
      <div class="row mb-2">
        <div class="col-md-6 mb-0 info-label text-secondary">
          {{ info.label }}
        </div>
        <div class="col-md-6 mb-0 text-walai-default-color">
          <a (click)="addHistory(info.URL)" href="{{ info.URL }}" target="_blank">{{ info.name }}</a>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- <span class="border-end border-1 border-secondary mx-3"></span> -->