import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from '../services/home.service';

@Component({
  selector: 'app-bibitem-card',
  templateUrl: './bibitem-card.component.html',
  styleUrls: ['./bibitem-card.component.css'],
})
export class BibitemCardComponent implements OnInit {
  @Input() bibid: string = '';

  public title: string;
  public author: string;
  public published: string;
  public infoList: any[];
  public locationID: string = '';
  public mattypeID: string;
  public mattyeIcon: String;
  public abstract: string;
  public contents: any[];
  public havesubject: boolean = false;
  public haveISBN: boolean = false;
  public addedAuthor: boolean = false;
  public bookcoverUrl: string;
  public subjectList: any[];
  public bookRelated: any[] = [];

  public bookStatic: any;

  public isBook = true;
  public isSerial = false;
  public isArticle = false;
  loading = true;
  constructor(
    private spinner: NgxSpinnerService,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    this.getInfo(this.bibid);
  }

  // แสดงรายละเอียดเบื้องต้นของหนังสือโดยมีการแยกไว้เป็น Set เพื่อความสะดวก
  public async getInfo(bibid: string) {
    this.spinner.show(); // หมุนๆ

    // ส่งค่าไปยัง API

    (await this.homeService.getBibInfo(bibid)).subscribe(
      (book: any) => {
        // รายละเอียดของหนังสือเช่น ผู้แต่งรูป สำหนักพิมพ์
        this.infoList = book.BookInfo;
        this.loading = false;
        // ตรวจสอบบางค่าเช่น Subject ที่มีการแยกการแสดงผลเพื่อความสวยงามว่ามีอยู่หรือไม่
        // ซึ่งถ้าไม่มีก็จะไม่ต้องแสดง Label ...ไว้สำหรับตัดข้อมูลแยกแสดงผล
        // เช่นหัวเรื่องอาจจะต้องตัด Lebel ด้านซ้ายออกไปด้วยแล้วให้มาแสดงผลใน บันทัดเดียว
        this.infoList.forEach((element) => {
          // เช็คว่ามี Subject หรือไม่

          if (element.FIELD === 'ISBN') {
            this.haveISBN = true;
          }
          if (element.FIELD === 'Published') {
            this.published = element.DATA;
          }
        });

        // หลายค่าจะส่งแยกออกจาก Json หลักเพื่อง่ายต่อการแสดงผล
        this.title = book.Books[0].title;
        this.author = book.Books[0].author;
        this.bookcoverUrl = book.Books[0].bookcover;
        this.abstract = book.Books[0].abstract; // บทคัดย่อ

        // สารบัญ นั้นบรรณารักษ์มักจะคั่นด้วย -- ของแต่ละบทไว้จึงใช้ตัวนี้มาตัดแบ่งการแสดงผล
        const str = book.Books[0].contents;
        if (str) {
          this.contents = str.split('--');
        }
        this.spinner.hide(); // ซ่อนหมุนๆ
      },
      (error) => {
        this.loading = false;
        // ซ่อนหมุนๆ
        this.spinner.hide();
      }
    );
  }
}
