import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RdDialogComponent } from '../../rd-dialog/rd-dialog.component';
import { MemberService } from 'src/app/services/member.service';
import { of, Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-btn-rd',
  templateUrl: './btn-rd.component.html',
  styleUrls: ['./btn-rd.component.css'],
})
export class BtnRDComponent implements OnInit {
  @Input() item: any;
  @Input() BLOCKUSER: boolean;
  @Input() mattypeID: string;
  @Input() NDDUserPrivilege: boolean;
  
  USERID: string = '';
  currentUser: any[] = [];

  NDDTypeOpen = false;
  NDDTypeOpenSubcription: Subscription = new Subscription();
  constructor(
    private router: Router,
    private homeService: HomeService,
    private modalService: NgbModal,
    private memberService: MemberService
  ) {}
  ngOnDestroy(): void {
    if (this.NDDTypeOpenSubcription) {
      this.NDDTypeOpenSubcription.unsubscribe();
    }
  }
  async ngOnInit(): Promise<void> {
    this.NDDTypeOpen = await this.CheckNDDTypeOpen(this.item.LOCATIONID);
    /*ตรวจสอบ user login*/
    this.currentUser =
      JSON.parse(localStorage.getItem('WALAIcurrentUser')) || [];
    if (this.currentUser.length != 0) {
      this.USERID = this.currentUser[0].USERID;
      // this.NDDTypeOpen = await this.CheckNDDTypeOpen(this.item.LOCATIONID);
    }
  }

  async openRD(item: any) {
    let val = encodeURIComponent(this.router.url);
    let url = '<a href=login?redirectURL=' + val + '>เข้าสู่ระบบ?</a>';
    if (this.currentUser.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'สมาชิกห้องสมุดเท่านั้นที่สามารถใช้งานฟังก์ชั่น Resource delivery ได้!',
        footer: url,
      });
    } else {
      if (this.BLOCKUSER == true) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Unable to request delivery: Member is blocked!',
        });
        return ;
      }

      if (this.NDDUserPrivilege == false) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: "Unable to request delivery: This member type is not allowed!",
        });
        return ;
      }


     
      
      this.homeService
        .getNDDRequestTypeOpen(item.ITEMID)
        .subscribe((requesttype: any) => {
          let FlagNDDCOUNTOPEN =
            Number(requesttype[0].REALITEMFLAG) +
            Number(requesttype[0].COPYFLAG) +
            Number(requesttype[0].FILEFLAG);

          if (FlagNDDCOUNTOPEN > 0) {
            // this.dataRD.elemitem = this.itemsList;
            // this.dataRD.selectitem = item.itemid;

            const modalRef = this.modalService.open(RdDialogComponent, {
              size: 'md',
            });
            modalRef.componentInstance.dataRD = item;
            modalRef.componentInstance.mattypeID = this.mattypeID;
            modalRef.componentInstance.USERID = this.USERID;
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Oops...',
              text:
                'item: ' + item.ITEMID + ' ไม่เปิดใช้งาน Resource delivery!',
            });
          }
        });
    }
  }

  CheckNDDTypeOpen(locationidList: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.NDDTypeOpenSubcription = this.memberService
        .NDDTypeOpen(locationidList)
        .pipe(
          switchMap(async (res: any) => {
            switch (res.status) {
              case 200:
                resolve(res.body.NDDtypeOpen);
                break;
              default:
                reject(null);
                break;
            }
          }),
          catchError((e) => {
            reject(null);

            return of([]);
          })
        )
        .subscribe();
    });
  }
}
