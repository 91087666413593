import { of, Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { HomeService } from '../services/home.service';



export interface Count {
  num: string;
}

export interface BasketItem {
  TITLE: string;
  DDFORMAT: number;
  DDFORMATSTR: string;
  URL: string;
  STATUSACTIVE: number;
  CALLNOSHOW: string;
  LOCATIONINITIAL: string;
  LOCATIONACTIVE: string;
  DDINBASKETID: number;
  DDINBASKETDATE: string;
  DDINBASKETTIME: string;
  DDFORMAT1: number;
  PAGE: string;
  COPYNUM: number;
  FEE: number;
  INBASKETST: number;
  USERID: number;
  LOCATIONID: number;
  ITEMID: string;
  JMARCID: string;
  MATTYPEID: number;

  DDFORMATNAME: string;
  checked: boolean;
  closeDD: number;
}

export interface NDDRequest {
  locationID:number;
  locationName:string;
  SHIPPINGCOST:number;
  totalfee:number;
  total:number;
  BasketItems:BasketItem[]
}

export interface NDDBasketData {
  DDINBASKETID: string;
  DDFORMAT: string;
  USERID: string;
  ITEMID: string;
  JMARCID: string;
  PAGE: string;
  COPYNUM: string;
  LOCATIONID: string;
  FEE: string;
  MATTYPEID: string;
}


export interface NDDBasketlistResponse {
  Counts: Count[];
  RealItemCopy: BasketItem[];
  File: BasketItem[];
}

export interface NDDtypeOpen {
  LOCATIONID: number;
  LOCATIONNAME: string;
  HOMEDELFLAG: number;
  POINTDELFLAG: number;
  FILEDELFLAG: number;
}

export interface NDDtypeOpenResponse {
  NDDtypeOpen: NDDtypeOpen[];
}

export interface Delivertime {
  ROUNDDATE: string;
}

export interface DelivertimeResponse {
  delivertime: Delivertime[];
}

export interface NDDShippingCostModel {
  LOCATIONID: number;
  SHIPPINGCOST: number;
}

export class NDDFunction {
  NDDFeeSubcription: Subscription = new Subscription();
  calPageCountAndFeeSubcription: Subscription = new Subscription();
  constructor(private homeService: HomeService) {}

  //คำนวนหาจำนวนหน้า
  calPageCount(page) {
    return new Promise<number>((resolve, reject) => {
      this.calPageCountAndFeeSubcription = this.homeService
        .NDDCheckPageformat(page)
        .pipe(
          switchMap(async (res: any) => {
            switch (res.status) {
              case 200:
                if (res.body[0].result == 'True') {
                  resolve(res.body[0].countpage);
                  //   this.getNDDFee(this.type, this.pageCount);
                } else {
                  reject(0);
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.body[0].message,
                  });
                }
                break;
              default:
                reject(0);
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Page must be number',
                });
                break;
            }
          }),
          catchError((e) => {
            reject(0);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Unable to connect to API',
            });
            return of([]);
          })
        )
        .subscribe();
    });


    
  }

  getNDDFee(type: string, page: number,copyCount:number) {
    return new Promise<string>((resolve, reject) => {
      this.NDDFeeSubcription = this.homeService
        .GetNDDFee(type, page)
        .pipe(
          switchMap(async (res: any) => {
            switch (res.status) {
              case 200:
                if (res.body[0].result == 'True') {
                  resolve(
                    (
                      res.body[0].Fee * copyCount
                    ).toString()
                  );

                } else {
                  reject('');
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to connect to API',
                  });
                }

                break;
              default:
                reject('');
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Unable to connect to API',
                });
                break;
            }
          }),
          catchError((e) => {
            reject('');
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Unable to connect to API',
            });
            return of([]);
          })
        )
        .subscribe();
    });
  }


  
}
