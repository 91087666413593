import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  TagCloudDataAdd,
  TagCloudModel,
  reviewData,
  reviewDataAdd,
} from 'src/app/models/walai';
import { HomeService } from 'src/app/services/home.service';
import { textChangeRangeIsUnchanged } from 'typescript';
import Swal from 'sweetalert2';

import { DataService } from 'src/app/services/data.service';
import { MemberService } from 'src/app/services/member.service';
declare var $: any;
@Component({
  selector: 'app-bib-tag-cloude',
  templateUrl: './bib-tag-cloude.component.html',
  styleUrls: ['./bib-tag-cloude.component.css'],
})
export class BibTagCloudeComponent implements OnInit {
  @Input() bibid: string;
  currentUser: any[] = [];
  public locationID: string = '';

  ntkLocation = '';
  nttLocation = '';
  USERID: string = '';
  // editStatus = false;
  addStatus = true;
  REVIEWID: string = '';
  tagCloudData: TagCloudModel[] = [];

  MytagCloud: any[] = [];

  addFG: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private homeService: HomeService,
    private spinner: NgxSpinnerService,
    private memberService: MemberService,
    private activateRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private data: DataService
  ) {
    this.addFG = this.fb.group({
      value: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.currentUser =
      JSON.parse(localStorage.getItem('WALAIcurrentUser')) || [];
    if (this.currentUser.length > 0) {
      this.USERID = String(this.currentUser[0].USERID);
      this.loadMytag();
    }

    try {
      this.locationID = localStorage.getItem('locationIDMain'); //location หลัก

      if (this.locationID != '') {
        this.ntkLocation = '|LOCATION';
        this.nttLocation = '|' + this.locationID;
      }
    } catch (error) {}

    this.load();
  }

  loadMytag() {
    this.addStatus = true;
    this.spinner.show(); // หมุนๆ
    this.homeService
      .GetUserTagInBibItem(this.USERID)
      .subscribe((data: any[]) => {
        this.MytagCloud = data;
        this.spinner.hide(); // ซ่อนหมุนๆ
      });
  }

  load() {
    this.addStatus = true;
    this.spinner.show(); // หมุนๆ
    this.homeService
      .getTagCloud(this.bibid)
      .subscribe((data: TagCloudModel[]) => {
        this.tagCloudData = data;
        this.spinner.hide(); // ซ่อนหมุนๆ
      });
  }

  AddTag() {
    var tagVal = this.addFG.controls.value.value;
    this.ConfirmAddTag(tagVal, 1);
  }

  ConfirmAddTag(tagVal, type) {
    let libFlag = '0';
    if (
      this.currentUser[0].ADMINFLAG === 1 ||
      this.currentUser[0].LIBRARIANFLAG === 1
    ) {
      libFlag = '1';
    }

    // if(type==2){
    //   libFlag = '1';
    // }
    const objReviewDataAdd: TagCloudDataAdd = {
      USERID: this.USERID,
      BIBID: this.bibid,
      Tag: tagVal,
      LibFLAG: libFlag,
    };

    this.memberService
      .addMemberTagCloud(objReviewDataAdd)
      .subscribe((getData: any) => {
 console.log(getData)
        if (getData.status === true) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: getData.message,
            showConfirmButton: false,
            timer: 1500,
          });
          if (type == 1) {
            this.addFG.controls.value.setValue('');
          }

          this.load();
          this.loadMytag();
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: getData.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }
}
