<div class="modal-header">
  <h4 class="modal-title mt-auto mb-auto w-100" id="modal-basic-title">
    {{ this.TextRD[0].TitleModal }}
  </h4>
  <a class="close h1 mt-auto mb-auto a-walai" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </a>
</div>

<div class="modal-body border-bottom-0">
  <form class="row g-3" [formGroup]="FG">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          [hidden]="this.FlagREALITEM === 0"
          class="nav-link active"
         
          id="nav-home-tab"
          data-bs-toggle="tab"
          data-bs-target="#"
          type="button"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          (click)="changeType('NDDRealItemFee')"
        >
          <b>Real Item</b>
        </button>
        <button
          [hidden]="this.FlagCOPY === 0"
          class="nav-link"
          id="nav-profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#"
          type="button"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="false"
          (click)="changeType('NDDCopyFee')"
        >
          <b>Copy</b>
        </button>
        <button
          [hidden]="this.FlagFILE === 0"
          class="nav-link"
          id="nav-contact-tab"
          data-bs-toggle="tab"
          data-bs-target="#"
          type="button"
          role="tab"
          aria-controls="nav-contact"
          aria-selected="false"
          (click)="changeType('NDDFileFee')"
        >
          <b>File</b>
        </button>
      </div>
    </nav>
    <div class="" id="nav-tabContent">
      <div
        class=""
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="col-12" *ngIf="type != 'NDDRealItemFee'">
          <label for="basic-url" class="form-label">Page:</label>
          <div class="form-check ps-4 ms-2">
            <input
              (change)="onPageChange($event.target.value)"
              class="form-check-input"
              type="radio"
              name="flexRadioPage"
              id="flexRadioDefault1"
              value="1"
              [(ngModel)]="allPage"
              [checked]="allPage"
            />
            <label class="form-check-label" for="flexRadioDefault1">
              All
            </label>
          </div>
          <div class="form-check ps-4 ms-2">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioPage"
              id="flexRadioDefault2"
              [(ngModel)]="allPage"
              (change)="onPageChange($event.target.value)"
              value="0"
              [checked]="!allPage"
            />
            <label class="form-check-label" for="flexRadioDefault2">
              Page Range
            </label>
          </div>
          <div class="ps-4 ms-2">
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="formControlpage"
              placeholder="Page numbers"
              (change)="calPageCountAndFee()"
            />
            <label
              for="exampleFormControlInput1"
              class="form-label mt-1 text-muted"
              ><small
                >Enter page numbers and/or page ranges separated by commas (e.g.
                1-10, 20, 30, I, II, III, V)</small
              >
            </label>
          </div>
        </div>
        <div
          class="col-12"
          *ngIf="type != 'NDDRealItemFee'"
        >
          <div class="input-group input-group-sm mb-3">
            <span  *ngIf="type == 'NDDCopyFee'" class="input-group-text" id="inputGroup-sizing-sm">{{'Quantity'|translate}}: </span>
            <input *ngIf="type == 'NDDCopyFee'"
              min="1"
              type="number"
              class="form-control"
              aria-label="Sizing example input"
              formControlName="copyCount"
              aria-describedby="inputGroup-sizing-sm"
              (change)="calPageCountAndFee()"
            />
            <span  *ngIf="type == 'NDDCopyFee'" class="input-group-text" id="inputGroup-sizing-sm">copy</span>
            <!-- <button (click)="calPageCountAndFee()" type="button" class="btn btn-primary ms-2"><i class="bi bi-calculator"></i> Calculate Fee</button> -->
          </div>
        </div>

        <div class="col-12">
          <div *ngIf="Fee!=''" class="alert alert-primary" role="alert">
            <b>{{'Fee'|translate}}: {{ Fee|number }} ฿ </b>
            <small *ngIf=" this.type!='NDDFileFee'"> {{'Shipping is not included in the offer price.'|translate}}</small>
          </div>
        </div>
      </div>
      <!-- <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...</div>
      <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div> -->
    </div>
  </form>

  <!-- 
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)" class="nav-tabs">
    <li ngbNavItem="1" [hidden]="this.FlagREALITEM === 0">
      <a ngbNavLink>Real Item</a>
      <ng-template ngbNavContent let-active>
    
        <div class="card-body mt-4">
          <p class="h5 d-inline-block btn-walai-default w-100 text-center py-2">Fee: <span class="px-1">0.00</span> ฿
          </p>
          <p class="wl-font-color-gray-v1 text-right">Shipping is not included in the offer price</p>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="2" [hidden]="this.FlagCOPY === 0">
      <a ngbNavLink>Copy</a>
      <ng-template ngbNavContent let-active>
    
        <div class="card-body mt-4">
          <p class="h5 d-inline-block btn-walai-default w-100 text-center py-2">Fee: <span class="px-1">0.00</span> ฿
          </p>
          <p class="wl-font-color-gray-v1 text-right">Shipping is not included in the offer price</p>

          <div class="h5 text-center mt-4">Page: <input [value]="page" type="text" 
              class="px-1 form-control d-inline-block w-25"></div>
              <p>(Format 1-10, 20, 30, I, II, III, V)</p>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="3" [hidden]="this.FlagFILE === 0" >
      <a ngbNavLink>File</a>
      
      <ng-template ngbNavContent let-active>
     
        <div class="card-body mt-4">
          <p class="h5 d-inline-block btn-walai-default w-100 text-center py-2">Fee: <span class="px-1">0.00</span> ฿
          </p>
          <p class="wl-font-color-gray-v1 text-right">Shipping is not included in the offer price</p>

          <div class="h5 text-center mt-4">Page: <input type="text" [value]="page"
              class="px-1 form-control d-inline-block w-25"></div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div> -->
</div>

<div class="modal-footer justify-content-between p-0 wl-aling-footer">
  <a  
    class="btn wl-decoration-none rounded-0 m-0 pt-3 col btn-success"
    (click)="AddToBasket(1)"
    ><span class="ml-auto mr-auto h5 pt-2">Add to basket</span></a
  >

  <a   (click)="AddToBasket(2)"
    class="btn wl-decoration-none rounded-0 m-0 pt-3 col btn-primary"
  
  >
    <span class="ml-auto mr-auto h5">Request</span>
  </a>
</div>
