<button
  type="button"
  class="btn btn-primary btn-sm py-1 fw-light shadow me-2"
  data-bs-toggle="modal"
  data-bs-target="#exportModalLong"
>
  <i class="bi bi-download"></i> Export
</button>
<!-- Modal -->
<div
  class="modal fade"
  id="exportModalLong"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <i class="bi bi-download"></i> Export
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body" style="text-align: left">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link active a-walai" (click)="export('MARC')">
              MARC
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active a-walai" (click)="export('MARCISO2709')">
              ISO 2709
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active a-walai" (click)="export('Bibliography')">
              Bibliography
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active a-walai" (click)="export('ENDNOTE')">
              ENDNOTE
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active a-walai" (click)="export('DublinCore')">
              Dublin Core
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active a-walai" (click)="export('APAStyle')">
              APA Style
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active a-walai" (click)="export('RISForZotero')">
              RIS for Zotero
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link active a-walai"
              (click)="export('RISForMendeley')"
            >
              RIS for Mendeley
            </a>
          </li>
        </ul>

        <div>
          <div class="card card-body">
            <div *ngIf="type !== 'MARC'">
              <p *ngFor="let item of data">
                {{ item.data }}
              </p>
            </div>

            <div *ngIf="type == 'MARC'" class="table-responsive">
              <table class="table">
                <thead class="table-primary">
                  <tr>
                    <th scope="col">Tag</th>
                    <th scope="col">Indicator1</th>
                    <th scope="col">Indicator2</th>
                    <th scope="col" class="text-center">Subfield</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tag of marc">
                    <th scope="row">{{ tag.TagID }}</th>
                    <td class="text-center">{{ tag.Indicator1 }}</td>
                    <td class="text-center">{{ tag.Indicator2 }}</td>
                    <td class="text-justify">
                      <p
                        style="max-width: 500px"
                        [innerHTML]="tag.Subfield"
                      ></p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveAsTextFile()"
        >
          <i class="bi bi-save"></i> Save
        </button>
      </div>
      <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div> -->
    </div>
  </div>
</div>
