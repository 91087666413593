<!-- Button trigger modal -->
<button
  type="button"
  class="btn btn-primary btn-sm py-1 fw-light shadow me-2"
  data-bs-toggle="modal"
  data-bs-target="#HoldingModal"
>
  Holding
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="HoldingModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Holding</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>

      <div class="modal-body p-0">
        <ul class="list-group rounded-0" *ngFor="let h of holdings">
          <li class="list-group-item active" aria-current="true">
            {{ h.Location }}
          </li>
          <li class="list-group-item" *ngFor="let data of h.HoldingData">
            {{ data.Subfield }}
          </li>
        </ul>
      </div>

      <div class="modal-footer">
        <a
          role="button"
          data-bs-dismiss="modal"
          class="btn btn-outline-secondary btn-sm py-1"
        >
          <span class="ml-auto mr-auto">Close</span></a
        >
      </div>
    </div>
  </div>
</div>
