import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {  AutoCompleteWord, AutoCompleteResponse,AutoComplete } from 'src/app/models/walai';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {
  private baseUrl = '';
  header = new HttpHeaders();
  val1: AutoComplete;
  constructor(private http: HttpClient) { this.header.append('Content-Type', 'application/json'); }

  // search(filter: {word: string} = {word: ''}, page = 1): Observable<autoCompleteResponse> {


  //   this.baseUrl = `${environment.apiurl}/api/autosuggest`;
   
  //   this.val1 = new autoComplete();
  //   this.val1.Keyword ="art";
  //   this.val1.Location ="1";
  //   return this.http.post<any>(this.baseUrl, this.val1, { headers: this.header })
  //   .pipe(
  //     tap((response: autoCompleteResponse) => {
  //       response.results = response.results
  //       .map(user => new autoCompleteWord(user.word))
  //         // // Not filtering in the server since in-memory-web-api has somewhat restricted api
  //         .filter(user => user.word.includes(filter.word))

  //       return response;
  //     })
  //     );
  // }

  autoSuggest(val: any) {
    this.baseUrl = `${environment.apiurl}/api/autosuggest/10`;
    return this.http.post<any>(this.baseUrl, val, { headers: this.header });
  }


}
