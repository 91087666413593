import { Pipe, PipeTransform } from '@angular/core';
import { Mattype } from '../models/walai';
import { HomeService } from '../services/home.service';

@Pipe({
  name: 'mattName'
})
export class MattNamePipe implements PipeTransform {
  constructor(private homeService: HomeService) { }

  async transform(b: any): Promise<string> {
    const typeid = b.MATTYPENAME;
    const bibid = b.id;
    const matType = new Mattype();
    let matName: string='...';
    return new Promise((resolve, reject) => {
      if (Number(typeid) !== 1 && Number(typeid) !== 2) {
        matName = matType.GetMattypeName(typeid);
        resolve(`${matName}`);
      } else {
        this.homeService.getBookNewMatType(bibid).subscribe((mid: any) => {
          matName = matType.GetMattypeName(mid);
          resolve(`${matName}`);
        });
      }

    });
  }
}
