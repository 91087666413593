import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Keywords } from 'src/app/models/walai';
import { HomeService } from 'src/app/services/home.service';
import { SearchService } from 'src/app/services/search.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-book-cover-share',
  templateUrl: './book-cover-share.component.html',
  styleUrls: ['./book-cover-share.component.css'],
})
export class BookCoverShareComponent implements OnInit {
  @Input() bookCoverURL: string;
  @Input() id: string;
  @Input() cssStyle: string;
  cover = 'assets/images/bookcover/loading.jpg';
  IsImageError:boolean = false;

  constructor(
    private searchService: SearchService,
    private fc: UtilityService,
    private http: HttpClient,
    private homeS: HomeService
  ) {
    // this.cover = 'assets/images/bookcover/loading.jpg';
    // this.fc.checkIfImageExists(this.bookCoverURL, (exists) => {
    //   if (!exists) {
    //     this.doSomethingOnError();
    //   } else {
    //     this.cover = this.bookCoverURL;
    //   }
    // });
    // if(this.id=='b00253848'){
    //   console.log('sdfgdfgfdg'+  this.bookCoverURL)
    // }
  }

  ngOnInit() {
    this.cover = 'assets/images/bookcover/loading.jpg';

    try {
      if (this.id.indexOf('j') != -1) {
        //Article

        this.homeS.bookcover(this.id).subscribe(
          (results: any) => {
            this.cover = results;
            this.fc.checkIfImageExists( this.cover, (exists) => {
              if (!exists) {
                this.doSomethingOnError();
              } else {
                this.cover = this.bookCoverURL;
              }
            });
          
          },
          (error) => {
            this.cover = 'assets/images/bookcover/noimage.jpg';
          }
        );
      } else {
        //book
        this.fc.checkIfImageExists(this.bookCoverURL, (exists) => {
          if (!exists) {
            this.doSomethingOnError();
          } else {
            this.cover = this.bookCoverURL;
          }
        });
      }
    } catch (error) {
      this.fc.checkIfImageExists(this.bookCoverURL, (exists) => {
        if (!exists) {
          this.doSomethingOnError();
        } else {
          this.cover = this.bookCoverURL;
        }
      });
    }
  }

  doSomethingOnError() {
    this.keywordSearch();
  }

  checkWALAICOVER(results: any) {
    let isbn13 = this.fc.getISBN(results.Display[0].ISBNISSN);
    fetch(
      'https://opac-api.wu.ac.th/synccoverbook/v1/api/GetBookCover/' + isbn13
    )
      .then((response) => response.text())
      .then((result) => {
        this.checkCover(result, results);
      })
      .catch((error) => this.getCoverByLang(results));
  }

  checkCover(uri: string, results: any) {
    //  console.log(uri)
    if (uri == 'Invalid ISBN' || uri == '' || uri == 'ไม่พบเลข ISBN') {
      this.getCoverByLang(results);
    } else {
      this.cover = uri;
      //   console.log('Cover :' + this.cover);
      this.fc.checkIfImageExists(this.cover, (exists) => {
        if (!exists) {
          this.getCoverByLang(results);
        }
      });
    }
  }

  //Function การสืบค้นที่มีการไปดึงผลการสืบค้นจาก API
  keywordSearch() {
    this.cover = 'assets/images/bookcover/noimage.jpg';
    var keyword_ = new Keywords();
    keyword_.CurrentPage = 1;
    keyword_.RowPerPage = 1;
    keyword_.Ntk = 'ID';
    keyword_.Ntt = this.id;
    keyword_.Orderby = 'RELEVANCE';
    keyword_.Nto = '';

    this.searchService.keywordsearch(keyword_).subscribe(
      (results: any) => {
        if (results) {
          if (results.Display.length > 0) {
            let useWalaiBookCover = `${environment.useWalaiBookCover}`;
            if (useWalaiBookCover == '1') {
              this.checkWALAICOVER(results);
            } else {
              this.getCoverByLang(results);
            }
          }
        } else {
          this.cover = 'assets/images/bookcover/noimage.jpg';
        }
      },
      (error) => {
        this.cover = 'assets/images/bookcover/noimage.jpg';
      }
    );
  }

  getCoverByLang(results: any) {
    if (results.Display[0].LANG == 'ภาษาไทย') {
      this.cover = this.fc.getBookTha(results.Display[0].ISBNISSN);

      this.fc.checkIfImageExists(this.cover, (exists) => {
        if (!exists) {
          this.cover = 'assets/images/bookcover/noimage.jpg';
        }
      });
    } else {
      this.cover = this.fc.getBookEng(results.Display[0].ISBNISSN);

      this.fc.checkIfImageExists(this.cover, (exists) => {
        if (!exists) {
          this.cover = 'assets/images/bookcover/noimage.jpg';
        }
      });
    }
  }


  imagerror(e){
    e.target.hidden = true;
    this.IsImageError = true;
  }
}
