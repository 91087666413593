import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Collection } from 'src/app/models/walai';
import { MemberService } from 'src/app/services/member.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css'],
})
export class CollectionComponent implements OnInit {
  @Input() public bibid: string;
  @Input() public page: string; // หน้าที่ส่งมา เช่น bibitem



  collectionList: any[] = [];
  currentUser: any[] = [];
  USERID = '';

  addCollectionForm: UntypedFormGroup;

  constructor(private memberService: MemberService, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.defineaddCollectionForm();

    this.currentUser = JSON.parse(localStorage.getItem('WALAIcurrentUser')) || [];
    //  if(this.currentUser.length==0){   this.route.navigateByUrl('home');}
    if (this.currentUser.length > 0) {
      this.USERID = this.currentUser[0].USERID;
      this.displayCollection();
    }


  }



  // แสดงรายการ Collection
  displayCollection() {
    if (this.currentUser.length > 0) {
      this.memberService
        .opacMemberGetCollection(this.USERID, this.bibid)
        .subscribe((collection: any) => {
          if (collection) {
            this.collectionList = collection;
          } else {
          }
        });
    }
  }

  // เพิ่มข้อมูลลงใน Collection
  addbib(id) {
    const collection = new Collection();
    collection.BIBID = this.bibid;
    collection.MLID = id;
    this.memberService
      .opacMemberCollectionAddBib(collection)
      .subscribe((status: boolean) => {
        if (status) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your list has been saved',
            showConfirmButton: false,
            timer: 1500,
          });

          this.displayCollection();
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'ไม่สามารถเพิ่มข้อมูลได้กรุณาลองใหม่อีกครั้ง',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }

  // ลบ bib ออกจาก Collection
  deletebib(id) {
    this.memberService
      .opacMemberCollectionDeleteBib(id, this.bibid)
      .subscribe((status: boolean) => {
        if (status) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your bib has been delete',
            showConfirmButton: false,
            timer: 1500,
          });

          this.displayCollection();
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'ไม่สามารถลบข้อมูลได้กรุณาลองใหม่อีกครั้ง',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }

  defineaddCollectionForm() {
    this.addCollectionForm = this.fb.group({
      addCollectionForm_name: new UntypedFormControl('', Validators.required),
    });

  }


  // เพิ่ม Collection ใหม่
  addCollection() {
    const collection = new Collection();
    collection.UserID = this.USERID;
    collection.NAME = this.addCollectionForm.controls.addCollectionForm_name.value;


    this.memberService.opacMemberCollectionAdd(collection).subscribe((getData: any) => {
      this.addCollectionForm.controls.addCollectionForm_name.setValue('');
    //  $('#newCollectionModal').modal('hide');

      this.displayCollection();

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'เพิ่มข้อมูลเรียบร้อยแล้ว',
        showConfirmButton: false,
        timer: 1500
      });

    }, error => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'ไม่สามารถเพิ่มข้อมูลได้! กรุณาลองใหม่อีกครั้ง',
        showConfirmButton: false,
        timer: 1500
      });
    });
    
  }


}
