import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import {
  bookcaseMap,
  bookcoverOnShelf,
  bookshelfinfo,
  bookshelfpoint,
  itembookshelfinfo,
} from '../models/modelVirtualbook';
import {
  book856,
  callNoResponse,
  category,
  EducationalResources,
  FriendModel,
  HoldingAvailableIssues,
  keywordModel,
  locationCollectionResponse,
  returnCategorySubCategory,
  returnSubCategory,
  SearchTool,
  TagCloudModel,
} from '../models/walai';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    //authorization: ''
  }),
};

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  getBlockNotice(USERID: any) {
    throw new Error('Method not implemented.');
  }
  private baseUrl = '';
  header = new HttpHeaders();

  constructor(private http: HttpClient) {
    //this.header.append('authorization','');
    this.header.append('Content-Type', 'application/json');
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // just a test ... more could would go here
    return throwError(() => err);
  }

  getLocation() {
    this.baseUrl = `${environment.apiurl}/api/Location`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getLocationName(id) {
    this.baseUrl = `${environment.apiurl}/api/GetLocationName/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<string>(this.baseUrl, httpOptions);
  }

  getTop(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/SuggestBook/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  //   function tryLogin(...put here your args): Observable<any> {
  //     return this.webService.isTokenValid(jsonStr);
  //     // Yep, just this. Usually services don't handle logic.
  //  }

  async getBibInfo(id) {
    this.baseUrl = `${environment.apiurl}/api/BookInfo/${id}`;
    const response = await this.http.get<any[]>(this.baseUrl, httpOptions);
    return response;
  }
  // getBibInfo(id) {
  //   this.baseUrl = `${environment.apiurl}/api/BookInfo/${id}`;
  //   return this.http.get<any[]>(this.baseUrl, httpOptions);
  // }

  //   getItems(id){
  //   this.baseUrl = `${environment.apiurl}/api/GetItems/${id}`;
  //   const response =   this.http.get<any[]>(this.baseUrl, httpOptions);
  //   return   response;
  // }

  getItems(id): Observable<any> {
    return this.http
      .get<any>(`${environment.apiurl}/api/GetItems/${id}`, {
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }

  /*สำหรับเช็คว่ามีข้อมูลของรายการ map (virtual bookshelf)*/
  async getItemShowmap(id) {
    this.baseUrl = `${environment.apiurl}/api/GetItem/${id}`;
    const response = await this.http.get<any[]>(this.baseUrl, httpOptions);
    return response;
  }

  getBookRelated(id, num): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GetBookRelated/${id}/${num}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getAllTitleInIssue(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GetAllTitleInIssue/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  

  getBookStatic(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/BibStatic/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getBookNewMatType(id) {
    this.baseUrl = `${environment.apiurl}/api/GetBookNewMatType/${id}`;
    return this.http.get<String>(this.baseUrl, httpOptions);
  }

  getBook856(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GetBook856/${id}`;
    return this.http.get<book856[]>(this.baseUrl, httpOptions);
  }

  getMARC(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/MARC/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getItemStatus(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GetItemStatus/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getParameter(PARAMETERKEY): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GetOPACConfig/${PARAMETERKEY}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  public getParameterPipe(PARAMETERKEY): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GetOPACConfig/${PARAMETERKEY}`;
    return this.http
      .get<any>(this.baseUrl, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  getLang(): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GetLang/`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getMaterialType(): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/MaterialType/`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getCallNumber(): Observable<callNoResponse> {
    this.baseUrl = `${environment.apiurl}/api/GetCallNumber/`;
    return this.http.get<callNoResponse>(this.baseUrl, httpOptions);
  }

  getCollection(): Observable<locationCollectionResponse[]> {
    this.baseUrl = `${environment.apiurl}/api/GetCollection/`;
    return this.http.get<locationCollectionResponse[]>(
      this.baseUrl,
      httpOptions
    );
  }

  getCatagory(): Observable<category[]> {
    this.baseUrl = `${environment.apiurl}/api/getCatagory/`;
    return this.http.get<category[]>(this.baseUrl, httpOptions);
  }

  getSubCatagory(id): Observable<returnCategorySubCategory> {
    this.baseUrl = `${environment.apiurl}/api/getSubCatagory/${id}`;
    return this.http.get<returnCategorySubCategory>(this.baseUrl, httpOptions);
  }

  getSubject(id): Observable<returnSubCategory> {
    this.baseUrl = `${environment.apiurl}/api/getSubject/${id}`;
    return this.http.get<returnSubCategory>(this.baseUrl, httpOptions);
  }

  checkSerial(id): Observable<boolean> {
    this.baseUrl = `${environment.apiurl}/api/checkSerial/${id}`;
    return this.http.get<boolean>(this.baseUrl, httpOptions);
  }

  getMarcHolding(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/getMarcHolding/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getHolding(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/getHolding/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getSerialItemForOPAC(id): Observable<HoldingAvailableIssues[]> {
    this.baseUrl = `${environment.apiurl}/api/GetSerialItemForOPAC/${id}`;
    return this.http.get<HoldingAvailableIssues[]>(this.baseUrl, httpOptions);
  }

  getBookCoverAll(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/getBookCoverAll/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  /*virtual  bookshelf */
  getbookshelfinfo(id): Observable<bookshelfinfo[]> {
    //แสดงข้อมูล shelf
    this.baseUrl = `${environment.apiurl}/api/ShowBookShelfInfo/${id}`;
    return this.http.get<bookshelfinfo[]>(this.baseUrl, httpOptions);
  }

  showbookcasemap(id): Observable<bookcaseMap[]> {
    //แสดงตำแหน่งตู้บน map
    this.baseUrl = `${environment.apiurl}/api/ShowBookCaseMap/${id}`;
    return this.http.get<bookcaseMap[]>(this.baseUrl, httpOptions);
  }

  showbookshelfpoint(id): Observable<bookshelfpoint> {
    //แสดงตำแหน่งชั้นหนังสือบนตู้
    this.baseUrl = `${environment.apiurl}/api/ShowBookShelfPoint/${id}`;
    return this.http.get<bookshelfpoint>(this.baseUrl, httpOptions);
  }

  showiteminfo(id): Observable<itembookshelfinfo[]> {
    //แสดงข้อมูล item
    this.baseUrl = `${environment.apiurl}/api/ShowItemInfo/${id}`;
    return this.http.get<itembookshelfinfo[]>(this.baseUrl, httpOptions);
  }

  showbookcovers(id): Observable<bookcoverOnShelf[]> {
    //แสดงข้อมูลปก bib (vb)
    this.baseUrl = `${environment.apiurl}/api/showBookCovers/${id}`;
    return this.http.get<bookcoverOnShelf[]>(this.baseUrl, httpOptions);
  }

  bookcover(id): Observable<any> {
    //แสดงรูปปก bib
    this.baseUrl = `${environment.apiurl}/api/BookCover/${id}`;
    return this.http.get<any>(this.baseUrl, httpOptions);
  }

  getpinitem(id): Observable<any> {
    //pin item
    this.baseUrl = `${environment.apiurl}/api/PinItem/${id}`;
    return this.http.get<any>(this.baseUrl, httpOptions);
  }

  pinitem(id, barcode): Observable<any> {
    //pin item
    this.baseUrl = `${environment.apiurl}/api/PinItem?userid=${id}&barcode=${barcode}`;
    return this.http.post<any>(this.baseUrl, httpOptions);
  }

  getBookCoverPath(): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/BookCoverURL`;
    return this.http.get<any>(this.baseUrl, httpOptions);
  }

  public getBookReviewLibrarain(bibid: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiurl}/api/bookReviewLibrarain/${bibid}`,
      httpOptions
    );
  }

  public getBookReviewMember(bibid: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiurl}/api/bookReviewMember/${bibid}`,
      httpOptions
    );
  }

  getgenDublinCoreMetadata(bibid: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiurl}/api/DublinCoreMetadata/${bibid}`,
      httpOptions
    );
  }



  async bibExportList(idList, type): Promise<Observable<any>> {
    //console.log(id)
    this.baseUrl = `${environment.apiurl}/api/ExportList/${idList}/${type}`;
    return await this.http.get<any[]>(this.baseUrl, httpOptions);
  }


  bibExport(id, type): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/Export/${id}/${type}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getFilePath(type: string): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/getFilesURL/${type}`;
    return this.http.get<any>(this.baseUrl, httpOptions);
  }

  getEducationalResources(): Observable<EducationalResources[]> {
    this.baseUrl = `${environment.apiurl}/api/EducationalResources`;
    return this.http.get<any>(this.baseUrl, httpOptions);
  }

  getSearchTool(): Observable<SearchTool[]> {
    this.baseUrl = `${environment.apiurl}/api/SearchTool`;
    return this.http.get<any>(this.baseUrl, httpOptions);
  }

  /**Resource delivery */

  CheckOpenRD(): Observable<boolean> {
    this.baseUrl = `${environment.apiurl}/api/CheckOpenRD`;
    return this.http.get<boolean>(this.baseUrl, httpOptions);
  }

  public GetNDDFee(type: string, page: number): Observable<any> {
    return this.http
      .get<any>(`${environment.apiurl}/api/` + type + '/' + page, {
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }

  getNDDRequestTypeOpen(id: string): Observable<any[]> {
    this.baseUrl = `${environment.apiurl}/api/NDDrequestTypeOpen/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  GetNDDTypeOpen(id: string): Observable<any[]> {
    this.baseUrl = `${environment.apiurl}/api/NDDTypeOpen/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  

  getNDDTypeOpen(id: string): Observable<any[]> {
    this.baseUrl = `${environment.apiurl}/api/NDDTypeOpen/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  public NDDCheckPageformat(page: number): Observable<any> {
    return this.http
      .get<any>(`${environment.apiurl}/api/NDDCheckPageformat?page=${page}`, {
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }

  addOpacview(val: any) {
   // console.log(val)
    this.baseUrl = `${environment.apiurl}/api/opacview`;
    return this.http.post<any>(this.baseUrl, val, { headers: this.header });
  }

  public checkWALAICOVER(isbn: string): Observable<any> {
      this.baseUrl = 'https://opac-api.wu.ac.th/synccoverbook/v1/api/GetBookCover/'+isbn;
      return this.http.get<any>(this.baseUrl, httpOptions);
  }


  getTagCloud(id): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GenTagByBIBID/${id}`;
    return this.http.get<TagCloudModel[]>(this.baseUrl, httpOptions);
  }

  GetUserTagInBibItem(userid): Observable<any[]> {
    this.baseUrl = `${environment.apiurl}/api/GetUserTagInBibItem/${userid}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getTagCloudAll(): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GetTagAll`;
    return this.http.get<TagCloudModel[]>(this.baseUrl, httpOptions);
  }

  GetMemberByKeyword(keyword:keywordModel): Observable<FriendModel[]> {
    this.baseUrl = `${environment.apiurl}/api/GetMemberByKeyword`;
    return this.http.post<FriendModel[]>(this.baseUrl, keyword, { headers: this.header });
  }

}
