import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import {  returnCategorySubCategory,subCategory,subject,returnSubCategory } from '../../models/walai';
declare var $: any;
@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css']
})
export class SubcategoryComponent implements OnInit {
  returnCategorySubCategory:returnCategorySubCategory;
  subCategorys:subCategory[];

  returnSubCategory:returnSubCategory;
 cursubCategory:subCategory;
 CATIMG:string ='';
 CATNAMETHAI:string='';
 CATNAMEENG:string='Loading...';
 DESCRIPTION:string='';

 CATEGRPNAMETHAI:string='';
  subjects:subject[];
  locationID: string;
  public cateid: string;
  constructor(private homeService: HomeService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.locationID = localStorage.getItem('locationIDMain'); // location หลัก
    this.cateid = this.route.snapshot.queryParamMap.get('cateid');
    this.getSubCategory(this.cateid);
  }

  getSubCategory(id:string){
    this.homeService.getSubCatagory(id).subscribe((returnCategorySubCategory:returnCategorySubCategory) => {
      this.returnCategorySubCategory = returnCategorySubCategory;
      this.CATIMG =   this.returnCategorySubCategory.CATIMG;
      this.CATNAMETHAI= this.returnCategorySubCategory.CATNAMETHAI;
      this.CATNAMEENG= this.returnCategorySubCategory.CATNAMEENG;
      this.DESCRIPTION= this.returnCategorySubCategory.DESCRIPTION;
      this.subCategorys = returnCategorySubCategory.subCategory;
    }
    );
  }

  getSubject(id:string){
    this.homeService.getSubject(id).subscribe((cursubCategory:returnSubCategory) => {
      this.cursubCategory = cursubCategory.subCategory;
      this.CATEGRPNAMETHAI =   this.cursubCategory .CATEGRPNAMETHAI;
      this.subjects = cursubCategory.subjects;
      console.log( cursubCategory.subjects);
      $('#subjectModal').modal('show');
    }
    );
  }


}
