

<div
  class="row row-cols-2 gap-3 justify-content-between justify-content-center"
>
  <div class="col-lg-12">
    <h5 style="color: rgb(91, 94, 97)" class="my-auto">
      <i class="bi bi-building me-1"></i>{{ "Location" | translate }}
    </h5>
  </div>

  <div class="dropdown col-12 col-md-auto col-lg-12">
    <button
      class="btn btn-sm btn-primary dropdown-toggle rounded-5 shadow py-1 w-100"
      id="dropdownMenuButtonLocationItem"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="my-auto">
        {{ locationName }}
      </span>
    </button>

    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonLocationItem">
      <li>
        <a class="dropdown-item a-walai" (click)="ChangeLocation('', 'ทุกสาขา')"
          >ทุกสาขา</a
        >
      </li>
      <li *ngFor="let lo of itemsLocation">
        <a
          class="dropdown-item a-walai"
          (click)="ChangeLocation(lo.LOCATIONID, lo.LOCATIONNAMETHAI)"
          >{{ lo.LOCATIONNAMETHAI }}</a
        >
      </li>
    </ul>
  </div>
</div>
<div  *ngIf="curentCheckOut.length>0" class="mt-4 alert alert-primary alert-dismissible fade show" role="alert">
{{'You are borrowing this resource!'|translate}} <br>  <strong>
    ({{'DueDate'|translate}} : {{ curentCheckOut[0].DUEDATE }})</strong>  <br><a href="member/checkout" target="_blank"  class="alert-link"> {{'Your are  checked outs list' | translate}}</a>
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>


<div class="mt-4" *ngIf="LoaditemOk == true; else contentLoading">
  <div
    class="col-12 mt-3 ml-1"
    *ngIf="itemsList.length > 0; else contentNoitem"
  >
    <!-- <ng-container *ngIf="viewAll==false; else ViewAllItems">
    <div *ngFor="let item of itemsList.slice(0, 2);">
  </ng-container>

  <ng-template #ViewAllItems>
    <div *ngFor="let item of itemsList;">
  </ng-template> -->

    <div *ngFor="let item of itemsListShow">
      <div class="row">
        <div class="col-3">
          <p class="p-walai">Barcode</p>
        </div>
        <div class="col-9">
          <p class="p-walai text-dark">{{ item.BARCODE }}</p>
        </div>
      </div>

      <div class="row" *ngIf="item.CALLNO">
        <div class="col-3">
          <p class="p-walai">CallNo</p>
        </div>
        <div class="col-9">
          <p class="p-walai text-dark">{{ item.CALLNO }}</p>
        </div>
      </div>

      <div *ngIf="item.COLOR1" class="row mt-2">
        <div class="col-9 offset-3">
          <table class="table  w-25">
            <tbody>
              <tr>
                <td class="border-0" [style.background-color]="item.COLOR1"></td>
                <td class="border-0" [style.background-color]="item.COLOR2"></td>
                <td class="border-0" [style.background-color]="item.COLOR3"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <p class="p-walai">Collection</p>
        </div>
        <div class="col-9">
          <p class="p-walai text-dark">{{ item.COLLECTIONNAME }}</p>
        </div>
      </div>

      <div class="row" *ngIf="replaceSpace(item.UNIT)">
        <div class="col-3">
          <p class="p-walai">Unit</p>
        </div>
        <div class="col-9">
          <p class="p-walai text-dark">{{ item.UNIT }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <p class="p-walai">Copy</p>
        </div>
        <div class="col-9">
          <p class="p-walai text-dark">{{ item.COPY }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <p class="p-walai">Status</p>
        </div>

        <div [ngSwitch]="item.ITEMSTATUSID" class="col-9">
          <p *ngSwitchCase="'1'" class="text-success">
            {{ item.ITEMSTATUSNAME }}
          </p>
          <p *ngSwitchCase="'2'" class="text-warning">
            {{ item.ITEMSTATUSNAME }}  (Due date {{ item.DUEDATE }})
          </p>

          <!-- Missing -->
          <p *ngSwitchCase="'4'" class="text-danger">
            {{ item.ITEMSTATUSNAME }}
          </p>

          <p *ngSwitchDefault class="text-primary">
            {{ item.ITEMSTATUSNAME }}
          </p>
        </div>
      </div>

      <div class="row">
        <div  class="col-9 offset-3">
          <!-- Available -->
          <a
            target="_blank"
            *ngIf="item.SHOWMAP != 0 && item.SHOWMAP != '' && item.ITEMSTATUSID=='1'"
            routerLink="../virtualbookshelf/{{ item.ITEMID }}"
          >
            <img
            
              src="assets/images/icon/Map1.png"
              class="icon-item a-walai img-color-navy"
              title="Map"
          /></a>

          <a *ngIf="opacopenRD == true && item.ITEMSTATUSID=='1'">
            <app-btn-rd
              [item]="item"
              [BLOCKUSER]="BLOCKUSER"
              [mattypeID]="mattypeID"
              [NDDUserPrivilege]="NDDUserPrivilege"
            ></app-btn-rd>
          </a>

          <!-- Check Out -->
          <!-- *ngIf="bookListcheckout.length==0" -->
          <img   *ngIf="item.ITEMSTATUSID=='2' && curentCheckOut.length==0"
          
            src="assets/images/icon/Hold.png"
            class="icon-item a-walai img-color-navy"
            title="Hold"
            (click)="holdItem(item.BARCODE)"
          />

          <!-- Just Returned -->

          <a 
            target="_blank"
            *ngIf="item.SHOWMAP != 0 && item.SHOWMAP != '' && item.ITEMSTATUSID=='6'"
            routerLink="../virtualbookshelf/{{ item.ITEMID }}"
          >
            <img
            
              src="assets/images/icon/Map1.png"
              class="icon-item a-walai img-color-navy"
              title="Map"
          /></a>

          <a *ngIf="opacopenRD == true && item.ITEMSTATUSID=='6'">
            <app-btn-rd
              [item]="item"
              [BLOCKUSER]="BLOCKUSER"
              [mattypeID]="mattypeID"
              [NDDUserPrivilege]="NDDUserPrivilege"
            ></app-btn-rd>
          </a>
          <a *ngIf="opacopenRD == true && item.ITEMSTATUSID=='31'">
            <app-btn-rd
              [item]="item"
              [BLOCKUSER]="BLOCKUSER"
              [mattypeID]="mattypeID"
              [NDDUserPrivilege]="NDDUserPrivilege"
            ></app-btn-rd>
           
          </a>
        </div>


        <!-- <div [ngSwitch]="item.ITEMSTATUSID" class="col-9 offset-3">
        
          <a
            target="_blank"
            *ngIf="item.SHOWMAP != 0 && item.SHOWMAP != ''"
            routerLink="../virtualbookshelf/{{ item.ITEMID }}"
          >
            <img
              *ngSwitchCase="'1'"
              src="assets/images/icon/Map1.png"
              class="icon-item a-walai img-color-navy"
              title="Map"
          /></a>

          <a *ngIf="opacopenRD == true">
            <app-btn-rd
              *ngSwitchCase="'1'"
              [item]="item"
              [BLOCKUSER]="BLOCKUSER"
              [mattypeID]="mattypeID"
            ></app-btn-rd>

          </a>

        
          <img
            *ngSwitchCase="'2'"
            src="assets/images/icon/Hold.png"
            class="icon-item a-walai img-color-navy"
            title="Hold"
            (click)="holdItem(item.BARCODE)"
          />

          <a
            target="_blank"
            *ngIf="item.SHOWMAP != 0 && item.SHOWMAP != ''"
            routerLink="../virtualbookshelf/{{ item.ITEMID }}"
          >
            <img
              *ngSwitchCase="'6'"
              src="assets/images/icon/Map1.png"
              class="icon-item a-walai img-color-navy"
              title="Map"
          /></a>

          <a *ngIf="opacopenRD == true">
            <app-btn-rd
              *ngSwitchCase="'6'"
              [item]="item"
              [BLOCKUSER]="BLOCKUSER"
              [mattypeID]="mattypeID"
            ></app-btn-rd>
          </a>
          <a *ngIf="opacopenRD == true">
            <app-btn-rd
              *ngSwitchCase="'31'"
              [item]="item"
              [BLOCKUSER]="BLOCKUSER"
              [mattypeID]="mattypeID"
            ></app-btn-rd>
           
          </a>
        </div> -->
      </div>
      <hr />
    </div>
    <button
      *ngIf="viewAll == false && itemsList.length > lessItem"
      (click)="clickViewAll()"
      type="button"
      class="btn btn-light"
    >
      View all items
    </button>
  </div>
  <ng-template #contentNoitem>
    <div class="row mt-3 ml-1">
      <div class="col-md-12 text-center text-walai-default-color">
        <i class="fa fa-times" aria-hidden="true"></i> ไม่พบ Item ในสาขานี้
      </div>
    </div>
  </ng-template>
</div>
<ng-template #contentLoading>
  <div class="row mt-3 ml-1">
    <div class="col-md-12 text-center text-walai-default-color">Loading...</div>
  </div>
</ng-template>
