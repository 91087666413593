<div class="row mt-5">
    <div class="col-12">
      <div class="border-bottom mb-3">
        <h5 class="text-walai-default-color">Tag cloud</h5>
      </div>
  
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="default"
        color="#581f87"
        type="ball-pulse-sync"
        [fullScreen]="false"
      >
      </ngx-spinner>
  
  
  <div class="mb-4" >
    <a   *ngFor="let item of tagCloudData"   target="_blank"
    href='results?Ntk=TAGSFACET{{ntkLocation}}&Ntt="{{ item.Value }}"{{nttLocation}}'><span class="badge bg-light {{item.CSS}} ">#{{item.Value}}</span>
</a>

  </div>
  <div *ngIf="USERID!=''">
      <form class="example-form" [formGroup]="addFG">
  <div class="input-group input-group-sm w-50">
    <span class="input-group-text"><i class="bi bi-bookmark-fill"></i></span>
    <input type="text" class="form-control" placeholder="tag word" formControlName="value" aria-label="tag word" aria-describedby="button-addon2">
    <button     [disabled]="!addFG.valid" (click)="AddTag()" class="btn btn-outline-primary" type="button" id="button-addon2"> <i class="bi bi-plus-square me-1"></i> Add</button>
  </div>
  </form>

  <div class="d-grid gap-2 d-md-block mt-2">
    <p>Or select these</p>
    <button (click)="ConfirmAddTag(item.TAGSWORD, 2)" *ngFor="let item of MytagCloud" class="btn btn-primary btn-sm me-2" type="button">{{item.TAGSWORD}} <i class="bi bi-bookmark-plus-fill"></i></button>
   
  </div>
  </div>

     
    </div>
  </div>
  

  