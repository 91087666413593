import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FriendModel, SuggestFriendParam, keywordModel } from 'src/app/models/walai';
import { HomeService } from 'src/app/services/home.service';
import { MemberService } from 'src/app/services/member.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bib-suggest-friend',
  templateUrl: './bib-suggest-friend.component.html',
  styleUrls: ['./bib-suggest-friend.component.css'],
})
export class BibSuggestFriendComponent implements OnInit {
  @Input() public bibid: string;
  currentUser: any[] = [];
  USERID = '';
  qsearchForm: UntypedFormGroup;
  suggestForm: UntypedFormGroup;
  friends: FriendModel[] = [];
  friend: FriendModel = {
    EMAIL: '',
    MEMBERID: 0,
    USERID: 0,
    NAME: '',
    IMAGES: '',
    ADMINFLAG: 0,
    LIBRARIANFLAG: 0,
    DASHBOARDFLAG: 0,
  };
  constructor(private serv: HomeService, private memberService: MemberService) {
    this.qsearchForm = new UntypedFormGroup({
      textSearch: new UntypedFormControl(),
    });

    this.suggestForm = new UntypedFormGroup({
      textComment: new UntypedFormControl(),
    });
  }

  ngOnInit(): void {
    this.currentUser =
      JSON.parse(localStorage.getItem('WALAIcurrentUser')) || [];

    if (this.currentUser.length > 0) {
      this.USERID = this.currentUser[0].USERID;
    }
  }

  auto(value) {
    if (value.length >= 2) {

   let   keyword:keywordModel={
     keyword: value
   }
      this.serv.GetMemberByKeyword(keyword).subscribe(
        (results: any) => {
          if (results) {
            //  JSON.stringify(results);
            this.friends = results;

            // add newly fetched posts to the existing post
          } else {
            this.friends = [];
          }
        },
        (error) => {}
      );
    } else {
      this.friends = [];
    }
  }

  Select(item: FriendModel) {
    this.friend = item;
  }

  Confirm() {
    if (
      this.suggestForm.controls.textComment.value == '' ||
      this.friend.USERID == 0
    ) {
      return false;
    }

    const objReviewDataAdd: SuggestFriendParam = {
      SUGGESTBY: this.USERID,
      FriendID: this.friend.USERID.toString(),
      BIBID: this.bibid,
      Comment: this.suggestForm.controls.textComment.value,
    };

  //  console.log(objReviewDataAdd);

    this.memberService
      .opacMemberSuggestFriend(objReviewDataAdd)
      .subscribe((getData: any) => {
        //console.log(getData);
        if (getData.status === true) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: getData.message,
            showConfirmButton: false,
            timer: 1500,
          });

          this.suggestForm.controls.textComment.setValue('');
          this.qsearchForm.controls.textSearch.setValue('');

          this.friend = {
            EMAIL: '',
            MEMBERID: 0,
            USERID: 0,
            NAME: '',
            IMAGES: '',
            ADMINFLAG: 0,
            LIBRARIANFLAG: 0,
            DASHBOARDFLAG: 0,
          };
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: getData.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }
}
