<div class="container mt-5 container-walai">
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-3" >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src="{{CATIMG}}" 
                    onError="this.src='assets/images/bookcover/noimage.jpg'"
                    class="card-img-top" >
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h2 class="card-title">{{CATNAMETHAI}}</h2>
                      <h6 class="card-subtitle mb-2 text-muted">({{CATNAMEENG}})</h6>
                      <p class="card-text">{{DESCRIPTION}}</p>
                  
                    </div>
                  </div>
                </div>
              </div>


              <div class="row row-cols-1 row-cols-md-3 g-4">
                <div class="col mb-4" *ngFor="let cat  of subCategorys">
                    <div class="card h-100">
                      <div class="card-body">
                        <h5 class="card-title">{{cat.CATEGRPNAMETHAI}}</h5>
                        <h6 class="card-subtitle mb-2 text-muted">({{cat.CATEGRPNAMEENG}})</h6>
                        <p class="card-text">{{cat.CATEGRPDESC}}</p>
                        <a (click)="getSubject(cat.CATEGRPID)"  class="btn btn-primary  btn-sm"><i class="bi bi-card-list"></i> Browse {{cat.COUNT}} Subjects</a>
                      </div>
                     
                    </div>
                  </div>
               
             
              </div> 

        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="subjectModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{CATEGRPNAMETHAI}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let subject of subjects">
                  <ng-container *ngIf="locationID!=''; else NoLocation">
                    <a target="_blank" href="results?Ntk=AUTHLINK|LOCATION&Ntt={{subject.AUTHID}}|{{locationID}}"> {{subject.AUTHHEADING}}</a>  
                  </ng-container>
              
                  <ng-template #NoLocation>
                    <a target="_blank" href="results?Ntk=AUTHLINK&Ntt={{subject.AUTHID}}"> {{subject.AUTHHEADING}}</a>  
                  </ng-template>
             
                </li>
              
              </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>