import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { reviewData } from 'src/app/models/walai';
import { HomeService } from 'src/app/services/home.service';
import { textChangeRangeIsUnchanged } from 'typescript';
import Swal from 'sweetalert2';
import { MemberService } from 'src/app/services/member.service';
@Component({
  selector: 'app-review-lib',
  templateUrl: './review-lib.component.html',
  styleUrls: ['./review-lib.component.css'],
})
export class ReviewLibComponent implements OnInit {
  @Input() bibid: string;
  currentUser: any[] = [];

  USERID = '';
  editStatus = false;
  addStatus = false;
  libLogin = false;

  reviewData: reviewData[] = [];
  constructor(
    private route: ActivatedRoute,
    private homeService: HomeService,
    private memberService: MemberService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
 
    this.currentUser =
      JSON.parse(localStorage.getItem('WALAIcurrentUser')) || [];
    if (this.currentUser.length > 0 && this.currentUser[0].LIBRARIANFLAG==1) {
      //login เป็นบรรณารักษ์
      this.libLogin = true;
      this.USERID = this.currentUser[0].USERID;
    }

  this.getLibReview();

  }

getLibReview(){
  this.spinner.show(); // หมุนๆ
    this.homeService
      .getBookReviewLibrarain(this.bibid)
      .subscribe((Review: any) => {
        this.reviewData = Review;
     
        if (this.libLogin && Review.length > 0) {
          this.editStatus = true;
        }

        if (this.libLogin && Review.length === 0) {
          this.addStatus = true;
        }

        this.spinner.hide(); // ซ่อนหมุนๆ
      });

}


  deleteReview(id:string){
    Swal.fire({
      title: 'ยืนยันการลบ review?',
     // text: this.addFG.controls.NewsTitle.value,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.value) {

        this.memberService.reviewDelete(id).subscribe((getData: any) => {
          if (getData === true) {

            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'ลบข้อมูลเรียบร้อยแล้ว',
              showConfirmButton: false,
              timer: 1500,
            });
            this.getLibReview();
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'ลบข้อมูลไม่สำเร็จ',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    });


  }


}
