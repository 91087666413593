export const environment = {
  production: true,

  token: '',
  //apiurl: 'https://wunca41.walaiautolib.com/apiopacutm-l1',
  //apiurl: 'https://wunca41.walaiautolib.com/apiopacutm-l4', //Test
  //apiurl: 'https://clmopac.walaiautolib.com/api',

  //apiurl: 'https://opac-api.wu.ac.th/v1', //WU
  //apiurl: 'https://libapi.uru.ac.th/v1',  //URU อุตรดิต
  //apiurl: 'https://libapp.msu.ac.th/msu', //MSU มมส
  //apiurl: 'https://libapi.sru.ac.th/v1', //SRU สุราษ
  //apiurl: 'https://libapi.vistec.ac.th/kvis',
  //apiurl: 'https://libapi.vistec.ac.th/vistec',
  //apiurl: 'https://libapp.cmru.ac.th/v1',  // CMRU
  //apiurl: 'https://apiopac.rmutsv.ac.th/opac',  // RMUTSV
  //apiurl: 'https://libapi.rmutt.ac.th/v1',  // RMUTT
  apiurl: 'https://libapi.rmutp.ac.th/v1',  // RMUTP
 

  defaultLocationID: '',
  defaultLocationName: '',
  // defaultLocationID: '1',
  //  defaultLocationName:'ศูนย์บรรณสารและสื่อการศึกษา',
  // apirdurl: 'https://default.walaiautolib.com/rdapiutm',
  // tokenrd: ''



  useWalaiBookCover: '1', //ใช้งานปกจากเครือข่ายหน้องสมุด WALAI (1 =ใช้, 0= ไม่ใช้)

  //Google Analytics
  MeasurementID: 'G-H60YJLFKP9',
  //   kku UA-83060958-11
  //   nstru UA-77242599-1
  //   มารวย SET UA-74443868-3
  //   มทร.พระนคร RMUTP 151500590-3
  //   ม.ทักษิน UA-83060958-3
  //   pea UA-83060958-4
  //   rmutl UA-83060958-5
  //   CLM UA-83060958-6   // GA4 -> G-TKNXR32N9M
  //   SAC UA-83060958-8
  //   cc UA-83060958-12
  //   cdtc UA-83060958-14
  //   rmutsv UA-83060958-17
  //   buu เอาออก
  //   yru UA-83060958-16
  //   pcru UA-83060958-19
  //   kmitl UA-151500590-5
  //   RMUTR UA-83060958-20
  // RMUTT UA-151500590-6
  // PSRU UA-151500590-4
  // CMRU UA-151500590-7
  // opac2023 'G-69CBD5C3Y0'
 // สจล G-VHS64RSR3S
 //มมส G-XK8H2R1EBR
 //rmutp G-H60YJLFKP9
};
