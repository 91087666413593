<div
  *ngIf="bibid != '' && bibid != null && infoList.length > 0"
  class="container mt-5"
>
  <div class="row justify-content-center justify-content-md-between">
    <main class="col-lg-9 order-0">
      <!-- title -->
      <div class="row row-cols-auto justify-content-between">
        <div>
          <div class="d-flex">
            <img
              *ngIf="mattyeIcon"
              src="{{ mattyeIcon }}"
              class="bd-placeholder-img rounded-circle"
              width="50"
              height="50"
            />
            <div>
              <p class="media-body pb-3 mb-0">
                <strong class="d-block" style="font-size: 18px">{{
                  title
                }}</strong>

                <ng-container *ngIf="locationID; else elseNotLocation">
                  <span
                    ><a
                      class="a-walai"
                      target="_blank"
                      href="results?Ntk=AUTHOR|LOCATION&Ntt={{ author }}|{{
                        locationID
                      }}"
                      >{{ author }}</a
                    ></span
                  >
                </ng-container>

                <ng-template #elseNotLocation>
                  <span
                    ><a
                      class="a-walai"
                      target="_blank"
                      href="results?Ntk=AUTHOR&Ntt={{ author }}"
                      >{{ author }}</a
                    ></span
                  >
                </ng-template>
                <!-- <span class="px-2" *ngIf="author">.</span>
          <span class="small" *ngIf="author">Follow</span> -->
              </p>
            </div>
          </div>
        </div>

        <div class="text-right ms-auto">
          <div class="justify-content-center d-flex">
            <app-bib-suggest-friend [bibid]="bibid"></app-bib-suggest-friend>
            <app-collection [bibid]="bibid" [page]="'bibitem'"></app-collection>
          </div>
        </div>
      </div>

      <!-- card bib -->
      <div class="row">
        <div class="col-md-3 mt-4">
          <div class="img text-center">
            <i
              *ngIf="covers.length > 0"
              class="bi bi-zoom-in a-walai px-2 py-1"
              data-bs-toggle="modal"
              data-bs-target="#bookcoverAllModal"
            ></i>
            <img
              class="img-fluid img-thumbnail custom-img-shadow"
              src="{{ bookcoverUrl }}"
            />

            <!-- (error)="getCoverOnError()" -->
            <!-- <app-book-cover [bookCoverURL]="bookcoverUrl" [id]="bibid" [cssStyle]="'img-fluid img-thumbnail custom-img-shadow'"></app-book-cover> -->
          </div>
          <!-- <div class="d-flex justify-content-center pt-4">
            <span class="text-walai-default-color me-3">
              <i class="fas fa-book-reader"></i>
              {{ bookStatic.CHECKOUTCOUNT }}</span
            >
            <span class="text-walai-default-color me-3">
              <i class="fas fa-eye"></i> {{ bookStatic.OPACVIEW }}</span
            >
            <span class="text-walai-default-color">
              <i class="fa fa-heart"></i> {{ bookStatic.MYLIST }}</span
            >
          </div> -->
        </div>
        <!-- <div class="col-md-4">
          <img class="img-fluid img-thumbnail custom-img-shadow" src="{{ bookcoverUrl }}"
            onError="this.src='assets/images/bookcover/noimage.jpg'" />
          <div class=" p-3 mb-5 bg-white rounded text-center" *ngIf="covers.length > 0">
            <a style="cursor: pointer" data-bs-toggle="modal" data-bs-target="#bookcoverAllModal"><i class="fas fa-search">
                {{'BookCover'|translate}}</i></a>
          </div>
        </div> -->

        <div class="col-md-9 flex-column position-static ps-md-5 mt-4">
          <!-- spinner -->
          <ngx-spinner
            bdOpacity="0.9"
            bdColor=""
            size="default"
            color="#581f87"
            type="ball-pulse-sync"
            [fullScreen]="false"
          >
          </ngx-spinner>

          <div class="col-12 mb-3" *ngIf="contents.length > 0">
            <div id="moduleTableOfContents">
              <p class="pb-0 mb-0">{{ "TableOfContents" | translate }}</p>

              <p
                class="text-walai-default-color pl-3 pb-0 mb-0 collapse"
                id="collapseTableOfContents"
                aria-expanded="false"
              >
                <span *ngFor="let Content of contents"
                  >- {{ replaceBR (Content) }} <br
                /></span>
              </p>
              <a
                class="collapsed"
                data-bs-toggle="collapse"
                href="#collapseTableOfContents"
                aria-expanded="false"
                aria-controls="collapseTableOfContents"
              ></a>
            </div>
          </div>

          <div class="col-12 mb-3">
            <div class="row" *ngIf="havesubject">
              <div class="col-md-3">
                <p class="mb-0 h6 text-secondary">
                  {{ "Subject" | translate }}
                </p>
              </div>
              <div class="col-md-9">
                <div *ngIf="locationID != ''; else NoLocation" class="">
                  <p
                    *ngFor="let s of subjectList"
                    style="padding: 0px; margin: 0px"
                  >
                    <a
                      target="_blank"
                      href="results?Ntk=SUBJECT|LOCATION&Ntt=&quot;{{
                        s.DATA
                      }}&quot;|{{ locationID }}"
                      class="a-walai me-2 text-primary text-decoration-underline"
                    >
                      {{ s.DATA }}</a
                    >
                  </p>
                </div>
                <ng-template #NoLocation>
                  <p
                    *ngFor="let s of subjectList"
                    style="padding: 0px; margin: 0px"
                  >
                    <a
                      target="_blank"
                      href="results?Ntk=SUBJECT&Ntt=&quot;{{ s.DATA }}&quot;"
                      class="a-walai me-2 text-primary text-decoration-underline"
                    >
                      {{ s.DATA }}</a
                    >
                  </p>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3">
            <div class="row" *ngIf="book856.length > 0">
              <!-- <div class="row mb-3" *ngIf="book856"> -->
              <div class="col-md-3 pe-0">
                <p class="mb-0 h6 text-secondary">
                  {{ "ElectronicResource" | translate }}
                </p>
              </div>
              <div class="col-md-9">
                <!--              
              <div *ngFor="let info of book856"
              class="col-md-9 mb-0 text-walai-default-color"
              [innerHTML]="info.data"
            >
              {{ info.data }}
            </div> -->

                <ng-container *ngFor="let info of book856">
                  <div class="text-secondary">
                    {{ info.label }}
                  </div>

                  <div class="text-primary">
                    <a
                      (click)="addHistory(info.URL)"
                      href="{{ info.URL }}"
                      target="_blank"
                      >{{ info.name }}</a
                    >
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3" *ngIf="infoList">
            <div class="border-bottom pb-1">
              <p class="mb-0 h6 text-secondary">{{ "Details" | translate }}</p>
            </div>

            <div class="p-2 pt-3">
              <div class="row mb-2" *ngIf="addedAuthor">
                <div class="col-md-3 info-label">
                  {{ "AddedAuthor" | translate }}
                </div>
                <div class="col-md-9">
                  <div *ngIf="locationID != ''; else NoLocationAddedAuthor">
                    <!-- <a class="a-walai text-primary text-decoration-underline" *ngFor="let info of infoList"
                      target="_blank" href="results?Ntk=AUTHOR|LOCATION&Ntt={{ info.DATA }}|{{
                        locationID
                      }}">
                      <span class="me-2" *ngIf="info.FIELD === 'Added Author'">
                        {{ info.DATA }}
                      </span>
                    </a> -->

                    <p
                      *ngFor="let info of infoList"
                      style="padding: 0px; margin: 0px"
                    >
                      <a
                        class="a-walai text-primary text-decoration-underline"
                        *ngIf="info.FIELD === 'Added Author'"
                        target="_blank"
                        href="results?Ntk=AUTHOR|LOCATION&Ntt={{ info.DATA }}|{{
                          locationID
                        }}"
                      >
                        <span class="me-2">
                          {{ info.DATA }}
                        </span>
                      </a>
                    </p>
                  </div>

                  <ng-template #NoLocationAddedAuthor>
                    <!-- <a class="a-walai text-primary text-decoration-underline " *ngFor="let info of infoList"
                      target="_blank" href="results?Ntk=AUTHOR&Ntt={{ info.DATA }}">
                      <span class="me-2" *ngIf="info.FIELD === 'Added Author'">
                        {{ info.DATA }}
                      </span>
                    </a> -->
                    <p
                      *ngFor="let info of infoList"
                      style="padding: 0px; margin: 0px"
                    >
                      <a
                        class="a-walai text-primary text-decoration-underline"
                        *ngIf="info.FIELD === 'Added Author'"
                        target="_blank"
                        href="results?Ntk=AUTHOR&Ntt={{ info.DATA }}"
                      >
                        <span class="me-2">
                          {{ info.DATA }}
                        </span>
                      </a>
                    </p>
                  </ng-template>
                </div>
              </div>

              <ng-container *ngFor="let info of infoList">
                <div
                  class="row mb-2"
                  *ngIf="
                    info.FIELD != 'Title' &&
                    info.FIELD != 'Author' &&
                    info.FIELD != 'Subject' &&
                    info.FIELD != 'ISBN' &&
                    info.FIELD != 'Added Author'
                  "
                >
                  <div class="col-md-3 info-label">
                    {{ info.FIELD | translate }}
                  </div>

                  <!-- <div  class="col-md-9 mb-0 text-walai-default-color" [innerHTML]="info.DATA">
                    {{ info.DATA }}
                </div> -->

                  <div
                    *ngIf="info.FIELD == 'Source'; else other_content773t"
                    class="col-md-9 text-walai-default-color"
                  >
                    <a
                      target="_blank"
                      class="a walai text-primary text-decoration-underline"
                      href="results?Ntk=JOURNALTITLE&Ntt={{ objBook773t.data }}"
                      ><span class="me-2">{{ info.DATA }}</span></a
                    >
                  </div>

                  <!-- <ng-template
                 
                    #other_content773t
                    class="col-md-9 mb-0 text-walai-default-color"
                    [innerHTML]="info.DATA"
                  >
                    {{ info.DATA }}
                  </ng-template> -->

                  <ng-template #other_content773t>
                    <div class="col-md-9 text-walai-default-color">
                      <span class="me-2">{{ info.DATA }}</span>

                      <!-- <a
                        target="_blank"
                        class="a-walai me-2 text-primary text-decoration-underline"
                        href="results?Ntk=JOURNALTITLE&Ntt={{
                          objBook773t.data
                        }}"
                        ><span class="me-2">{{ info.DATA }}</span>
                      </a> -->
                    </div>
                  </ng-template>
                </div>
              </ng-container>

              <div class="row mb-2" *ngIf="haveISBN">
                <div class="col-md-3 info-label">ISBN</div>
                <div class="col-md-9">
                  <div *ngFor="let info of infoList">
                    <span class="me-2 text-dark" *ngIf="info.FIELD === 'ISBN'">
                      {{ info.DATA }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3 p-2">
            <div class="row row-cols-auto justify-content-between mb-2">
              <!-- Views -->
              <div class="d-flex justify-content-center pt-4">
                <span class="text-walai-default-color me-3 fs-6">
                  <i class="bi bi-book-fill img-color-navy"></i>
                  {{ bookStatic.CHECKOUTCOUNT }}</span
                >
                <span
                  *ngIf="book856.length > 0"
                  class="text-walai-default-color fs-6 me-3"
                >
                  <i class="bi bi bi-cloud-arrow-down-fill img-color-navy"></i>
                  {{ bookStatic.DOWNLOAD }}</span
                >
                <span class="text-walai-default-color me-3 fs-6">
                  <i class="bi bi-eye-fill img-color-navy"></i>
                  {{ bookStatic.OPACVIEW }}</span
                >
                <span class="text-walai-default-color fs-6">
                  <i class="bi bi-heart-fill img-color-navy"></i>
                  {{ bookStatic.MYLIST }}</span
                >
              </div>

              <!-- marc/holding/marcholding/export/save/marc -->
              <div class="d-flex gap-2 justify-content-end mt-4">
                <div class="d-flex">
                  <app-marc [bibid]="bibid"></app-marc>

                  <app-holding [bibid]="bibid" *ngIf="isSerial"></app-holding>

                  <app-marcholding
                    [bibid]="bibid"
                    *ngIf="isSerial"
                  ></app-marcholding>
                </div>

                <div class="border-end border-secondary mx-2"></div>

                <div class="d-flex">
                  <app-export
                    [bibid]="bibid"
                    *ngIf="isArticle == false"
                  ></app-export>

                  <button
                    *ngIf="isArticle == false"
                    type="button"
                    class="btn btn-primary btn-sm py-1 fw-light shadow me-2"
                    (click)="save(bibid)"
                  >
                    <span
                      ><i class="bi bi-file-earmark-arrow-down"></i> Save
                    </span>
                  </button>
                  <app-share-facebook [shareUrl]="url"></app-share-facebook>
                </div>

                <!-- <button
    type="button"
    class="btn btn-light button-radius1 btn-walai-default"
>
    MARC Holding
  </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2 mt-4" *ngIf="abstract">
        <div class="col-12">
          <strong class="text-walai-default-color">{{
            "Description" | translate
          }}</strong>
          <p class="text-walai-default-color pl-4 mt-3">{{ abstract }}</p>
        </div>
      </div>

      <!-- <div class="row " *ngIf="isSerial">
        <div class="col-md-12">
          <strong class="text-walai-default-color"> Available Issues</strong>

          <div
           
            *ngFor="let HoldingAvailableIssue of HoldingAvailableIssues"
          >    <h6
              class="text-walai-default-color mt-2"
              *ngIf="HoldingAvailableIssue.availableIssues.length > 0"
            >
            {{'LocationItem'|translate}} {{ HoldingAvailableIssue.locationName }}</h6 >

              <ul class="list-group mb-2" 
                *ngFor="
                let availableIssues of HoldingAvailableIssue.availableIssues;
                let i = index
              "
                >
                <li class="list-group-item active" aria-current="true"  style="background: #e8f0fe; color: #1a73e8">{{ availableIssues.year }}</li>
               
                <li
                      class="list-group-item"
                      *ngFor="let units of availableIssues.unit"
                    >
                      {{ units }}
                    </li>
              </ul>


          </div>

         </div>
      </div> -->
    </main>

    <main class="col-lg-9 order-2 mt-4">
      <div class="row" *ngIf="bookRelated && isBook">
        <div class="col-12">
          <div class="border-bottom">
            <h5 class="text-walai-default-color">
              {{ "RelatedBook" | translate }}
            </h5>
          </div>

          <div class="row row-cols-2 row-cols-sm-4 row-cols-xl-6 mt-4">
            <figure class="figure-bookcover" *ngFor="let b of bookRelated">
              <div class="">
                <div class="text-center">
                  <a href="bibitem?bibid={{ b.BIBID }}">
                    <!-- <img
                      alt="picture"
                      src="{{ b.bookcoverurl }}"
                      class="img-fluid img-bookcover custom-img-shadow max-height-180"
                      onError="this.src='assets/images/bookcover/noimage.jpg'"
                    /> -->
                    <app-book-cover-share
                      [bookCoverURL]="b.bookcoverurl"
                      [id]="b.BIBID"
                      [cssStyle]="
                        'img-fluid img-bookcover custom-img-shadow max-height-180'
                      "
                    ></app-book-cover-share>
                  </a>
                </div>
                <!-- <div  class="menuCategory">
            <a class="float-right"><i class="fas fa-folder-plus fa-lg"></i>
            </a>
          </div>  -->
              </div>
              <h6 class="text-center mt-3">
                <a class="a-walai-book" href="bibitem?bibid={{ b.BIBID }}">{{
                  b.TITLESHOT
                }}</a>
              </h6>
              <p class="text-center">
                <small>{{ b.AUTHOR }}</small>
              </p>
            </figure>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="bookRelated && isArticle">
        <div class="col-12">
          <div class="">
            <h5 class="text-walai-default-color">
              {{ "All title in issue" | translate }}
            </h5>

            <table class="table table-striped table-hover">
              <tbody>
                <tr *ngFor="let b of AllTitleInIssue; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>
                    <a
                      class="a-walai"
                      href="/bibitem?bibid={{ b.JMARCID }}"
                      target="_blank"
                      >{{ b.TITLE }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <app-bib-tag-cloude [bibid]="bibid"></app-bib-tag-cloude>

      <app-review-lib [bibid]="bibid"></app-review-lib>

      <app-review-member [bibid]="bibid"></app-review-member>
    </main>

    <aside class="col-lg-3 order-1 mt-4 mt-lg-0">
      <ng-template [ngIf]="isBook == true">
        <app-items
          [bibid]="bibid"
          [title]="title"
          [mattypeID]="mattypeID"
        ></app-items>
      </ng-template>
    </aside>
  </div>
</div>

<div
  *ngIf="bibid == '' || bibid == null || validParameter == false"
  class="container mt-5"
>
  <div class="row justify-content-center justify-content-md-between">
    <main class="col-lg-12 order-0">
      <div class="alert alert-danger" role="alert">Invalid parameter!</div>
    </main>
  </div>
</div>

<!-- Modal cover-->
<div
  class="modal fade"
  id="bookcoverAllModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="bookcoverAllModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="bookcoverAllModalTitle">Book Cover</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div
              class="carousel-item active"
              *ngFor="let cover of covers; let i = index"
              [ngClass]="{ active: i === 0 }"
            >
              <img src="{{ cover }}" class="d-block w-100" alt="{{ cover }}" />
            </div>
          </div>
          <button
            type="button"
            *ngIf="covers.length > 1"
            class="carousel-control-prev"
            data-bs-target="#carouselExampleControls"
            role="button"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            type="button"
            *ngIf="covers.length > 1"
            class="carousel-control-next"
            data-bs-target="#carouselExampleControls"
            role="button"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div> -->
    </div>
  </div>
</div>
