import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-regulation',
  templateUrl: './regulation.component.html',
  styleUrls: ['./regulation.component.css']
})
export class RegulationComponent implements OnInit {
  value :string= '';
  constructor(
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.value = '';
    this.adminService
      .getAboutUSContactRule('rule')
      .subscribe((val:  string) => {
        this.value = val;
      });
  }

}
