<div class="mt-5 pt-5 pb-5 footer" [style.color]="colorFooter" [style.background-color]="backgroundFooter">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-xs-12 about-company">
        <h5>About us</h5>
        <p class="pr-5">
          {{AboutUsFooter}}
        </p>
       <div class="row row-cols-auto gap-2 px-3">
      
        <!-- <button type="button" class="btn btn-sm btn-outline-secondary-footer p-0"><i class="bi bi-linkedin"></i></button> --></div>
      </div>
      <div class="col-lg-3 col-xs-12 links">
        <h5 class="mt-lg-0 mt-sm-3">Links</h5>
        <ul class="m-0 p-0">
          <li>- <a [routerLink]="'./search/alphabetic'">A-Z SEARCH</a></li>
          <li>- <a [routerLink]="'./search/advance'">ADVANCED SEARCH</a></li>
          <li>- <a [routerLink]="'news'">NEWS</a></li>
          <li>- <a [routerLink]="'aboutus'">ABOUT US</a></li>
          <li>- <a [routerLink]="'regulation'">REGULATION</a></li>
          <li>- <a [routerLink]="'contactus'">CONTACT US</a></li>
        </ul>
      </div>
      <div class="col-lg-4 col-xs-12 location">
        <h5 class="mt-lg-0 mt-sm-4">Location</h5>
        <p>{{ addressEN }}</p>
        <p class="mb-1"><i class="bi bi-telephone me-2"></i>{{ Telephone }}</p>
        <p class="mb-0"><i class="bi bi-envelope me-2"></i>{{ EmailContact }}</p>
      <a href="{{facebook}}" target="_blank" class="btn btn-sm btn-outline-secondary-footer p-0"><i class="bi bi-facebook me-2"></i> Facebook</a>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col copyright mt-2">
        <p class="">
          <small>Copyright © IICE 2024</small> |
          <small>
            Version
            <a href="version" class="a-walai text-white">20240126</a></small
          >
        </p>
      </div>
    </div>
  </div>
</div>

<!-- <div class="Cookies-footer alert alert-primary alert-dismissible fade show" role="alert">
      <h1 class="display-4">Hello</h1>
      Your privacyWe use cookies to ensure the functionality of our website, to personalize content and advertising, to provide social media features, and to analyze our traffic. If you allow us to do so, we also inform our social media, advertising and analysis partners about your use of our website. You can decide for yourself which categories you want to deny or allow. Please note that based on your settings not all functionalities of the site are available. View our privacy policy
      <a class="btn btn-primary btn-lg" href="#" role="button">Accept All Cookies</a>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->
