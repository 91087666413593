<div class="container mt-4">
  <div id="page-content-wrapper">
    <div class="card me-3 shadow border-0 mt-5">
      <div class="card-title bg-light mb-0">
        <div class="row row-cols-auto justify-content-between p-4 pb-0">
          <h4><i class="bi bi-download"></i> {{ "Export" | translate }}</h4>
          <div class="d-flex align-items-center gap-3" [formGroup]="FGcheckall">
            <div class="form-check form-switch my-auto">
              <input
                (change)="selectAll($event)"
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckAll"
                name="flexSwitchCheckAll"
               
                formControlName="checkall"
              />
              <label
                class="form-check-label fs-6 text-walai-default-color"
                for="flexSwitchCheckAll"
              >
                <!-- <i  class="bi bi-file-earmark-arrow-down"></i>  -->
                Select all</label
              >
            </div>

            <div class="dropdown">
              <a
                class="btn btn-primary dropdown-toggle py-1"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
              <i class="bi bi-download"></i>
                {{ "Export" | translate }}
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a
                    class="dropdown-item pointer"
                    (click)="export('MARC')"
                    data-bs-toggle="modal"
                    data-bs-target="#exportModal"
                    ><i class="bi bi-file-earmark-arrow-down"></i> MARC</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item pointer"
                    (click)="export('MARCISO2709')"
                    data-bs-toggle="modal"
                    data-bs-target="#exportModal"
                    ><i class="bi bi-file-earmark-arrow-down"></i> ISO 2709</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item pointer"
                    (click)="export('Bibliography')"
                    data-bs-toggle="modal"
                    data-bs-target="#exportModal"
                    ><i class="bi bi-file-earmark-arrow-down"></i>
                    Bibliography</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item pointer"
                    (click)="export('ENDNOTE')"
                    data-bs-toggle="modal"
                    data-bs-target="#exportModal"
                    ><i class="bi bi-file-earmark-arrow-down"></i> ENDNOTE</a
                  >
                </li>

                <li>
                  <a
                    class="dropdown-item pointer"
                    (click)="export('DublinCore')"
                    data-bs-toggle="modal"
                    data-bs-target="#exportModal"
                    ><i class="bi bi-file-earmark-arrow-down"></i> Dublin
                    Core</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item pointer"
                    (click)="export('APAStyle')"
                    data-bs-toggle="modal"
                    data-bs-target="#exportModal"
                    ><i class="bi bi-file-earmark-arrow-down"></i>
                    Bibliography(APA style)</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item pointer"
                    (click)="export('RISForZotero')"
                    data-bs-toggle="modal"
                    data-bs-target="#exportModal"
                    ><i class="bi bi-file-earmark-arrow-down"></i> RIS for
                    Zotero</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item pointer"
                    (click)="export('RISForMendeley')"
                    data-bs-toggle="modal"
                    data-bs-target="#exportModal"
                    ><i class="bi bi-file-earmark-arrow-down"></i> RIS for
                    Mendeley</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>

        <p class="text-secondary mb-0 wl-fs-18 p-4 pt-0">
          เลือก <span class="text-dark" *ngIf="bibs.length != 0;else count0">{{ countselected }}</span> 
          <ng-template #count0>0</ng-template>
          จาก <span class="text-dark">{{ bibs.length }}</span> ชื่อเรื่อง
        </p>
      </div>

      <div class="card-body pt-0">
        <div class="container">
          <ul class="list-unstyled">
            <!-- <li
              class="media mb-4 mb-lg-5 row justify-content-between border-top pt-3 pt-lg-5"
              *ngFor="let item of bibs"
            >
              <div class="col-12 order-1 col-lg-auto  order-lg-0 mt-3 mt-lg-0">
                <app-bibitem-card [bibid]="item"></app-bibitem-card>
              </div>

              <div class="col order-0 col-lg-auto order-lg-1">
                <div class="row row-cols-auto justify-content-end">
                  <div class="form-check form-switch my-auto">
                    <input
                      (change)="AddOrRemoveSeleted(item)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      name="flexSwitchCheck"
                      id="flexSwitchCheckChecked"
                      checked
                    />
                    <label class="form-check-label fs-6 text-walai-default-color" for="flexSwitchCheckChecked">
                      Select</label
                    >
                  </div>
                  <div>
                    <button type="button"
                      (click)="deleteBib(item)"
                      class="btn btn-sm btn-danger py-1"
                      ><i class="bi bi-trash"></i> Delete</button
                    >
                  </div>
                </div>

                
              </div>
            </li> -->

            <li
            
              class="media mb-4 mb-lg-5 row justify-content-between border-top pt-3 pt-lg-5"
              *ngFor="let list of listckeckedselect; let i = index;"
            >
            
              <div class="col-12 order-1 col-lg-9 order-lg-0 mt-3 mt-lg-0">
                <app-bibitem-card [bibid]="list.bib"></app-bibitem-card>
              </div>

              <div class="col order-0 col-lg-3 order-lg-1">
                <div class="row row-cols-auto justify-content-end">
                  <div class="form-check form-switch my-auto">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      role="switch"
                      name="flexSwitchCheck"
                      id="flexSwitchCheckChecked"
                      [checked]="list.selected"
                      (change)="AddOrRemoveSeleted(list.bib, i)"
                    />
                    <label
                      class="form-check-label fs-6 text-walai-default-color"
                      for="flexSwitchCheckChecked"
                    >
                      <!-- <i  class="bi bi-file-earmark-arrow-down"></i>  -->
                      Select</label
                    >
                  </div>

                  <div>
                    <button type="button"
                      (click)="deleteBib(list.bib, i)"
                      class="btn btn-sm btn-danger py-1"
                      ><i class="bi bi-trash"></i> Delete</button
                    >
                  </div>
                </div>
              </div>
            </li>

            <!-- <li class="media mb-5" *ngFor="let item of bibs">
              <div class="row">
                <div class="col-12">
                  <div class="form-check form-switch mt-2">
                    <input
                      (change)="AddOrRemoveSeleted(item)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexSwitchCheckChecked"
                      >select</label
                    >
                    <span>
                      <a
                        (click)="deleteBib(item)"
                        class="badge fs-sm text-white bg-danger shadow-info text-decoration-none mb-3 ms-2 pointer"
                        ><i class="bi bi-trash"></i> Delete</a
                      ></span
                    >
                  </div>
                </div>
              </div>
              <app-bibitem-card [bibid]="item"></app-bibitem-card>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exportModal"
  tabindex="-1"
  aria-labelledby="eexportModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exportModalLabel">
          Export : {{ exportType }}
        </h5>
        <a
          class="ms-3 pointer"
          (click)="saveAsTextFile()"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Save"
        >
          <i class="bx bx-download me-1"></i
        ></a>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <!-- sinner -->
        <div class="row pb-5" *ngIf="loading">
          <div class="col-md-12 p-5">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            Loading...
            <!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner> -->
          </div>
        </div>
        <div class="card card-body">
<div *ngIf="type!=='MARC'">
     <ng-container *ngFor="let exp of datalist">
          <p  *ngFor="let item of exp.databib">
            {{ item.data }}
          </p>

        </ng-container>
</div>

<div *ngIf="type == 'MARC'" class="table-responsive">
  <table class="table">
    <thead class="table-primary">
      <tr>
        <th scope="col">Tag</th>
        <th scope="col">Indicator1</th>
        <th scope="col">Indicator2</th>
        <th scope="col" class="text-center">Subfield</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let exp of dataMARClist">
             <tr *ngFor="let tag of exp">
        <th scope="row">{{ tag.TagID }}</th>
        <td class="text-center">{{ tag.Indicator1 }}</td>
        <td class="text-center">{{ tag.Indicator2 }}</td>
        <td class="text-justify">
          <p
            style="max-width: 500px"
            [innerHTML]="tag.Subfield"
          ></p>
        </td>
      </tr>
      </ng-container>
 
    </tbody>
  </table>
</div>


        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <!-- <button type="button" class="btn btn-primary" (click)="copyText()"> <i class='bx bx-copy-alt'></i> Copy</button> -->
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveAsTextFile()"
        >
          <i class="bi bi-file-earmark-arrow-down"></i> Save
        </button>
      </div>
    </div>
  </div>
</div>
