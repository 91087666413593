<app-header *ngIf="networkOk">
</app-header>
<router-outlet *ngIf="networkOk"></router-outlet>
<app-footer *ngIf="networkOk">
</app-footer>


<div class="container text-center" *ngIf="!networkOk">
    <div class="row mt-5 pt-5">
          <div class="col-12 card-body pt-5">
    <h1 class="card-title">Error API</h1>
    <h4 class="card-text p-4">Unable to connect to API</h4>
    <a href="home"  class="btn btn-primary"><i class="bi bi-arrow-clockwise"></i>Try again</a>
  </div>  
    </div>

</div>




<a id="back-to-top" (click)="onActivate($event)" 
title="Back to top"
class="btn btn-light btn-lg back-to-top btn-sm" role="button"><i class="fas fa-chevron-up"></i></a>


