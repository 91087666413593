
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Keywords , Mattype, Facet, CurrentFilter, AutoComplete, AutoCompleteResponse } from '../models/walai';
import { AutocompleteService } from '../services/autocomplete.service';
import { HomeService } from '../services/home.service';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'app-bookshelfdiscovery',
  templateUrl: './bookshelfdiscovery.component.html',
  styleUrls: ['./bookshelfdiscovery.component.css']
})
export class BookshelfdiscoveryComponent implements OnInit {
  bookList: any;
  bookcount = '...';
  keyword: Keywords;
  Mattype: Mattype;
  CurrentFilter: Array<CurrentFilter> = []; //คำที่ค้น
  CurrentKeyword: Array<CurrentFilter> = []; //คำที่ค้น
  DisplayTable: string;
  DisplayList: string;

  Ntk: any[];
  Ntt: any[];
  Nto: any[];

  locationID: string;
  locationName: string;

  qsearchForm: UntypedFormGroup;

  notEmptyPost = true;
  notscrolly = true;

  allFacet: Facet[];
  subjectFacet: Array<Facet> = [];
  subjectFacetLess: Array<Facet> = [];
  subjectFacetLessShow: boolean = true;

  authorFacet: Array<Facet> = [];
  authorFacetLess: Array<Facet> = [];
  authorFacetLessShow: boolean = true;

  locationFacet: Array<Facet> = [];
  locationFacetLess: Array<Facet> = [];
  locationFacetLessShow: boolean = true;


  sourceTypeFacet: Array<Facet> = [];
  sourceTypeFacetLess: Array<Facet> = [];
  sourceTypeFacetLessShow: boolean = true;

  facetLess: number = 6;

  minYear: number;
  maxYear: number;

  minYearValue: number = 0;
  maxYearValue: number = 0;
  //optionsYear: Options;
  // optionsYear: Options = {
  //   floor: 0,
  //   ceil: 0,
  // };
  // options: Options = {
  //   floor: 1000,
  //   ceil: 2563,
  // };

  Qkeyword: string;
  listAuto: AutoCompleteResponse[] = [];

  
  
  constructor(
    private spinner: NgxSpinnerService,
    private searchService: SearchService,
    private homeService: HomeService,
    private appService: AutocompleteService
    ) { }

  ngOnInit(): void {

  }

  
      //Function ที่ใช้สืบค้น ของหน้า Result เอง โดยส่งมาจาก Text Search
      search() {
        if (this.qsearchForm.controls.textSearch.value != '') {
          // Textbox Search ด้านบน
    
          //ดึง Location ที่มีการใส่ไว้ใน  localStorage มาส่งเพิ่มใน Function สืบค้น เพราะในระบบนี้มีการแสดงผลแบบแยก Location เป็นหลัก
          this.locationID = localStorage.getItem('locationIDMain'); //location หลัก
          this.locationName = localStorage.getItem('locationNameMain');
    
          //เพิ่มค่าให้แก่ตัวแปร  Keyword Ntk คือประเภทการสืบค้น Ntt คือคำค้น Nto คือ Operator ที่ใช้สำหรับคำค้นที่มีมากกว่าหนึ่งซึ่งเป็นได้ทั้ง And Or Not
          this.keyword.Ntk = 'KEYWORD';
          this.keyword.Ntt = this.qsearchForm.controls.textSearch.value;
    
          if (this.locationID != '') {
            //กรณีมีการระบุสาขา ซึ่งถ้าเป็นค่าว่าง จะไม่ระบุสาขา
            this.keyword.Ntk += '|LOCATION';
            this.keyword.Ntt += '|' + this.locationID;
          }
          this.keyword.Nto = '';
          this.keyword.CurrentPage = 1; // CurrentPage หน้าที่แสดงผลในการสืบค้นครั้งใหม่จะเริ่มที่หน้าแรกเสมอ
          this.keywordSearch(this.keyword); //ส่งค่าไปยัง Function การสืบค้น
        }
      }

  //Function การสืบค้นที่มีการไปดึงผลการสืบค้นจาก API
  keywordSearch(keyword_: Keywords) {
    this.Ntk = keyword_.Ntk.split('|'); //ประเภทการสืบค้น
    this.Ntt = keyword_.Ntt.split('|'); //คำค้น
    this.Nto = keyword_.Nto.split('|');

    //แสดงรายการคำค้น ประเภทการสืบค้น และ ตัวกรองต่างๆ ที่ใช้
    this.GetCurrentFilterAndKeyword();
    this.bookList = [];

    //หมุนๆ รอก่อนผลจะมา
    this.spinner.show();

 
    //ส่งคำไปสืบค้นและรอ Api
    this.searchService.keywordsearch(keyword_).subscribe(
      (results: any) => {
        if (results) {

          this.bookcount = results.Counts[0].Count; //จำนวนผลการสืบค้น

          this.bookList = results.Display; //รายการหนังสือที่เจอ
          this.allFacet = results.Narrow; //ตัวกรองทั้งหมด

          this.getFacet(); //แสดงรายละเอียดของตัวกรองด้านซ้าย
        } else {

          this.setNull(); //ในกรณีไม่พบผลลัพธ์การสืบค้นให้ Set ตัวแปรต่างๆ เป็นค่าว่าง เพื่อให้ Control ที่แสดงผลเป็นค่าว่างๆ ผู้ใช้จะได้ไม่ งง
        }
        this.spinner.hide(); //ซ่อนตัวหมุนๆ
      },
      (error) => {

        //ในกรณี Error  ให้ Set ตัวแปรต่างๆ เป็นค่าว่าง เพื่อให้ Control ที่แสดงผลเป็นค่าว่างๆ ผู้ใช้จะได้ไม่ งง
        this.setNull();
        this.spinner.hide();
      }


    );
  }

  //แสงเรายการคำค้นและ Filter ด้านซ้าย
  GetCurrentFilterAndKeyword() {
    //Set ค่าว่างการเสมอ มีประโยชน์ในการโหลดครั้งแรกและเมื่อผลลัพธ์ Error
    this.CurrentFilter = [];
    this.CurrentKeyword = [];

    for (let index = 0; index < this.Ntk.length; index++) {
      const k = this.Ntk[index];
      const t = this.Ntt[index];
      let Filter: CurrentFilter;
      Filter = new CurrentFilter();
      Filter.Ntk = k;
      Filter.Ntt = t;
      Filter.id = t;
      switch (k) {
        case 'LOCATION':
          this.homeService.getLocationName(t).subscribe(
            (locationName: string) => {
              Filter.Ntt = locationName;
            },
            (error) => {
              this.setNull();
            }
          );

          Filter.id = t;
          this.CurrentFilter.push(Filter);
          break;
        case 'SUBJECTFACET':
          this.CurrentFilter.push(Filter);
          break;
        case 'AUTHORFACET':
          this.CurrentFilter.push(Filter);
          break;
        case 'PUBYEAR':
          this.CurrentFilter.push(Filter);
          break;
          case 'MEDIATYPE':
            Filter.Ntt = this.getMEDIATYPENameByID(t);
            this.CurrentFilter.push(Filter);
            break;
        default:
          //จะสกัด Filter ออกเป็น Case ก่อนหน้าและถ้าเป็น Case อื่นๆ ก็ให้มองเป็น คำค้น
          this.CurrentKeyword.push(Filter);
          break;
      }
    }
  }

  //แสดงรายการ Facet หรือ Filter Result โดยแต่ละประเภทการ Filter จะมีการแยกไปยังตัวแปลคนละตัวกัน
  getFacet() {
    this.subjectFacet = [];
    this.subjectFacetLess = [];

    this.authorFacet = [];
    this.authorFacetLess = [];

    this.locationFacet = [];
    this.locationFacetLess = [];

    this.sourceTypeFacet = [];
    this.sourceTypeFacetLess = [];

    let isubject: number = 0;
    let iauthor: number = 0;
    let ilocation: number = 0;
    let isourceType: number = 0;
    let firstYear: boolean = true;

  
    //นำค่าที่ได้จาก Function สืบค้น ของ API ในส่วนที่เป็น Facet มาวนแยกเพื่อใส่ลงไปตามประเภทของตัวแปรที่ได้เตรียมไว้
    this.allFacet.forEach((element) => {
      switch (element.Type) {
        case 'subjectfacet':
          this.subjectFacet.push(element);
          isubject += 1;
          if (isubject <= this.facetLess) {
            this.subjectFacetLess.push(element);
          }
          break;
        case 'authorfacet':
          this.authorFacet.push(element);
          iauthor += 1;
          if (iauthor <= this.facetLess) {
            this.authorFacetLess.push(element);
          }
          break;
        case 'location':
          this.locationFacet.push(element);
          ilocation += 1;
          if (ilocation <= this.facetLess) {
            this.locationFacetLess.push(element);
          }
          break;
        case 'pubyear':
          //        this.optionsYear = {
          //   floor: 0,
          //   ceil: 1600,
          // };

          // this.minYearValue= this.optionsYear.floor ;
          // this.maxYearValue= this.optionsYear.ceil ;

          if (Number(element.name)) {
            if (firstYear == true) {
              // ค่าแรกสุดเป็นค่าตั้งต้นให้ตัวแปร
              this.minYear = Number(element.name);
              this.maxYear = Number(element.name);
              firstYear = false;
            } else {
              //   console.log(element.name);
              if (Number(element.name) > this.maxYear) {
                this.maxYear = Number(element.name);
              }
              if (Number(element.name) < this.minYear) {
                this.minYear = Number(element.name);
              }
            }
          }

          break;
          case 'mattype':
            this.sourceTypeFacet.push(element);
            isourceType += 1;
            if (isourceType <= this.facetLess) {
              this.sourceTypeFacetLess.push(element);
            }
            break;
        default:
          break;
      }
    });

    this.PrepareYear();
  }

  //Set ค่าว่างให้ตัวแปลที่ใช้แสดงผลใน Control ต่างๆ เพื่อผลที่ดีสำหรับกรณีไม่มีข้อมูลมาแสดงผล
  setNull() {
    this.bookcount = null;
    this.subjectFacet = null;
    this.subjectFacetLess = null;

    this.authorFacet = null;
    this.authorFacetLess = null;

    this.locationFacet = null;
    this.locationFacetLess = null;
  }

  //จัดการข้อมูลปีพิมพ์
  PrepareYear() {
    // this.minYear =  Number(element.name);
    // this.maxYear =  Number(element.name);
    // this.optionsYear = {
    //   floor: this.minYear,
    //   ceil: this.maxYear,
    // };

    //alert( this.optionsYear.floor);
    this.minYearValue = this.minYear;
    this.maxYearValue = this.maxYear;
  }

  getMEDIATYPENameByID(id){
    const type = ["Unknow", "Book", "Continuing Resources", "Music", "Map", "Visual Materials", "Computer Files", "Mixed Materials","", "Article", "ebook"];
    return type[id];
  }

  EnterSearch(keyWord: string) {
    if (this.locationID != '') {// กรณีมีการระบุสาขา ซึ่งถ้าเป็นค่าว่าง จะไม่ระบุสาขา
      this.Qkeyword = 'results?Ntk=KEYWORD|LOCATION&Ntt=' + keyWord + '|' + this.locationID;
    } else {
      this.Qkeyword = 'results?Ntk=KEYWORD&Ntt=' + keyWord;
    }
    window.location.href = this.Qkeyword;
  }

  

  auto(value) {
    if (value.length >= 2) {
      let autoCompleteWord: AutoComplete;
      autoCompleteWord = new AutoComplete();
      autoCompleteWord.Keyword = value;
      autoCompleteWord.Location = '';
      this.appService.autoSuggest(autoCompleteWord).subscribe(
        (results: any) => {
          if (results) {
            JSON.stringify(results);
            this.listAuto = results;
            // add newly fetched posts to the existing post
          } else {
            this.listAuto = [];
          }
        },
        (error) => { }
      );
    } else {
      this.listAuto = [];
    }
  }


  RemoveKeyword(type, word) {
    //keyword จะถูกเชื่อมด้วย | ดังนั้นจะต้องแยกประเภทการสืบค้นและคำค้นต่างๆ ออกจากกันก่อน โดยคำค้นและประเภทนั้นถูกเรียงกันเป็นลำดับอย่างชัดเจนตั้งแต่ส่งมาแล้ว
    this.Ntk = this.keyword.Ntk.split('|');
    this.Ntt = this.keyword.Ntt.split('|');
    this.Nto = this.keyword.Nto.split('|');

    for (let index = 0; index < this.Ntk.length; index++) {
      //วนลบคำค้น
      if (this.Ntk[index] == type && this.Ntt[index] == word) {
        //พบตำแหน่งของคำค้นที่ต้องการลบ *คำและประเภทตรงกัน
        this.Ntk.splice(index, 1);
        this.Ntt.splice(index, 1);
      }
    }

    this.keyword.Ntk = '';
    this.keyword.Ntt = '';
    for (let index = 0; index < this.Ntk.length; index++) {
      //วนลบคำค้น
      this.keyword.Ntk += this.Ntk[index] + '|';
      this.keyword.Ntt += this.Ntt[index] + '|';
    }

    //ตัด | ตัวสุดท้ายออก
    this.keyword.Ntk = this.keyword.Ntk.slice(0, -1);
    this.keyword.Ntt = this.keyword.Ntt.slice(0, -1);

    //เมื่อลบคำค้นเรียบร้อยแล้ว จะมีการไปลืบค้นหาผลลัพธ์ใหม่
    this.keywordSearch(this.keyword);
  }

  //เพิ่มตัวกรองการสืบค้น
  addFilter(type, name) {
    this.keyword.CurrentPage = 1;
    //การเพิ่มตัวกรองการสืบค้นทำได้โดยการใช้ | ต่อท้ายคำค้นและประเภทการสืบค้นได้เลย
    this.keyword.Ntk += '|' + type;
    this.keyword.Ntt += '|' + name;
    this.keywordSearch(this.keyword);
  }

  
  addFilterYear(event) {
    this.keyword.CurrentPage = 1;
    this.keyword.Ntk += '|PUBYEAR';
    this.keyword.Ntt += '|[ ' + event.value + ' TO ' + event.highValue + ']';
    this.keywordSearch(this.keyword);
    // alert(event.value);
    // alert(event.highValue);
  }

   //เมื่อกดปุ่มดูเพิ่มเติมของ Author
   MoreAuthor(value) {
    this.authorFacetLessShow = value;
  }

    //เมื่อกดปุ่มดูเพิ่มเติมของ Subject
    MoreSubject(value) {
      this.subjectFacetLessShow = value;
    }

    //เมื่อกดปุ่มดูเพิ่มเติมของ Location
  MoreLocation(value) {
    this.locationFacetLessShow = value;
  }


}
