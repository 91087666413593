<!-- Button trigger modal -->
<button type="button" class="btn btn-primary btn-sm py-1 fw-light shadow me-2" data-bs-toggle="modal"
  data-bs-target="#MARCHoldingModal">
  MARC Holding
</button>


<!-- Modal -->
<div class="modal fade" id="MARCHoldingModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle"> MARC Holding</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>


      <div class="modal-body pb-0" *ngFor="let h of holdings">
        <h5 class=" text-primary ml-3">{{h.locationnamethai}}</h5>
        <div class="table-responsive">
          <table class="table">
            <thead class="table-primary">
              <tr>
                <th scope="col">Tag</th>
                <th scope="col">Indicator1</th>
                <th scope="col">Indicator2</th>
                <th scope="col" class="text-center">Subfield</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tag of h.marcs">
                <th scope="row">{{tag.TagID}}</th>
                <td class="text-center">{{tag.Indicator1}}</td>
                <td class="text-center">{{tag.Indicator2}}</td>
                <td class="text-justify">
                  <p [innerHTML]="tag.Subfield"></p>
                </td>
              </tr>


            </tbody>
          </table>
        </div>

      </div>

      <div class="modal-footer">
        <a role="button" data-bs-dismiss="modal" class="btn btn-outline-secondary btn-sm py-1">
          <span class="ml-auto mr-auto">Close</span></a>
      </div>
    </div>
  </div>
</div>