<div class="container mt-5">
  <h4>News</h4>
  <div class="container mt-5 container-walai">

    <hr>
    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()">

      <div class="card mb-3" *ngFor="let result of displayResult">
        <div class="row g-0">
          <div class="col-md-4">
            <img src="{{result.NEWSIMAGES}}" class="img-fluid rounded-start" alt="{{result.NEWSTITLE}}"
              onError="this.src='assets/images/noImageNew.jpeg'">
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title"><a target="_blank"
                  routerLink="../newsdetail/{{result.NEWSID}}">{{result.NEWSTITLE}}</a>
              </h5>
              <p class="card-text"><small class="text-muted">Last updated {{result.NEWSDATE}} </small></p>
              <p class="card-text" innerHTML="{{result.NEWSLEAD}}"></p>

            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- sinner -->
    <div class="row pb-5">
      <div class="col-md-12 p-5">
        <ngx-spinner bdOpacity="0.9" bdColor="" size="default" color="#581f87" type="ball-pulse-sync"
          [fullScreen]="false"></ngx-spinner>
      </div>
    </div>
    <!-- Alert no more post -->
    <div *ngIf="!notEmptyPost" class="row pb-5">
      <div class="col-md-12 d-flex justify-content-center">
        <div class="text-muted h6 alert alert-secondary p-2">
          {{ 'No more resource to show' | translate }}
        </div>
      </div>
    </div>
  </div>




</div>