import { Injectable } from '@angular/core';
//import { stringify } from 'querystring';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { HomeService } from './home.service';
import { SearchService } from './search.service';
import { DownloadHistory, UserClient } from '../models/walai';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private countNoti = new BehaviorSubject<number>(0);
  currentNoti = this.countNoti.asObservable();

  private NotiStyle = new BehaviorSubject<string>('d-none');
  currentNotiStyle = this.NotiStyle.asObservable();

  // private profile = new BehaviorSubject<string>('d-flex');

  private countHold = new BehaviorSubject<number>(0);
  currentHold = this.countHold.asObservable();

  private countCollection = new BehaviorSubject<number>(0);
  currentCountCollection = this.countCollection.asObservable();

  private countSuggest = new BehaviorSubject<string>('0');
  currentCountSuggest = this.countSuggest.asObservable();

  private countReviews = new BehaviorSubject<number>(0);
  currentCountReviews = this.countReviews.asObservable();

  private countTags = new BehaviorSubject<number>(0);
  currentCountTags = this.countTags.asObservable();



  private countSuggestFriend = new BehaviorSubject<number>(0);
  currentCountSuggestFriend = this.countSuggestFriend.asObservable();
  


  private CountNDDBasketlist = new BehaviorSubject<number>(0);
  currentCountNDDBasketlist = this.CountNDDBasketlist.asObservable();

  // private countDeliveryOrder = new BehaviorSubject<number>(0);
  // currentCountDeliveryOrder = this.countDeliveryOrder.asObservable();

  private bibList = new BehaviorSubject([]);
  currentBibList = this.bibList.asObservable();

  private mail = new BehaviorSubject<string>('');
  currentMail = this.mail.asObservable();
  updatemail(data: string) {
    this.mail.next(data);
  }

  private Fname = new BehaviorSubject<string>('');
  currentFname = this.Fname.asObservable();
  updateFname(data: string) {
    this.Fname.next(data);
  }

  private Lname = new BehaviorSubject<string>('');
  currentLname = this.Lname.asObservable();
  updateLname(data: string) {
    this.Lname.next(data);
  }

  private MemberImg = new BehaviorSubject<string>('');
  currentMemberImg = this.MemberImg.asObservable();
  updateMemberImg(data: string) {
    this.MemberImg.next(data);
  }

  private logo = new BehaviorSubject<string>('');
  currentlogo = this.logo.asObservable();
  updatelogo(data: string) {
    this.logo.next(data);
  }

  private colorFooter = new BehaviorSubject<string>('var(--wl-color-footer)');
  currentcolorFooter = this.colorFooter.asObservable();
  updatecolorFooter(data: string) {
    this.colorFooter.next(data);
  }

  private colorBGFooter = new BehaviorSubject<string>(
    'var(--wl-bg-color-footer)'
  );
  currentcolorBGFooter = this.colorBGFooter.asObservable();
  updatecolorBGFooter(data: string) {
    this.colorBGFooter.next(data);
  }

  updateUserData(data: any) {
    this.bibList.next(data);
  }

  constructor(
    private homeService: HomeService,
    private ss: SearchService,
    private http: HttpClient
  ) {}

  changeCountHold(countHold: number) {
    this.countHold.next(countHold);
  }

  changeCountNoti(countNoti: number) {
    this.countNoti.next(countNoti);
  }

  changeNotiStyle(style: string) {
    this.NotiStyle.next(style);
  }

  changeCountCollection(countCollection: number) {
    this.countCollection.next(countCollection);
  }

  changeCountSuggest(countSuggest: string) {
    this.countSuggest.next(countSuggest);
  }

  changeCountReviews(countReviews: number) {
    this.countReviews.next(countReviews);
  }
  changeCountTags(countTags: number) {
    this.countTags.next(countTags);
  }

  changeCountSuggestFriend(countTags: number) {
    this.countSuggestFriend.next(countTags);
  }
  

  changeCountNDDBasketlist(CountNDDBasketlist: number) {
    this.CountNDDBasketlist.next(CountNDDBasketlist);
  }

  // changeCountDeliveryOrder(countDeliveryOrder: number){
  //   this.countDeliveryOrder.next(countDeliveryOrder);
  // }

  saveForExport(bibid: string) {
    let listBib: any = [];
    if (localStorage.getItem('listBib') != null) {
      let bibStr: any = localStorage.getItem('listBib');
      var splitted = bibStr.split(',');
      splitted.forEach((element: any) => {
        if (element != '') {
          listBib.push(element);
        }
      });
    }

    if (listBib.indexOf(bibid, 0) == -1) {
      //เช็คซ้ำ
      listBib.push(bibid);
      this.updateUserData(listBib);
      localStorage.setItem('listBib', listBib);
    }

    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Book has been saved',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  getLogo() {
    this.homeService.getFilePath('Logo').subscribe(async (data: any) => {
      return data + 'web-logo.png';
    });
  }

  addDownloadHistory(bibid: string, url: string) {
    try {
      this.http
        .get('https://geolocation-db.com/json/')
        .subscribe((res: any) => {
          var data: DownloadHistory = {
            BIBID: bibid,
            URL: url,
            USERIPADDRESS: res.IPv4,
            USERAGENT: window.navigator.userAgent,
          };

          this.ss.addDownloadHistory(data).subscribe(
            (results: any) => {},
            (error) => {}
          );
        });
    } catch (error) {}
  }

  addOpacview() {
    
    try {
      this.http
        .get('https://geolocation-db.com/json/')
        .subscribe((res: any) => {
          var data: UserClient = {
            ipaddress: res.IPv4,
            agent: window.navigator.userAgent,
          };
          this.homeService.addOpacview(data).subscribe(
            (results: any) => {},
            (error) => {}
          );
        });
    } catch (error) {}
    } 


  
}
