import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortTitle'
})
export class ShortTitlePipe implements PipeTransform {

  transform(value: string, ...args: number[]): unknown {
    args[0]
    return value.length> args[0]?value.substring(0, args[0])+' ...':value;
  }

}
