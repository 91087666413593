import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  value :string= '';
  constructor(
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.value = '';
    this.adminService
      .getAboutUSContactRule('contact')
      .subscribe((val:  string) => {
        this.value = val;
      });
  }

}
