import { Component, OnInit, Input } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { book856, Mattype } from 'src/app/models/walai';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-electronic-resource',
  templateUrl: './electronic-resource.component.html',
  styleUrls: ['./electronic-resource.component.css'],
})

// ไว้สำหรับแสดงปุ่มของหนังสือที่มีข้อมูล Tag 856 หรือ Link ไปยังสื่อต่างๆ
export class ElectronicResourceComponent implements OnInit {
  book856: book856[] = [];
  bookStatusList: any[] = [];
  status: string;
  matTypeName = '';

  @Input() public bibid: string;

  closeResult = '';
  constructor(
    private homeService: HomeService,
    private modalService: NgbModal,
    private dataS: DataService
  ) {}

  ngOnInit(): void {
    this.getGetBook856();
    this.getStatus();
    this.getBookNewMatType();
  }

  //ประวัติการ Download
  addHistory(url: string) {
    this.dataS.addDownloadHistory(this.bibid, url);
  }

  getBookNewMatType() {
    this.homeService
      .getBookNewMatType(this.bibid)
      .subscribe((typeName: string) => {
        const matType = new Mattype();
        if (typeName) {
          this.matTypeName = matType.GetMattypeName(typeName);
        } else {
          this.matTypeName = '';
        }
      });
  }

  // แสดงรายการ 856
  getGetBook856() {
    this.homeService.getBook856(this.bibid).subscribe((book856: any) => {
      //console.log(book856)
      if (book856) {
        this.book856 = book856;
      } else {
        this.book856 = [];
        // this.book856[0].URL
      }
    });
  }

  // แสดงสถานะของหนังสือในหน้าผลการสืบค้น
  getStatus() {
    this.bookStatusList = [];
    var Available = false;
    let NotAvailable = false;
    let ElectronicResource = false;
    this.homeService.getItemStatus(this.bibid).subscribe((bookStatus: any) => {
      // if(this.bibid =='b00089990'){
      //   console.log('b00089990')
      // console.log(bookStatus)
      // }

      this.status = '';
      if (bookStatus) {
        this.bookStatusList = bookStatus;
        this.status = '';

        if (bookStatus.length > 0) {
          bookStatus.forEach((element) => {
            if (element.ITEMSTATUSNAME == 'Available') {
              // ถ้าเป็น Available ให้ออกจาก Loop
              Available = true;
              this.status = 'Available';
            } else if (element.ITEMSTATUSNAME.trim() == 'Electronic Resource') {
              // เป็น online
              ElectronicResource = true;
            } else {
              // ถ้าไม่   'Available'
              this.status = 'Not Available';
              NotAvailable = true;
            }
          });
        } else {
          this.status = '';
        }
      } else {
        this.status = '';
      }

      if (Available == true) {
        this.status = 'Available';
      }

      if (ElectronicResource && NotAvailable) {
        // กรณีเป็น Status ElectronicResource ไม่ต้อง Show ปุ่มแสดง Status
        this.status = '';
      }
    });
  }

  // Modal popup
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  // กดปิด Modal Pupup
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
