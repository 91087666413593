import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BibitemComponent } from './bibitem/bibitem.component';
import { LoginComponent } from './member/login/login.component';
import { ResultsComponent } from './results/results.component';
import {  SubcategoryComponent} from './category/subcategory/subcategory.component';
import { CategoryComponent} from './category/category/category.component';
import { VersionComponent } from './version/version.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { RegulationComponent } from './regulation/regulation.component';
import { VirtualbookshelfComponent } from './virtualbookshelf/virtualbookshelf.component';
import { NewListComponent } from './news/new-list/new-list.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { ExportSaveComponent } from './export-save/export-save.component';
import { BookshelfdiscoveryComponent } from './bookshelfdiscovery/bookshelfdiscovery.component';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'bibitem', component: BibitemComponent },
  { path: 'export', component: ExportSaveComponent },
  { path: 'category', component: CategoryComponent },
  { path: 'subcategory', component: SubcategoryComponent },
  { path: 'version', component: VersionComponent },

  { path: 'aboutus', component: AboutusComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'regulation', component: RegulationComponent },
  { path: 'virtualbookshelf/:itemid', component: VirtualbookshelfComponent },
  { path: 'bookshelfdiscovery', component: BookshelfdiscoveryComponent },

  { path: 'results', component: ResultsComponent },
  { path: 'member', loadChildren: () => import('./member/member.module').then(m => m.MemberModule) },
  { path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule) },
 { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
 
 { path: 'news', component: NewListComponent },
 { path: 'newsdetail/:id', component: NewsDetailComponent },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
