import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'link856'
})
export class Link856Pipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value ;
  }

}
