import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { AdminTagsApproveParam, TagCloudNameList, adminSetting, adminValue, config, libSuggestion, libSuggestionStatus, newsAdd, newsParam, reviewData, reviewDataAdd } from '../models/walai';
import { catchError } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    //authorization: ''
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private baseUrl = '';
  header = new HttpHeaders();

  constructor(private http: HttpClient) {
    //this.header.append('authorization','');
    this.header.append('Content-Type', 'application/json');
  }
  private handleError(err: HttpErrorResponse): Observable<never> {
    // just a test ... more could would go here
    return throwError(() => err);
  }
  getGeneralPageSetting(): Observable<adminSetting[]> {
    this.baseUrl = `${environment.apiurl}/api/GeneralPageSetting`;
    return this.http.get<adminSetting[]>(this.baseUrl, httpOptions);
  }

  getLDAP(): Observable<adminSetting[]> {
    this.baseUrl = `${environment.apiurl}/api/getLDAP`;
    return this.http.get<adminSetting[]>(this.baseUrl, httpOptions);
  }

  PutGeneralPageSetting(parameterkey: string, val: adminValue) {
    this.baseUrl = `${environment.apiurl}/api/GeneralPageSetting/${parameterkey}`;
    return this.http.put<any>(this.baseUrl, val, { headers: this.header });
  }

  getAboutUSContactRule(type: string): Observable<string> {
    this.baseUrl = `${environment.apiurl}/api/AboutUSContactRule/${type}`;
    return this.http.get<string>(this.baseUrl, httpOptions);
  }

  PutAboutUSContactRule(type: string, val: adminValue) {
    this.baseUrl = `${environment.apiurl}/api/AboutUSContactRule/${type}`;
    return this.http.put<any>(this.baseUrl, val, { headers: this.header });
  }

  getLibrarianSuggest(): Observable<libSuggestion[]> {
    this.baseUrl = `${environment.apiurl}/api/LibrarianSuggest`;
    return this.http.get<libSuggestion[]>(this.baseUrl, httpOptions);
  }

  addLibrarianSuggest(bibid: string) {
    this.baseUrl = `${environment.apiurl}/api/LibrarianSuggest/${bibid}`;
    return this.http.post<libSuggestionStatus>(this.baseUrl, { headers: this.header });
  }

  deleteLibrarianSuggest(bibid: adminValue) {
    let body = bibid;
    this.baseUrl = `${environment.apiurl}/api/LibrarianSuggest`;

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: body,
    };

    return this.http.delete<any>(this.baseUrl, httpOptions1);
  }

  newsList(val: newsParam): Observable<any>{
     return this.http.post<any>(`${environment.apiurl}/api/newsList`, val, { headers: this.header });
   }

   newsAdd(val: newsAdd): Observable<any>{
    return this.http.post<any>(`${environment.apiurl}/api/AddNews`, val, { headers: this.header });
  }

  newsUpdate(val: newsAdd): Observable<any>{
    //console.log(val)
    return this.http.put<any>(`${environment.apiurl}/api/UpdateNews`, val, { headers: this.header });
  }


  public newsDelete(id:string): Observable<any> {
    return this.http.delete<any[]>(`${environment.apiurl}/api/news/${id}`,httpOptions);

  }

  public getNews(id:number): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiurl}/api/news/${id}`,httpOptions);
  }
  
  // public uploadFile(file: FormData,id:string,type:string): Observable<any> {
  //   const headers = {
  //     headers: new HttpHeaders().set('accept', 'application/json'),
  //   };

  //   return this.http.post<any>(
  //     `${environment.apiurl}/api/UploadFiles/${type}/${id}`,
  //     file,
  //     headers
  //   );
  // }

  public uploadFile(file: FormData,id:string,type:string): Observable<any> {
    return this.http.post<any>( `${environment.apiurl}/api/UploadFiles/${type}/${id}`,
    file,
      { observe: 'response' }).pipe(catchError(this.handleError));
  }

  addLibReview(val: reviewDataAdd): Observable<any>{
    return this.http.post<any>(`${environment.apiurl}/api/bookAddReview`, val, { headers: this.header });
  }

  updateLibReview(val: reviewDataAdd): Observable<any>{
    return this.http.put<any>(`${environment.apiurl}/api/bookUpdateReview`, val, { headers: this.header });
  }

  public getReview(id:number): Observable<reviewData[]> {
    return this.http.get<reviewData[]>(`${environment.apiurl}/api/bookGetReviewByID/${id}`,httpOptions);
  }


  GetOpacConfigValue(parameterkey:string): Observable<string> {
    this.baseUrl = `${environment.apiurl}/api/GetOpacConfigValue/${parameterkey}`;
    return this.http.get<string>(this.baseUrl, httpOptions);
  }

  getColorSetting(parameterkey:string): Observable<string> {
    this.baseUrl = `${environment.apiurl}/api/GetOpacConfigValue/${parameterkey}`;
    return this.http.get<string>(this.baseUrl, httpOptions);
  }

  public getColorSettingPipe(parameterkey:string) : Observable<any> {
    return this.http
      .get<any>(`${environment.apiurl}/api/GetOpacConfigValue/${parameterkey}`, {
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }


  getLogoBanner(parameterkey:string): Observable<string> {
    this.baseUrl = `${environment.apiurl}/api/getLogoBanner/${parameterkey}`;
    return this.http.get<string>(this.baseUrl, httpOptions);
  }

  public getLogoBannerPipe(parameterkey:string) : Observable<any> {
    return this.http
      .get<any>(`${environment.apiurl}/api/getLogoBanner/${parameterkey}`, {
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }


  PutColorSetting(inputconfig: config) {
    this.baseUrl = `${environment.apiurl}/api/StyleSetting`;
    return this.http.put<any>(this.baseUrl, inputconfig, { headers: this.header });
  }

  getFacetSetting(): Observable<any[]> {
    this.baseUrl = `${environment.apiurl}/api/GetFacetDisplay`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }
  PutFacetSetting(CDID: string, display: number) {
    this.baseUrl = `${environment.apiurl}/api/updateFacetDisplay/${CDID}/${display}`;
    return this.http.put<any>(this.baseUrl, { headers: this.header });
  }

  public AddOPACImage(form: FormData): Observable<any> {
    //console.log(form)
    return this.http.post<any>( `${environment.apiurl}/api/AddOPACImage`,
    form,
      { observe: 'response' }).pipe(catchError(this.handleError));
  }

  DeleteOPACImage(id:string): Observable<string> {
    this.baseUrl = `${environment.apiurl}/api/OPACImage/${id}`;
    return this.http.delete<string>(this.baseUrl, httpOptions);
  }

  getViewOPAC(): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/GetViewOPAC`;
    return this.http.get<any>(this.baseUrl, httpOptions);
  }

  getTopKeyword(): Observable<any[]> {
    this.baseUrl = `${environment.apiurl}/api/GetTopKeyword`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  getTagCloud(): Observable<TagCloudNameList[]> {
    this.baseUrl = `${environment.apiurl}/api/GetTag`;
    return this.http.get<TagCloudNameList[]>(this.baseUrl, httpOptions);
  }

  TagsApprove(param: AdminTagsApproveParam): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/TagsApprove`;
    return this.http.put<any>(this.baseUrl,  param, {
      headers: this.header,
    });
  }

  async TagsApproveAwait(param: AdminTagsApproveParam) {
    this.baseUrl = `${environment.apiurl}/api/TagsApprove`;
    const response = await this.http.put<any>(this.baseUrl,  param, {
      headers: this.header,
    });
    return response;
  }

  updateTagsApproveStatus(val:boolean): Observable<any> {
    this.baseUrl = `${environment.apiurl}/api/updateTagsApproveStatus/${val}`;
    return this.http.put<any>(this.baseUrl, httpOptions);
  }

}
