import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { of, Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { NDDBasketData } from 'src/app/models/modelNDD';
import { HomeService } from 'src/app/services/home.service';
import { MemberService } from 'src/app/services/member.service';
import Swal from 'sweetalert2';
import { NDDFunction } from 'src/app/models/modelNDD';
import { Router } from '@angular/router';
@Component({
  selector: 'app-rd-dialog',
  templateUrl: './rd-dialog.component.html',
  styleUrls: ['./rd-dialog.component.css'],
})
export class RdDialogComponent implements OnInit {
  public isCollapsed = false;
  @Input() dataRD: any;
  @Input() mattypeID: any;
  @Input() USERID: any;
  FlagBLOCKUSER = 0;
  TextRD: Array<textRD> = [];
  FlagREALITEM: number = 0;
  FlagCOPY: number = 0;
  FlagFILE: number = 0;
  type = 'NDDRealItemFee';
  //page = '';
  pageCount = 0; //จำนวนหน้า
  allPage = true;
  //disabled = true;
  
  Fee = '';
  FG: UntypedFormGroup;
  NDDFeeSubcription: Subscription = new Subscription();
  calPageCountAndFeeSubcription: Subscription = new Subscription();
  AddToBasketSubcription: Subscription = new Subscription();

  fc = new NDDFunction(this.homeService);
  constructor(
    private modalService: NgbModal,
    private memberService: MemberService,
    private homeService: HomeService,
    private fb: UntypedFormBuilder,  private route: Router,
  ) {
    this.FG = this.fb.group({
      formControlpage: new UntypedFormControl({ value: '', disabled: true }),
      copyCount: new UntypedFormControl(1),
    });
  }
  ngOnDestroy(): void {
    if (this.NDDFeeSubcription) {
      this.NDDFeeSubcription.unsubscribe();
    }
    if (this.calPageCountAndFeeSubcription) {
      this.calPageCountAndFeeSubcription.unsubscribe();
    }

    if (this.AddToBasketSubcription) {
      this.AddToBasketSubcription.unsubscribe();
    }
  }

  async ngOnInit(): Promise<void> {
 

    let objRD = new textRD();
    if (localStorage.getItem('lang') == 'th') {
      objRD.TitleModal = 'Resource Delivery';
      objRD.NoticeBlockUser = 'ไม่สามารถทำรายการได้: สถานะสมาชิกมีรายการบล็อก';
      objRD.NoticeRequesttoBasket = 'เพิ่มรายการลงตะกร้าเรียบร้อยแล้ว';
    } else {
      objRD.TitleModal = 'Resource Delivery';
      objRD.NoticeBlockUser = 'Unable to request delivery: Member is blocked.';
      objRD.NoticeRequesttoBasket = 'The item has been added to the basket.';
    }

    this.TextRD.push(objRD);

    // UserSoftBlock.forEach(Datail => {});
    // UserardBlock.forEach(Datail => {
    //   console.log(Datail.Blocktype)
    // });

    if (this.dataRD.PAGE != '') {
      this.pageCount = this.dataRD.PAGE;
      this.FG.controls['formControlpage'].setValue('1-' + this.dataRD.PAGE);
    }
    /**สำหรับปิด tab type Realit */
    await this.GetNDDTypeOpen(this.dataRD.ITEMID);
  }

  async changeType(type: string) {
    // this.allPage = true;
    this.FG.controls['copyCount'].setValue(1);
    this.type = type;

    if (type == 'NDDRealItemFee') {
      this.Fee='';
      this.Fee = await this.fc.getNDDFee(
        'NDDRealItemFee',
        0,
        this.FG.controls['copyCount'].value
      );
    } else {
      this.calPageCountAndFee();
    }
  }

  async onPageChange(value) {
    if (value == 1) {
      this.allPage = true;
      //  this.page = this.page = '1-' + this.dataRD.PAGE;
      this.FG.controls['formControlpage'].setValue('1-' + this.dataRD.PAGE);
      this.FG.controls['formControlpage'].disable();
    } else {
      this.allPage = false;
      this.FG.controls['formControlpage'].enable();
    }

    this.calPageCountAndFee();
    this.pageCount = 0;

    this.pageCount = await this.fc.calPageCount(
      this.FG.controls['formControlpage'].value.split(' ').join('')
    );
  }
  closeModal() {
    this.modalService.dismissAll(this.modalService);
  }

  async GetNDDTypeOpen(ITEMID) {
    this.homeService
      .getNDDRequestTypeOpen(ITEMID)
      .subscribe(async (requesttype: any) => {
        this.FlagREALITEM = requesttype[0].REALITEMFLAG;
        if ((this.FlagREALITEM = 1)) {
          this.Fee = await this.fc.getNDDFee(
            'NDDRealItemFee',
            0,
            this.FG.controls['copyCount'].value
          );
        } else {
          if (this.FlagCOPY == 1) {
            this.Fee = await this.fc.getNDDFee(
              'NDDCopyFee',
              this.pageCount,
              this.FG.controls['copyCount'].value
            );
          } else {
            if (this.FlagFILE == 1) {
              this.Fee = await this.fc.getNDDFee(
                'NDDFileFee',
                this.pageCount,
                this.FG.controls['copyCount'].value
              );
            }
          }
        }

        this.FlagCOPY = requesttype[0].COPYFLAG;
        this.FlagFILE = requesttype[0].FILEFLAG;
      });
  }

  async calPageCountAndFee() {
    this.pageCount = 0;
    // let fc = new NDDFunction(this.homeService)
    this.pageCount = await this.fc.calPageCount(
      this.FG.controls['formControlpage'].value.split(' ').join('')
    );
    if (this.pageCount != 0) {
      this.Fee = await this.fc.getNDDFee(
        this.type,
        this.pageCount,
        this.FG.controls['copyCount'].value
      );
    } else {
      this.Fee = '';
    }
  }


  async AddToBasket(ProcessType) {
    var type = 'Real Item';

    if (this.type == 'NDDRealItemFee') {
      this.Fee = await this.fc.getNDDFee(
        this.type,
        this.pageCount,
        this.FG.controls['copyCount'].value
      );
    } else {
      this.pageCount = 0;
      this.pageCount = await this.fc.calPageCount(
        this.FG.controls['formControlpage'].value.split(' ').join('')
      );
      if (this.pageCount != 0) {
        this.Fee = await this.fc.getNDDFee(
          this.type,
          this.pageCount,
          this.FG.controls['copyCount'].value
        );
      } else {
        this.Fee = '';
      }
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-secondary me-2',
      },
      buttonsStyling: false,
    });

    var pageAndCopy = '';
    if (this.type == 'NDDCopyFee' || this.type == 'NDDFileFee') {
      type = 'NDDFileFee';
      pageAndCopy = 'Page: ' + this.FG.controls['formControlpage'].value;
    }
    if (this.type == 'NDDCopyFee') {
      type = 'NDDCopyFee';
      pageAndCopy += ', Copy: ' + this.FG.controls['copyCount'].value;
    }
    // pageAndCopy += type + '<br>'+pageAndCopy+ '<br>';
    type = type
      .replace('NDDRealItemFee', 'Real Item')
      .replace('NDDCopyFee', 'Copy')
      .replace('NDDFileFee', 'File');
    type == 'Real Item'
      ? (pageAndCopy = '(' + type + ')' + '<br>')
      : (pageAndCopy = '(' + type + ')' + '<br>' + pageAndCopy + '<br>');

    var htmlText: string =
      "<div class='alert alert-primary' role='alert'>" +
      pageAndCopy +
      '<b>Fee: ' +
      this.Fee +
      ' ฿ </b></div>';

    swalWithBootstrapButtons
      .fire({
        title: ProcessType==1? 'Are you sure add to basket?':'Are you sure request?',
        html: htmlText,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          //  1 - realitem, 2 - copy, 3 - File
          var basketData: NDDBasketData = {
            DDINBASKETID: '',
            DDFORMAT: type
              .replace('Real Item', '1')
              .replace('Copy', '2')
              .replace('File', '3'),
            ITEMID: this.dataRD.ITEMID,
            JMARCID: '',
            PAGE: this.FG.controls['formControlpage'].value,
            COPYNUM: this.FG.controls['copyCount'].value,
            LOCATIONID: this.dataRD.LOCATIONID,
            FEE: this.Fee,
            MATTYPEID: this.mattypeID,
            USERID: this.USERID,
          };
          this.AddToBasketSubcription = this.memberService
            .NDDAddBasket(this.USERID, basketData)
            .pipe(
              switchMap(async (res: any) => {
                switch (res.status) {
                  case 200:
                    if (res.body[0].result == 'True') {
                      swalWithBootstrapButtons.fire(
                        ProcessType==1?'Add to basket success!':'Request success!',
                        'success'
                      );
                      this.closeModal();

                    if(ProcessType==2) {
                      this.route.navigateByUrl('/member/basket?NDDType='+this.type+'&ddinbasketid=' +res.body[0].ddinbasketid);
                    }
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: res.body[0].message,
                      });
                    }

                    break;
                  default:
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Unable to connect to API',
                    });
                    break;
                }
              }),
              catchError((e) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Unable to connect to API',
                });
                return of([]);
              })
            )
            .subscribe();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  }

  
}

export class textRD {
  TitleModal: string;
  NoticeBlockUser: string;
  CurrentPage: string;
  Custompage: string;
  NoticeRequesttoBasket: string;
  Price: string;
}
