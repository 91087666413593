import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';

import { HomeService } from './services/home.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BibitemComponent } from './bibitem/bibitem.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ResultsComponent } from './results/results.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TestComponent } from './test/test.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';

import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import {PageEvent, MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';

import { MattPicPipe } from './pipe/matt-pic.pipe';
import { MattNamePipe } from './pipe/matt-name.pipe';
import { ElectronicResourceComponent } from './component/electronic-resource/electronic-resource.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ItemsComponent } from './bibitem/items/items.component';



import { LocationNameComponent } from './component/location-name/location-name.component';
import { CollectionComponent } from './component/collection/collection.component';

import { MarcComponent } from './bibitem/marc/marc.component';

import { CommonModule } from '@angular/common';
import { LoginComponent } from './member/login/login.component';
import { SubcategoryComponent } from './category/subcategory/subcategory.component';
import { CategoryComponent } from './category/category/category.component';
import { MarcholdingComponent } from './bibitem/marcholding/marcholding.component';
import { VersionComponent } from './version/version.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { RegulationComponent } from './regulation/regulation.component';
import { ContactusComponent } from './contactus/contactus.component';


import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { RdDialogComponent } from './bibitem/rd-dialog/rd-dialog.component';
import { VirtualbookshelfComponent } from './virtualbookshelf/virtualbookshelf.component'

import { QRCodeModule } from 'angularx-qrcode';
import { NewListComponent } from './news/new-list/new-list.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { ReviewLibComponent } from './bibitem/review-lib/review-lib.component';
import { ReviewMemberComponent } from './bibitem/review-member/review-member.component'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { BookshelfdiscoveryComponent } from './bookshelfdiscovery/bookshelfdiscovery.component';
import { ExportComponent } from './bibitem/export/export.component';
import { BibitemCardComponent } from './bibitem-card/bibitem-card.component';
import { ExportSaveComponent } from './export-save/export-save.component';
import { ClipboardModule } from 'ngx-clipboard';
import { HoldingComponent } from './bibitem/holding/holding.component';
import { BtnRDComponent } from './bibitem/items/btn-rd/btn-rd.component';
import { ShortTitlePipe } from './pipe/short-title.pipe';

import { ShareModule } from './share/share.module';
import { Link856Pipe } from './pipe/link856.pipe';
import { BibTagCloudeComponent } from './bibitem/bib-tag-cloude/bib-tag-cloude.component';
import { TagCloudComponent } from './home/tag-cloud/tag-cloud.component';
import { BibSuggestFriendComponent } from './bibitem/bib-suggest-friend/bib-suggest-friend.component';
import { ShareFacebookComponent } from './bibitem/share-facebook/share-facebook.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {MatSliderModule} from '@angular/material/slider';
export function HttpLoaderFactory(http: HttpClient){
return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    BibitemComponent,
    ResultsComponent,
    MattPicPipe,
    MattNamePipe,
    ItemsComponent,
    LocationNameComponent,
    CollectionComponent,
    MarcComponent,
    ElectronicResourceComponent,
    HomeComponent,
    LoginComponent,

    FooterComponent,
    HeaderComponent,
    SubcategoryComponent,
    CategoryComponent,
    MarcholdingComponent,
    VersionComponent,
    AboutusComponent,
    RegulationComponent,
    ContactusComponent,
    RdDialogComponent,
    VirtualbookshelfComponent,
    NewListComponent,
    NewsDetailComponent,
    ReviewLibComponent,
    ReviewMemberComponent,
    BookshelfdiscoveryComponent,
    ExportComponent,
    BibitemCardComponent,
    ExportSaveComponent,
    HoldingComponent,
    BtnRDComponent,
    ShortTitlePipe,
    Link856Pipe,
    BibTagCloudeComponent,
    TagCloudComponent,
    BibSuggestFriendComponent,
    ShareFacebookComponent,
    // MenuFineComponent,
    // MenuHoldComponent,
    // MenuCheckedOutComponent,
    // MenuCheckedOutHistorysComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule, 
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,MatSliderModule,
    AutocompleteLibModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    NgbModule,
    MatTreeModule,MatPaginatorModule,
    MatIconModule,ClipboardModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps : [HttpClient]
        },
      }
    ),
   
    QRCodeModule,
    CKEditorModule,
    ShareModule,
    NgxGoogleAnalyticsModule.forRoot(environment.MeasurementID),
    // NgxGoogleAnalyticsRouterModule.forRoot({ include: [ '*/public/*' ] }),
    MatDialogModule,
   // AngularGtagModule.forRoot({ trackingId: 'UA-XXXXXXXXX-X' }),
   
 
  //   TranslateModule.forChild({
  //     loader: {provide: TranslateLoader, useClass: CustomLoader},
  //     compiler: {provide: TranslateCompiler, useClass: CustomCompiler},
  //     parser: {provide: TranslateParser, useClass: CustomParser},
  //     missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomHandler},
  //     isolate: true
  // })
    // TranslateModule.forRoot(
    //   {
    //     loader: {
    //       provide: TranslateLoader,
    //       useFactory: HttpLoaderFactory,
    //       deps : [HttpClient]
    //     }
    //   }
    // )
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    TranslateModule,
    AppComponent,
    CKEditorModule,
    

  ],
  providers: [HomeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
