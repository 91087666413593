import { Component, OnInit, Input } from '@angular/core';
//import { stringify } from 'querystring';
import { exportRootObject } from 'src/app/models/walai';
import { HomeService } from 'src/app/services/home.service';
@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {

  @Input() bibid: string;
  type:string = 'MARC'
  data :exportRootObject[];
  marc : any[];
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
 
    this.export('MARC');
    
  }

  export(type:string){
    this.type = type;
    this.data=[];
  this.homeService.bibExport(this.bibid,type).subscribe((data: any) => {
        this.data = data;
   });

if( this.type=='MARC'){
  this.homeService.getMARC(this.bibid).subscribe((items: any) => {
    //ตัวแปรเก็บ Item ทั้งหมด
   this.marc = items;

 });
}

  }


  dataTofile():string {
  let  str:string='';
  this.data.forEach(element => {
    str += element.data +'\n';
});
return  str
  }

  saveAsTextFile() {
    let dataForExport: string =  this.dataTofile()
      .replace(/<[^>]*>/g, '')
      .replace(/&amp;/g, '&');
    if (this.type == "RISForZotero" || this.type == "RISForMendeley") {
      dataForExport = dataForExport.replace(/&amp;/g, '&');
    }
    var data = new Blob([dataForExport], { type: 'text/plain' });
    let url = window.URL.createObjectURL(data);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    if (this.type == "RISForZotero" || this.type == "RISForMendeley") {
      a.download = 'export.ris';
   
    } else {
      a.download = 'export.text';
    }
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
