


<div class="container mt-5">
    <h4><ul class="nav ">
        <li class="nav-item">
          <a class="nav-link "  routerLink="/news">News</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled">></a>
          </li>
        <li class="nav-item active">
          <a class="nav-link disabled" aria-current="page" href="#">{{result[0].NEWSTITLE}}</a>
        </li>
       
      </ul></h4>
    <div class="container mt-5 container-walai">
     
        <div class=" mb-3">
            <div class="text-center">
                <img src="{{result[0].NEWSIMAGES}}"   onError="this.src='assets/images/noImageNew.jpeg'"
                class="" style="max-width: 600px;" alt="...">
              </div>

           
            <div class="card-body">
            
              <p class="card-text" innerHTML=" {{result[0].NEWSDETAIL}}">
               
              </p>
              <p class="card-text"><small class="text-muted">Last updated  {{result[0].NEWSDATE}}</small></p>
            </div>
          </div>

    
            <!-- <div class="card mb-3"  >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src="{{result.NEWSIMAGES}}" class="img-fluid rounded-start" alt="{{result.NEWSTITLE}}"
                    onError="this.src='assets/images/noImageNew.jpeg'">   
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title"><a >{{result.NEWSTITLE}}</a></h5>
                      <p class="card-text" innerHTML="{{result.NEWSLEAD}}"></p>
                      <p class="card-text"><small class="text-muted">Last updated {{result.NEWSDATE}} </small></p>
                    </div>
                  </div>
                </div>
              </div> -->

      <!-- sinner -->
      <div class="row pb-5">
        <div class="col-md-12 p-5">
          <ngx-spinner bdOpacity="0.9" bdColor="" size="default" color="#581f87" type="ball-pulse-sync" [fullScreen]="false"></ngx-spinner>
        </div>
      </div>
   
    </div>
    
  
      
    </div>