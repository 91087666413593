<div class="row mt-5">
  <div class="col-12">
    <div class="border-bottom mb-3">
      <h5 class="text-walai-default-color">Librarian Review</h5>
    </div>

    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="default"
      color="#581f87"
      type="ball-pulse-sync"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <div class="card p-2 mb-3" *ngFor="let item of reviewData">
      <div
        class="row row-cols-auto justify-content-between p-2"
        *ngFor="let item of reviewData"
      >
        <p class="card-text mb-0" [innerHTML]="item.REVIEWDETAIL"></p>

        <p class="text-muted mb-0">
          <small> {{ item.REVIEWBy }} : {{ item.REVIEWDATE }}</small>
        </p>
      </div>

      <div class="row row-cols-auto justify-content-end gap-2 pe-3">
        <a
          routerLink="../admin/editreview/{{ item.REVIEWID }}"
          class="btn btn-outline-primary btn-sm py-1 pointer text-decoration-none"
          *ngIf="editStatus"
          ><i class="bi bi-pencil-square"></i> Edit review</a
        >
        <button type="button"
          (click)="deleteReview(item.REVIEWID)"
          class="btn btn-sm btn-outline-danger py-1"
          *ngIf="editStatus"
          ><i class="bi bi-trash"></i> Delete review</button
        >

     
      </div>
    </div>

    <a
      routerLink="../admin/addreview/{{ bibid }}"
      class="btn btn-primary btn-sm py-1 fw-light shadow mt-2 ms-auto pointer text-decoration-none"
      *ngIf="addStatus"
      ><i class="bi bi-plus-square me-1"></i> Add review</a
    >

   
  </div>
</div>
