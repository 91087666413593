import { Component, OnInit, Input } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-marc',
  templateUrl: './marc.component.html',
  styleUrls: ['./marc.component.css']
})
export class MarcComponent implements OnInit {
  @Input() bibid: string;
  marc : any[];
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    this.homeService.getMARC(this.bibid).subscribe((items: any) => {
      //ตัวแปรเก็บ Item ทั้งหมด
     this.marc = items;
    

   });

  }

}
