import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  value :string= '';
  constructor(
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.value = '';
    this.adminService
      .getAboutUSContactRule('about')
      .subscribe((val:  string) => {
        this.value = val;
        console.log(val)
      });
  }

}
