import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlphapetResult, Count, newsList, newsParam } from 'src/app/models/walai';
import { UtilityService } from 'src/app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-list',
  templateUrl: './new-list.component.html',
  styleUrls: ['./new-list.component.css']
})
export class NewListComponent implements OnInit {
  result: any;
  countResult: Count;
  displayResult: newsList[];
  
  notEmptyPost = true;
  notscrolly = true;
  page = 0;
  newsParam: newsParam;
  constructor(
    private util: UtilityService,
    private router: Router,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit(): void {
    this.displayResult = [];
    this.search();
  }
  
  search(): void {
    this.page = 1;
    this.spinner.show();
    this.notEmptyPost = false;
   
    this.newsParam = {
      currentPage: this.page,
      perPage: 10,
      filter: ''
    };
    this.util.getNewsList(this.newsParam).subscribe(result => {
      this.result = result;
      this.countResult = result.count[0].count;
      this.displayResult = result.rows;
      if (this.displayResult.length === 0) {
        this.notEmptyPost = false;
        this.spinner.hide();
      } else {
        this.notEmptyPost = true;
        this.spinner.hide();
      }
      this.notscrolly = true;
    });
  }

  loadMore(): void {
    this.page += 1;
    this.newsParam.currentPage = this.page;
    this.spinner.show();
    this.util.getNewsList(this.newsParam).subscribe(result => {
      this.result = result;
      this.countResult = result.count[0].count;
      this.displayResult = this.displayResult.concat(result.rows);
      if (this.displayResult.length === 0) {
        this.notEmptyPost = false;
      } else {
        this.notEmptyPost = true;
      }
      this.spinner.hide();
      this.notscrolly = true;
    });
  }
  
  onScroll(): void {
   
      if (this.notscrolly && this.notEmptyPost) {
        this.spinner.show();
        this.notscrolly = false;
        this.loadMore();
      }
    
  }


}
