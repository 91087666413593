<div class="jumbotron"  >
  <div class="container text-center" style="max-width: 1000px;">
    <h1 class="text-walai-default-color  mb-4">Bookshelf Discovery</h1>
    <form class="input-group Bg-Search" [formGroup]="qsearchForm">
      <input type="text" class="form-control form-control-md" placeholder="{{'Search'| translate}}..."
        formControlName="textSearch" id="TextSearch" (keydown.enter)="search()" (input)="auto($event.target.value)"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
      <div class="input-group-append">
        <a class="a-walai color-orange" (click)="search()">
          <i class="bi bi-search" width="24" height="24" viewBox="0 0 24 24"></i>
        </a>
      </div>
      <div class="dropdown-menu mt-2" aria-labelledby="TextSearch">
        <button class="dropdown-item" type="button" *ngFor="let item of listAuto"
          (click)="EnterSearch(item.word)">{{item.word}}</button>
      </div>

    </form> 
  </div>

  
</div>

<div class=" container-fluid">
  <div class="row justify-content-between">
  <div class="col-md-3">

    <div class="border-right" id="sidebar-wrapper">
      <div class="sidebar-heading">
        <h5>{{ "Filter" | translate }}</h5>
      </div>
  
      <div *ngIf="CurrentFilter.length > 0" class="container selected-criteria-panel">
        <h6 class="text-walai-default-color">Current Filter</h6>
        <div class="alert alert-primary alert-dismissible fade show" role="alert" *ngFor="let filter of CurrentFilter">
          <small>{{ filter.Ntk }}</small> {{ filter.Ntt }}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close"
            (click)="RemoveKeyword(filter.Ntk, filter.id)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
  
      <div class="list-group list-group-flush">
        <!-- locationFacet -->
        <ng-container *ngIf="locationFacet">
          <h5 class="
              list-group-item list-group-item-action
              mb-3
              mt-3
              text-walai-default-color
            ">
            {{ "Location" | translate }}
          </h5>
          <ul class="list-group" *ngIf="locationFacetLessShow">
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
                text-walai-default-color
                a-walai
              " *ngFor="let s of locationFacetLess" (click)="addFilter('LOCATION', s.name)">
              <!-- {{s.name}} -->
  
              <app-location-name [LOCATIONID]="s.name"></app-location-name>
              <span class="badge bg-secondary text-light">{{ s.count }}</span>
            </li>
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
              ">
              <a class="a-walai text-walai-default-color" *ngIf="locationFacetLess.length >= facetLess"
                (click)="MoreLocation(false)">more...</a>
            </li>
          </ul>
  
          <!-- When Show More -->
          <ul class="list-group" *ngIf="locationFacetLessShow == false">
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
                text-walai-default-color
                a-walai
              " *ngFor="let s of locationFacet" (click)="addFilter('LOCATION', s.name)">
              <!-- {{s.name}} -->
  
              <app-location-name [LOCATIONID]="s.name"></app-location-name>
              <span class="badge bg-secondary text-light">{{ s.count }}</span>
            </li>
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
              ">
              <a class="a-walai text-walai-default-color" (click)="MoreLocation(true)">...less</a>
            </li>
          </ul>
        </ng-container>
  
        <ng-container *ngIf="subjectFacet">
          <h5 class="
              list-group-item list-group-item-action
              mb-3
              mt-3
              text-walai-default-color
            ">
            {{ "Subject" | translate }}
          </h5>
          <ul class="list-group" *ngIf="subjectFacetLessShow">
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
                text-walai-default-color
                a-walai
              " *ngFor="let s of subjectFacetLess" (click)="addFilter('SUBJECTFACET', s.name)">
              {{ s.name }}
              <span class="badge bg-secondary text-light">{{ s.count }}</span>
            </li>
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
              ">
              <a class="a-walai text-walai-default-color" *ngIf="subjectFacetLess.length >= facetLess"
                (click)="MoreSubject(false)">more...</a>
            </li>
          </ul>
  
          <!-- When Show More -->
          <ul class="list-group" *ngIf="subjectFacetLessShow == false">
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
                text-walai-default-color
                a-walai
              " *ngFor="let s of subjectFacet" (click)="addFilter('SUBJECTFACET', s.name)">
              {{ s.name }}
              <span class="badge bg-secondary text-light">{{ s.count }}</span>
            </li>
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
              ">
              <a class="a-walai text-walai-default-color" (click)="MoreSubject(true)">...less</a>
            </li>
          </ul>
        </ng-container>
  
        <!-- authorFacet -->
        <ng-container *ngIf="authorFacet">
          <h5 class="
              list-group-item list-group-item-action
              mb-3
              mt-3
              text-walai-default-color
            ">
            {{ "Author" | translate }}
          </h5>
          <ul class="list-group" *ngIf="authorFacetLessShow">
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
                text-walai-default-color
                a-walai
              " *ngFor="let s of authorFacetLess" (click)="addFilter('AUTHORFACET', s.name)">
              {{ s.name }}
              <span class="badge bg-secondary text-light">{{ s.count }}</span>
            </li>
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
                a-walai
              ">
              <a class="a-walai text-walai-default-color" *ngIf="authorFacetLess.length >= facetLess"
                (click)="MoreAuthor(false)">more...</a>
            </li>
          </ul>
  
          <!-- When Show More -->
          <ul class="list-group" *ngIf="authorFacetLessShow == false">
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
                text-walai-default-color
                a-walai
              " *ngFor="let s of authorFacet" (click)="addFilter('AUTHORFACET', s.name)">
              {{ s.name }}
              <span class="badge bg-secondary text-light">{{ s.count }}</span>
            </li>
            <li class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                list-group-item-walai
                a-walai
              ">
              <a class="a-walai text-walai-default-color" (click)="MoreAuthor(true)">...less</a>
            </li>
          </ul>
        </ng-container>
  
        <br />
        <!-- <ng-container *ngIf="bookcount">
          <h5 class="
              list-group-item list-group-item-action
              mb-3
              text-walai-default-color
            ">
            {{ "PublishYear" | translate }}
          </h5>
          <div class="px-2">
            <ngx-slider [(value)]="minYearValue" [(highValue)]="maxYearValue" [options]="optionsYear"
              (userChangeEnd)="addFilterYear($event)"></ngx-slider>
          </div>
        </ng-container>
  
        <br> -->
        <!-- sourceTypeFacet -->
        <ng-container *ngIf="sourceTypeFacet">
          <h5 class="
            list-group-item list-group-item-action
            mb-3
            mt-3
            text-walai-default-color
          ">
            {{'SourceType'|translate}}
          </h5>
          <ul class="list-group" *ngIf="sourceTypeFacet">
            <li class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              list-group-item-walai
              text-walai-default-color
              a-walai
            " *ngFor="let s of sourceTypeFacet" (click)="addFilter('MEDIATYPE', s.name)">
              {{  getMEDIATYPENameByID(s.name)}}
              <!-- <app-location-name [LOCATIONID]="s.name"></app-location-name> -->
              <span class="badge bg-secondary text-light">{{ s.count }}</span>
            </li>
            <li class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              list-group-item-walai
            ">
  
            </li>
          </ul>
  
  
        </ng-container>
  
  
      </div>
    </div>
 
  </div>
  <div class="col-md-9">
    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50">

    </div>
  </div>
</div>
</div>


