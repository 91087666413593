export class Keywords {
  public Ntk: string;
  public Ntt: string;
  public Nto: string;
  public CurrentPage: number;
  public RowPerPage: number;
  public Orderby: string;
}

export class AutoComplete {
  public Keyword: string;
  public Location: string;
}

export class AutoCompleteWord {
  constructor(public word: string) {}
}

export interface AutoCompleteResponse {
  total: number;
  results: AutoCompleteWord[];
}

export class Facet {
  public Type: string;
  public count: string;
  public name: string;
}

export class CurrentFilter {
  public Ntk: string;
  public Ntt: string;
  public Nto: string;
  public id: string;
}

export class Mattype {
  public Name = new Array(
    'Unknow',
    'Book',
    'Serial',
    'Music',
    'Map',
    'Visual',
    'ComputerFile',
    'Mixed',
    'Unknow',
    'Article',
    'Online',
    'Thesis'
  );

  GetMattypeName(id) {
    return this.Name[id];
  }
}

export class Collection {
  // ไว้สำหรับเพิ่ม bib และ ลบ bib ใน Collection
  public MLID: string;
  public BIBID: string;
  public Note: string;

  // ไว้สำหรับเพิ่มข้อมูล Collectionใหม่
  public NAME: string;
  public LISTTYPE: string;
  public UserID: string;
}

export interface callNoResponse {
  type: string;
  callNoLC: CallNo[];
  callNoDC: CallNo[];
  callNoLocal: CallNo[];
}

export class CallNo {
  public type: string;
  public value: string;
  public display: string;
}

export interface locationCollectionResponse {
  locationid: string;
  LOCATIONNAMETHAI: string;
  collections: collection[];
}

export class collection {
  public Value: string;
  public DISPLAY: string;
}

export class category {
  public CATEID: string;
  public CATNAMETHAI: string;
  public CATNAMEENG: string;
  public DESCRIPTION: string;
  public CATIMG: string;
  public COUNT: string;
}

export class subCategory {
  public CATEID: string;
  public CATEGRPID: string;
  public CATEGRPNAMETHAI: string;
  public CATEGRPNAMEENG: string;
  public CATEGRPDESC: string;
  public COUNT: string;
  public CATIMG: string;
}

export class returnCategorySubCategory {
  public CATEID: string;
  public CATNAMETHAI: string;
  public CATNAMEENG: string;
  public DESCRIPTION: string;
  public CATIMG: string;
  public subCategory: subCategory[];
}

export class subject {
  public AUTHID: string;
  public AUTHHEADING: string;
}

export class returnSubCategory {
  public subCategory: subCategory;
  public subjects: subject[];
}
export interface SearchParameter {
  Ntk: string;
  Ntt: string;
  Nto: string;
  CurrentPage: number;
  RowPerPage: number;
  Orderby: string;
  isDel: boolean;
}

export interface marcHolding {
  holdingid: string;
  locationnamethai: string;
  marcs: marc[];
}

export interface marc {
  TagID: string;
  Indicator1: string;
  Indicator2: string;
  Subfield: string;
}

export interface availableIssue {
  year: string;
  unit: string[];
}

export interface HoldingAvailableIssues {
  locationName: string;
  locationID: string;
  availableIssues: availableIssue[];
}
export interface AlphapetResult {
  Count: Count[];
  Display: Display[];
}

export interface Display {
  INDEXID: string;
  PHRASEDISPLAY: string;
  HIT: number;
  TITLE: string;
  LINK: string;
}

export interface Count {
  Count: string;
}

export interface Count {
  num: string;
}

export interface blockLost {
  Blocktype: string;
  itemid: string;
  barcode: string;
  title: string;
  itemclassname: string;
  checkoutdate: string;
  duedate: string;
  bibid: string;
  url: string;
}

export interface ResponseBlock {
  Counts: Count[];
  dtSoftBlock: blockLost[];
  dtHardBlock: blockLost[];
}

export interface MissingResult {
  NO: number;
  INFORMDATE: string;
  INFORMTIME: string;
  BARCODE: string;
  ITEMCLASSNAME: string;
  TITLE: string;
  BIBID: string;
  CALLNO: string;
  ITEMFINE: number;
  PRICE: number;
  URL: string;
}



export interface ResponseMissing {
  Counts: Count[];
  Results: MissingResult[];
}

export interface LostItemHistory {
  CIRID: number;
  CHECKOUTTYPE: number;
  CHECKOUTDATE: string;
  CHECKOUTTIME: string;
  DUEDATE: string;
  RETURNTYPE: number;
  RETURNDATE: string;
  RETURNTIME: string;
  RENEW: number;
  CIRFLAG: number;
  CIRNOTE?: any;
  BORROFFICER: number;
  RECOFFICER: number;
  ITEMID: string;
  USERID: number;
  ITEMFINE: number;
  PERIODTYPE: number;
  DUETIME?: any;
  VTCIDCHECKOUT?: any;
  VTCIDRETURN?: any;
  NOTESTR?: any;
  INFORMOFFICER: number;
  INFORMDATE: string;
  INFORMTIME: string;
  BARCODE: string;
  BIBID: string;
  CALLNO: string;
  ITEMCLASSID: number;
  PRICE: number;
  FEE: number;
  EDITION: string;
  PUBLISHER: string;
  PUBBEYEAR: string;
  AUTHOR: string;
  ITEMFINE1: number;
  ITEMCLASSNAME: string;
  TITLE: string;
  URL: string;
}

export interface ResponseLostItemHistory {
  Counts: Count[];
  Table1: LostItemHistory[];
}




export interface aquisition {
  title: string;
  author: string;
  isn: string;
  prices: string;
  amount: string;
  reason: string;
}

export interface memberData {
  USERID: number;
  MEMBERID: number;
  FNAMETHAI: string;
  LNAMETHAI: string;
  LOCKED: number;
  ADMINFLAG: number;
  LIBRARIANFLAG: number;
  DASHBOARDFLAG: number;
  APPROVEFLAG: number;
  STATUS: string;
  LOCATIONID: number;
  PTTYPEID: number;
  EXPIREDATE: string;
  EMAIL: string;
  PASSWORD: string;
  PICPATH: string;
  PICPATHURL: string;
  PRIVACYFLAG: number;
  MOBILE: string;
  BARCODE?: string;
}


export interface memberDataEdit {
  UserID: string;
  FieldName: string;
  FieldValues: string;
}

export interface Count {
  num: string;
}

export interface Suggestion {
  SGID: number;
  TITLE: string;
  AUTHOR: string;
  ISBN: string;
  PUBLISHER: string;
  USER_SUGGEST: string;
  STATUS: string;
  SGREASON: string;
  SGPRICE: number;
  SGTIME: string;
  SGDATE: string;
  AMOUNT: string;
}

export interface ResponseSuggestList {
  Counts: Count[];
  Suggestion: Suggestion[];
}

// export interface Count {
//   num: string;
// }

export interface Hold {
  HOLDID: number;
  HOLDTYPE: string;
  BARCODE: string;
  BIBID: string;
  TITLE: string;
  CALLNO: string;
  ITEMCLASSNAME: string;
  COLLECTIONNAME: string;
  HOLDSTATUSNAME: string;
  HOLDDATE: string;
  HOLDTIME: string;
  INFORMDATE: string;
  EXPIREHOLDDATE: string;
  QUEUENO: number;
  URL: string;
  checked: boolean;
}

export interface ResponseHolds {
  Counts: Count[];
  hold: Hold[];
}

export interface ResponseHoldList {
  HOLDID: string;
  TITLE: string;
  STATUS: string;
  FLAG: string;
}

export interface HoldList {
  HOLDID: string;
  TITLE: string;
}


export interface adminSetting {
  PARAMETERNAME: string;
  VALUE: string;
  PARAMETERKEY: string;
  PAGE: string;
}


export interface adminValue {
  value: string;
}


export interface libSuggestion {
  ID: string;
  TITLE: string;
  AUTHOR: string;
  PUBLISHER: string;
  PUBYEAR: string;
  LANG: string;
  CALLNUMBER: string;
  BIBSTATUSID?: number;
  CALL_NUMBER_SORT: string;
  CATDATE: string;
  MATTYPEID: number;
  MATTYPENAME: string;
  BOOKCOVER: string;
  checked: boolean;
}

export interface libSuggestionStatus {
  message: string;
  status: boolean;
}

export interface book856 {
  label: string;
  name: string;
  URL: string;
}

export interface book773t {
  label: string;
  data: string;
}


//news input parameter
export interface newsParam {
  currentPage: number;
  perPage: number;
  filter: string;
}


export interface newsList {
  NEWSID: number;
  NEWSTITLE: string;
  NEWSLEAD: string;
  NEWSDETAIL: string;
  NEWSDATE: string;
  NEWSIMAGES: string;
  NEWSSTATUS: string;
  MEMBERCREATE: string;
  MEMBERUPDATE: string;
  HITS: string;
}

export interface newsAdd {
  NEWSID: number;
  NewsTitle: string;
  NewsLead: string;
  NewsDetail: string;
  NewsStatus: string;
  MemberID: string;
  NewsImages: string;
}


export interface reviewData {
  BIBID: string;
  REVIEWDETAIL: string;
  REVIEWDATE: string;
  REVIEWBy: string;
  USERID:string;
  REVIEWID: string;
}

export interface reviewDataAdd {
  REVIEWID: string;
  USERID: string;
  BIBID: string;
  Review: string;
  FLAG: string;
  REVIEWTYPE: number;
}


export interface reviewDisplay {
  BIBID: string;
  TITLE: string;
  AUTHOR: string;
  REVIEWDETAIL: string;
  REVIEWDATE: string;
  BookCover: string;
  MATTYPENAME: string;
  MATTYPEIMG: string;
  Link: string;
  REVIEWID: string;
}

export interface reviewCount {
  BIBCount: string;
}

export interface responseReviewList {
  Display: reviewDisplay[];
  Count: reviewCount[];
}


export interface AuthSeeAlso {
  Type: string;
  keyword: string;
  authid: string;
  authviewopac: string;
  REF: string;
  Count: string;
}

export interface AuthSymbol {
  REFID: string;
  AUTHVIEWOPAC: string;
}


export interface exportRootObject {
  data: string;
}

export interface exportList {
  databib: exportRootObject[];
}



export interface config {
  PARAMETERNAME: string;
  PARAMETERKEY: string;
  VALUE: string;
  PAGE: string;
}

export interface listcheckbib {
  bib: string
  selected: boolean
}


export interface EducationalResources {
  IMG_ID: number
  IMG_FILENAME: string
  IMG_DESC: string
  IMG_LINKTO: string
  IMG_TYPE: string
  LOCATIONID: number
  IMG_URL: string
}


export interface SearchTool {
  IMG_ID: number
  IMG_FILENAME: string
  IMG_DESC: string
  IMG_LINKTO: string
  IMG_TYPE: string
  LOCATIONID: number
  IMG_URL: string
}


export interface bookStatic {
  LASTUSE: string;
  CHECKOUTCOUNT: number;
  OPACVIEW: number;
  MYLIST: number;
  DOWNLOAD: number;
}

export interface memberAddress {
  USERID: string;
  ADDRESS: string;
  PROVINCEID: string;
  PROVINCENAME: string;
  DISTRICTID: string;
  DISTRICTNAME: string;
  POSTCODE: string;
  TEL: string;
}

export interface DownloadHistory {
  BIBID: string
  URL: string
  USERIPADDRESS: string
  USERAGENT: string
}


export interface UserClient {
  ipaddress: string
  agent: string
}


export interface UserPassword {
  CurrentPassword: string
  NewPassword: string
}

export interface TagCloudModel {
  CSS: string;
  Value: string;
}

export interface TagCloudDataAdd {
  USERID: string;
  BIBID: string;
  Tag: string;
  LibFLAG: string;
}


export interface TagCloudNameList {
  TAGID: string;
  COUNTBIB: number;
  TAGNAME: string;
  TAGSWORD:string;
  checked: boolean;
}


export interface responseTagsCloudBookList {
  Display: TagCloudBookList[];
  Count: String[];
}


export interface TagCloudBookList {
  MYNUM: number;
  ID: string;
  TITLE: string;
  AUTHOR: string;
  PUBLISHER: string;
  PUBYEAR: string;
  LANG: string;
  CALLNUMBER: string;
  BIBSTATUSID: number;
  EDITION: string;
  CALL_NUMBER_SORT: string;
  CATDATE: string;
  MATTYPEID: number;
  MATTYPENAME: string;
  NUMBERSORT1: string;
  PHRASESORT1: string;
  PUBBEYEAR: string;
  TAGSWORD: string;
  URLJPG:string;
}

export interface AdminTagsApproveParam {
  adminID: string;
  tagWord: string;
  ApproveStatus: string;
}


export interface DeleteTagsParam {
  UserID: string;
  BibID: string;
  tagWord: string;
}

export interface FriendModel {
  EMAIL: string;
  MEMBERID: number;
  USERID: number;
  NAME: string;
  IMAGES: string;
  ADMINFLAG: number;
  LIBRARIANFLAG: number;
  DASHBOARDFLAG: number;
}

export interface SuggestFriendParam {
  SUGGESTBY: string;
  FriendID: string;
  BIBID: string;
  Comment: string;
}

export interface SuggestFriendModel {
  ID: string;
  TITLE: string;
  SUGGESTDATETIME: string;
  BookCover: string;
  SUGGESTBY: string;
  COMMENTS: string;
  BookURL: string;
  SFID: string;
}

export interface keywordModel {
  keyword: string;
}


