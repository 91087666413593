import { Component, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import Swal from 'sweetalert2';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { exportList, exportRootObject, listcheckbib } from '../models/walai';
import { HomeService } from '../services/home.service';
import { NgForOf } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'app-export-save',
  templateUrl: './export-save.component.html',
  styleUrls: ['./export-save.component.css'],
})
export class ExportSaveComponent implements OnInit {
  bibs: any = [];
  bibsSelected: string[] = [];
  countselected: number = 0;
  exportType: string = '';
  exportStr: string = '';
  loading = true;
  type: string = 'MARC';
  datalist: exportList[];
  dataMARClist: any[];
  listckeckedselect = [];
  // checkall= true;
  FGcheckall = new UntypedFormGroup({
    checkall: new UntypedFormControl(false),
  });
  constructor(private data: DataService, private homeService: HomeService) {}

  ngOnInit(): void {
    if (localStorage.getItem('listBib') != null) {
      let bibStr: any = localStorage.getItem('listBib');
      var splitted = bibStr.split(',');
      splitted.forEach((element: any) => {
        if (element != '') {
          this.bibs.push(element);
          this.bibsSelected.push(element);
          let listChecked: listcheckbib = {
            bib: element,
            selected: true,
          };

          this.listckeckedselect.push(listChecked);
        }
      });

      this.countselected = this.bibsSelected.length;
      if (this.countselected > 0) {
        this.FGcheckall.patchValue({ checkall: true });
      }
    }
  }

  AddOrRemoveSeleted(bibid: string, i: number) {
    const index = this.bibsSelected.indexOf(bibid, 0);
    if (index > -1) {
      this.bibsSelected.splice(index, 1);
    } else {
      this.bibsSelected.push(bibid);
    }

    if (this.listckeckedselect[i].bib == bibid) {
      if (this.listckeckedselect[i].selected == true) {
        this.listckeckedselect[i].selected = false;

        //this.bibsSelected.splice(i, 1);
      } else {
        this.listckeckedselect[i].selected = true;

        //this.bibsSelected.push(bibid);
      }
    }

    const checallBoolean = this.listckeckedselect.every(
      (currentValue) => currentValue.selected == true
    );
    if (checallBoolean == true) {
      this.FGcheckall.patchValue({ checkall: true });
    } else {
      this.FGcheckall.patchValue({ checkall: false });
    }

    this.countselected = this.bibsSelected.length;
  }

  deleteBib(bibid: string, i: number) {
    const index1 = this.bibs.indexOf(bibid, 0);
    if (index1 > -1) {
      this.bibs.splice(index1, 1);
    }

    //  const index = this.bibsSelected.indexOf(bibid, 0);
    //  if (index > -1) {
    //   this.bibsSelected.splice(index, 1);
    //  }

    this.bibsSelected.splice(i, 1);
    this.listckeckedselect.splice(i, 1);
    this.data.updateUserData(this.bibs);
    this.data.currentBibList.subscribe((countHold) => (this.bibs = countHold));
    localStorage.setItem('listBib', this.bibs);
  }

  // export(type: string) {
  //   this.type = type;
  //   this.datalist = [];
  //   this.exportType = type;

  //   this.dataMARClist = [];
  //   this.listckeckedselect.forEach(async (element) => {
  //     if (element.selected == true) {
  //       this.loading = true;
       
  //       (await this.homeService.bibExport1(element.bib, type)).subscribe((data: any) => {
  //         let datalistAdd: exportList = {
  //           databib: [],
  //         };
  //         datalistAdd.databib = data;
  //         this.datalist.push(datalistAdd);
  //         this.loading = false;
     
  //       });

  //       if (this.type == 'MARC') {
  //         this.homeService.getMARC(element.bib).subscribe((data: any) => {
  //           this.dataMARClist.push(data);
  //           this.loading = false;
          
  //         });
  //       }
  //     }
  //   });

    
  // }
  async export(type: string) {
    this.type = type;
    this.datalist = [];
    this.exportType = type;
    if(type=='MARCISO2709'){ this.exportType ='ISO 2709'}
   

    this.dataMARClist = [];
    var biblist :string = '';
    this.listckeckedselect.forEach( (element) => {
      if (element.selected == true) {
        this.loading = true;
       
        biblist+= element.bib + ',';

        if (this.type == 'MARC') {
          this.homeService.getMARC(element.bib).subscribe((data: any) => {
            this.dataMARClist.push(data);
            this.loading = false;
          
          });
        }
      }
    });

if(biblist!=''){
  biblist=biblist.substring(0,biblist.length-1);
 
    (await this.homeService.bibExportList(biblist, type)).subscribe((data: any) => {
      let datalistAdd: exportList = {
        databib: [],
      };
  
      datalistAdd.databib = data;
      this.datalist.push(datalistAdd);
      this.loading = false;
 
    });
}


    
  }


  dataTofile(): string {
    let str: string = '';

    // this.datalist.forEach((exp) => {
    //   exp.forEach((element) => {
    //     str += element.data + '\n';
    //   });
    // });

    //     <ng-container *ngFor="let exp of datalist">
    //     <p  *ngFor="let item of exp">
    //         {{item.data}}
    //     </p>
    //  </ng-container>

    this.datalist.forEach((itm) => {
      itm.databib.forEach((element) => {
        str += element.data + '\n';
      });
    });

    return str;
  }

  saveAsTextFile() {
    let dataForExport: string = this.dataTofile()
      .replace(/<[^>]*>/g, '')
      .replace(/&amp;/g, '&');
    if (this.type == 'RISForZotero' || this.type == 'RISForMendeley') {
      dataForExport = dataForExport.replace(/&amp;/g, '&');
    }
    var data = new Blob([dataForExport], { type: 'text/plain' });
    let url = window.URL.createObjectURL(data);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    if (this.type == 'RISForZotero' || this.type == 'RISForMendeley') {
      a.download = 'export.ris';
    } else {
      a.download = 'export.text';
    }
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  selectAll(elem: any) {
    this.countselected = 0;
    if (elem.target.checked == true) {
      this.listckeckedselect.forEach((item, i) => {
        item.selected = true;
        this.countselected = i + 1;
      });
    } else {
      this.listckeckedselect.forEach((item) => (item.selected = false));
    }

    console.log(this.countselected);
  }
}
