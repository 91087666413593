<div
  class="container-fluid jumbotron text-center position-relative"
  [ngStyle]="banner"
  onError="this.src='assets/images/Banner4.jpg'"
>
 <div class="blur position-absolute"> </div>
 

 <div class="d-grid w-75 position-absolute boxsearchcenter mt-4">
      <h2 class="text-white">
        {{ "BookSearch" | translate }}...
      </h2>
    <div class="col-9 mt-2 mx-auto">

      <form class="input-group Bg-Search" [formGroup]="qsearchForm">

        <div>
          <button id="inputState" class="btn rounded-0 border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-filter-circle me-1 color-orange"></i><span class="mx-1">{{inputState}}</span><i class="bi bi-chevron-down ms-1"></i>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" role="button" (click)="SearchOption('KEYWORD', 'Keyword')">Keyword</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('TITLE', 'Title')">Title</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('AUTHOR', 'Author')">Author</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('SUBJECT', 'Subject')">Subject</a></li>
            <!-- <li><a class="dropdown-item" role="button" (click)="SearchOption('TAGS', 'Tags')">Tags</a></li> -->
            <li><a class="dropdown-item" role="button" (click)="SearchOption('PUBLISHER', 'Publisher')">Publisher</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('JOURNALTITLE', 'Journal')">Journal</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('CALLNUMBER', 'Call number')">Call number</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('ISBNISSN', 'ISBN/ISSN')">ISBN/ISSN</a></li>
            
          </ul>
        </div>


        <input
          type="text"
          class="form-control border-start"
          placeholder="{{ 'Search' | translate }}..."
          formControlName="textSearch"
          id="TextQSearch"
          (keydown.enter)="search()"
          (input)="auto($event.target.value)"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />

        <div class="input-group-append">
          <a role="button" class="a-walai color-orange" (click)="search()">
            <i
              class="bi bi-search"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            ></i>
          </a>
        </div>

        <ul class="dropdown-menu">
          <li *ngFor="let item of listAuto" class="w-100 pt-2">
            <a class="dropdown-item" (click)="EnterSearch(item.word)">{{
              item.word
            }}</a>
          </li>
        </ul>
      </form>

      
    </div>
    <div class="d-row mt-4">
      <a
        class="btn btn-sm btn-primary text-white py-1 me-2 mt-2"
        [routerLink]="['/search/alphabetic']"
        ><i class="bi bi-search"></i> A-Z
        {{ "AlphabeticallySearch" | translate }}</a
      >
      <a
        class="btn btn-sm btn-primary text-white py-1 mt-2 "
        [routerLink]="['/search/advance']"
        ><i class="bi bi-search"></i> {{ "AdvancedSearch" | translate }}</a
      >
    </div>
  </div>


  
</div>

<div class="container container-walai" style="margin-top: 60px !important">
  <h1 class="w-100 h1-main">
    {{ "NewArrivals" | translate }}
    <!-- <a href="{{ baseUrl }}6" target="_blank"><i class="bi bi-rss"></i></a> -->
  </h1>
  <p class="w-100 p-main" style="margin-bottom: 30px">
    {{ "NewArrivalsDetail" | translate }}
  </p>
  <div
    class="row row-cols-2 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6"
  >
    <div
      class="col figure-bookcover"
      *ngFor="let b of bookListNew; let i = index"
    >
      <div *ngIf="i < 6">
        <div class="DivBookCover">
          <div class="text-center container-bookcover">
            <a href="bibitem?bibid={{ b.BIBID }}" target="_blank">
              <!-- <img
                alt="picture"
                src="{{ b.bookcoverurl }}"
                class="img-fluid custom-img-shadow max-height-180"
                onError="this.src='assets/images/bookcover/noimage.jpg'"
              /> -->
              <app-book-cover-share [bookCoverURL]="b.bookcoverurl" [id]="b.BIBID" [cssStyle]="'iimg-fluid custom-img-shadow max-height-180'"></app-book-cover-share>
            </a>
            <!-- <div class="centered">{{b.TITLESHOT}}</div> -->
          </div>
        </div>
        <h6 class="text-center mt-3">
          <a
            class="a-walai-book"
            href="bibitem?bibid={{ b.BIBID }}"
            target="_blank"
            >{{ b.TITLESHOT }}</a
          >
        </h6>
        <p class="text-center">
          <small>{{ b.AUTHOR }}</small>
        </p>
      </div>
    </div>
  </div>
  <div class="w-100" style="text-align: center">
    <!-- Button trigger modal -->
    <button
      type="button"
      id="btnNewBookModal"
      class="btn btn-light button-radius1 btn-walai-default btn-sm py-1"
      data-bs-toggle="modal"
      data-bs-target="#newbookModal"
    >
      {{ "SeeAll" | translate }}
    </button>
  </div>

  <!-- Modal หนังสือใหม่-->
  <div
    class="modal fade"
    id="newbookModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ "NewArrivals" | translate }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="row row-cols-2 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
          >
            <div
              class="col figure-bookcover"
              *ngFor="let b of bookListNew; let i = index"
            >
              <div class="DivBookCover">
                <div class="text-center container-bookcover">
                  <a href="bibitem?bibid={{ b.BIBID }}" target="_blank">
                    <!-- <img
                      alt="picture"
                      src="{{ b.bookcoverurl }}"
                      class="img-fluid custom-img-shadow max-height-180"
                      onError="this.src='assets/images/bookcover/noimage.jpg'"
                    /> -->
                    <app-book-cover-share [bookCoverURL]="b.bookcoverurl" [id]="b.BIBID" [cssStyle]="'img-fluid custom-img-shadow'"></app-book-cover-share>
                  </a>
                </div>
              </div>
              <h6 class="text-center mt-3">
                <a
                  class="a-walai-book"
                  href="bibitem?bibid={{ b.BIBID }}"
                  target="_blank"
                  >{{ b.TITLESHOT }}</a
                >
              </h6>
              <p class="text-center">
                <small>{{ b.AUTHOR }}</small>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--  หนังสือยอดนิยม-->
  <h1 class="w-100 h1-main mt-5">
    {{ "MostPopular" | translate }}
    <!-- <a href="{{ baseUrl }}4" target="_blank"><i class="bi bi-rss"></i></a> -->
  </h1>
  <p class="w-100 p-main" style="margin-bottom: 30px">
    {{ "Popular books, have you tried reading yet?" | translate }}
  </p>
  <div
    class="row row-cols-2 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6"
  >
    <div
      class="col figure-bookcover"
      *ngFor="let b of bookListTopPick; let i = index"
    >
      <div *ngIf="i < 6">
        <div class="DivBookCover">
          <div class="text-center container-bookcover">
            <a href="bibitem?bibid={{ b.BIBID }}" target="_blank">
              <!-- <img
                alt="picture"
                src="{{ b.bookcoverurl }}"
                class="img-fluid custom-img-shadow max-height-180"
                onError="this.src='assets/images/bookcover/noimage.jpg'"
              /> -->
              <app-book-cover-share [bookCoverURL]="b.bookcoverurl" [id]="b.BIBID" [cssStyle]="'img-fluid custom-img-shadow max-height-180'"></app-book-cover-share>
            </a>
          </div>
        </div>
        <h6 class="text-center mt-3">
          <a
            class="a-walai-book"
            href="bibitem?bibid={{ b.BIBID }}"
            target="_blank"
            >{{ b.TITLESHOT }}</a
          >
        </h6>
        <p class="text-center">
          <small>{{ b.AUTHOR }}</small>
        </p>
      </div>
    </div>
  </div>

  <div class="w-100" style="text-align: center">
    <button
      type="button"
      id="btnMostPopular"
      class="btn btn-light button-radius1 btn-walai-default btn-sm py-1"
      data-bs-toggle="modal"
      data-bs-target="#toppickModal"
    >
      {{ "SeeAll" | translate }}
    </button>
  </div>

  <!-- Modal หนังสือยอดนิยม-->
  <div
    class="modal fade"
    id="toppickModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ "MostPopular" | translate }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="row row-cols-2 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
          >
            <div
              class="col figure-bookcover"
              *ngFor="let b of bookListTopPick; let i = index"
            >
              <div class="DivBookCover">
                <div class="text-center container-bookcover">
                  <a href="bibitem?bibid={{ b.BIBID }}" target="_blank">
                    <!-- <img
                      alt="picture"
                      src="{{ b.bookcoverurl }}"
                      class="img-fluid custom-img-shadow max-height-180"
                      onError="this.src='assets/images/bookcover/noimage.jpg'"
                    /> -->
                    <app-book-cover-share [bookCoverURL]="b.bookcoverurl" [id]="b.BIBID" [cssStyle]="'img-fluid custom-img-shadow max-height-180'"></app-book-cover-share>
                  </a>
                </div>
              </div>
              <h6 class="text-center mt-3">
                <a
                  class="a-walai-book"
                  href="bibitem?bibid={{ b.BIBID }}"
                  target="_blank"
                  >{{ b.TITLESHOT }}</a
                >
              </h6>
              <p class="text-center">
                <small>{{ b.AUTHOR }}</small>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- End หนังสือยอดนิยม-->

  <!--  หนังสือวิวสูงสุด-->
  <h1 class="w-100 h1-main mt-5">
    {{ "TopView" | translate }}
    <!-- <a href="{{ baseUrl }}3" target="_blank"><i class="bi bi-rss"></i></a> -->
  </h1>
  <p class="w-100 p-main" style="margin-bottom: 30px">
    {{ "Top view, have you tried reading yet?" | translate }}
  </p>
  <div
    class="row row-cols-2 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6"
  >
    <div
      class="col figure-bookcover"
      *ngFor="let b of bookListTopView; let i = index"
    >
      <div *ngIf="i < 6">
        <div class="DivBookCover">
          <div class="text-center container-bookcover">
            <a href="bibitem?bibid={{ b.BIBID }}" target="_blank">
              <!-- <img
                alt="picture"
                src="{{ b.bookcoverurl }}"
                class="img-fluid custom-img-shadow max-height-180"
                onError="this.src='assets/images/bookcover/noimage.jpg'"
              /> -->
              <app-book-cover-share [bookCoverURL]="b.bookcoverurl" [id]="b.BIBID" [cssStyle]="'iimg-fluid custom-img-shadow max-height-180'"></app-book-cover-share>
            </a>
          </div>
        </div>
        <h6 class="text-center mt-3">
          <a
            class="a-walai-book"
            href="bibitem?bibid={{ b.BIBID }}"
            target="_blank"
            >{{ b.TITLESHOT }}</a
          >
        </h6>
        <p class="text-center">
          <small>{{ b.AUTHOR }}</small>
        </p>
      </div>
    </div>
  </div>

  <div class="w-100" style="text-align: center">
    <button
      type="button"
      id="btnTopView"
      class="btn btn-light button-radius1 btn-walai-default btn-sm py-1"
      data-bs-toggle="modal"
      data-bs-target="#topviewModal"
    >
      {{ "SeeAll" | translate }}
    </button>
  </div>

  <!-- Modal หนังสือวิวสูงสุด-->
  <div
    class="modal fade"
    id="topviewModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ "TopView" | translate }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="row row-cols-2 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
          >
            <div
              class="col figure-bookcover"
              *ngFor="let b of bookListTopView; let i = index"
            >
              <div class="DivBookCover">
                <div class="text-center container-bookcover">
                  <a href="bibitem?bibid={{ b.BIBID }}" target="_blank">
                    <!-- <img
                      alt="picture"
                      src="{{ b.bookcoverurl }}"
                      class="img-fluid custom-img-shadow max-height-180"
                      onError="this.src='assets/images/bookcover/noimage.jpg'"
                    /> -->
                    <app-book-cover-share [bookCoverURL]="b.bookcoverurl" [id]="b.BIBID" [cssStyle]="'img-fluid custom-img-shadow'"></app-book-cover-share>
                  </a>
                </div>
              </div>
              <h6 class="text-center mt-3">
                <a
                  class="a-walai-book"
                  href="bibitem?bibid={{ b.BIBID }}"
                  target="_blank"
                  >{{ b.TITLESHOT }}</a
                >
              </h6>
              <p class="text-center">
                <small>{{ b.AUTHOR }}</small>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- End หนังสือวิวสูงสุด-->

  <h1 class="w-100 h1-main mt-5">
    {{ LibrarianSuggestText | translate }}
    <!-- <a href="{{ baseUrl }}5" target="_blank"><i class="bi bi-rss"></i></a> -->
  </h1>
  <p class="w-100 p-main" style="margin-bottom: 30px">
    {{ LibrarianSuggestDescText | translate }}
  </p>
  <div
    class="row row-cols-2 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6"
  >
    <div
      class="col figure-bookcover"
      *ngFor="let b of bookListLibSuggest; let i = index"
    >
      <div *ngIf="i < 6">
        <div class="DivBookCover">
          <div class="text-center container-bookcover">
            <a href="bibitem?bibid={{ b.BIBID }}" target="_blank">
              <app-book-cover-share [bookCoverURL]="b.bookcoverurl" [id]="b.BIBID" [cssStyle]="'img-fluid custom-img-shadow max-height-180'"></app-book-cover-share>
              <!-- <img
                alt="picture"
                src="{{ b.bookcoverurl }}"
                class="img-fluid custom-img-shadow max-height-180"
                onError="this.src='assets/images/bookcover/noimage.jpg'"
              /> -->
            </a>
            <!-- <div class="bookcover-menu-top-right">
            <app-collection [bibid]="b.BIBID" [page]="index"></app-collection> 
            <a class="float-right"><i class="far fa-heart"></i> </a>
          </div> -->
          </div>
        </div>
        <h6 class="text-center mt-3">
          <a
            class="a-walai-book"
            href="bibitem?bibid={{ b.BIBID }}"
            target="_blank"
            >{{ b.TITLESHOT }}</a
          >
        </h6>
        <p class="text-center">
          <small>{{ b.AUTHOR }}</small>
        </p>
      </div>
    </div>
  </div>

  <div class="w-100" style="text-align: center">
    <!-- Button trigger modal -->
    <button
      type="button"
      id="btnLibrarianSuggest"
      class="btn btn-light button-radius1 btn-walai-default btn-sm py-1"
      data-bs-toggle="modal"
      data-bs-target="#LibSuggestModal"
    >
      {{ "SeeAll" | translate }}
    </button>
  </div>

  <!-- Modal บรรณารักษ์แนะนำ-->
  <div
    class="modal fade"
    id="LibSuggestModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ LibrarianSuggestText | translate }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="row row-cols-2 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5"
          >
            <div
              class="col figure-bookcover"
              *ngFor="let b of bookListLibSuggest; let i = index"
            >
              <div class="DivBookCover">
                <div class="text-center container-bookcover">
                  <a href="bibitem?bibid={{ b.BIBID }}" target="_blank">
                    <app-book-cover-share [bookCoverURL]="b.bookcoverurl" [id]="b.BIBID" [cssStyle]="'img-fluid custom-img-shadow'"></app-book-cover-share>
                    <!-- <img
                      alt="picture"
                      src="{{ b.bookcoverurl }}"
                      class="img-fluid custom-img-shadow"
                      onError="this.src='assets/images/bookcover/noimage.jpg'"
                    /> -->
                  </a>
                  <!-- <div class="bookcover-menu-top-right"> -->
                  <!-- <app-collection [bibid]="b.BIBID" [page]="index"></app-collection> -->
                  <!-- <a class="float-right"><i class="far fa-heart"></i> </a> -->
                  <!-- </div> -->
                </div>
              </div>
              <h6 class="text-center mt-3">
                <a
                  class="a-walai-book"
                  href="bibitem?bibid={{ b.BIBID }}"
                  target="_blank"
                  >{{ b.TITLESHOT }}</a
                >
              </h6>
              <p class="text-center">
                <small>{{ b.AUTHOR }}</small>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
<div *ngIf="categorys.length>0">
  <h1 class="w-100 h1-main mt-5">{{ "BrowsingCategory" | translate }}</h1>
  <p class="w-100 p-main" style="margin-bottom: 30px">
    {{ "BrowsingCategorySub" | translate }}
  </p>
  <!-- categorys -->
  <!-- <div class="card-group">
  <div class="card" *ngFor="let cat  of categorys">
    <img class="card-img-top" src="{{cat.CATIMG}}" alt="">
    <div class="card-body">
      <h5 class="card-title">{{cat.CATNAMETHAI}}</h5>
      <h6 class="card-subtitle mb-2 text-muted">{{cat.CATNAMEENG}}</h6>
      <p class="card-text">{{cat.DESCRIPTION}}</p>
      <a href="#" class="card-link">Browse {{cat.COUNT}} Sub catagorys</a>
    </div>
  </div>
</div> -->
  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div
      class="col mb-4"
      *ngFor="let cat of categorys | slice: 0:9; let i = index"
    >
      <div class="card h-100">
        <!-- <img src="{{cat.CATIMG}}"  class="card-img-top" style="max-height: 200px;" alt="..."> 
        onError="this.src='assets/images/no-img.jpg'"-->
        <img
          [src]="cat.CATIMG"
          (error)="imagerror($event)"
          class="card-img-top"
          style="max-height: 200px"
        />
        <!-- <img src="{{cat.CATIMG}}" 
      class="card-img-top" style="max-height: 200px;" alt="..."> -->

        <div class="card-body">
          <h5 class="card-title">{{ cat.CATNAMETHAI }}</h5>
          <h6 class="card-subtitle mb-2 text-muted">({{ cat.CATNAMEENG }})</h6>

          <p class="card-text">{{ cat.DESCRIPTION.substring(0, 200) }} ...</p>
          <a
            href="subcategory?cateid={{ cat.CATEID }}"
            class="btn btn-primary btn-sm"
            ><i class="bi bi-card-list"></i> Browse {{ cat.COUNT }} Sub
            categorys</a
          >
        </div>
        <!-- <div class="card-footer">
        <small class="text-muted">Browse {{cat.COUNT}} Sub catagorys</small>
      </div> -->
      </div>
    </div>
  </div>
  <div class="w-100" style="text-align: center" >
    <!-- Button trigger modal -->
    <button
      type="button"
      id="btnLibrarianSuggest"
      class="btn btn-light button-radius1 btn-walai-default btn-sm py-1"
      data-bs-toggle="modal"
      data-bs-target="#BrowsingCategory"
    >
      {{ "SeeAll" | translate }}
    </button>
  </div>
</div>
  <!-- Modal BrowsingCategory-->
  <div
    class="modal fade"
    id="BrowsingCategory"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ "BrowsingCategory" | translate }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div
              class="col-4 mb-4"
              *ngFor="let cat of categorys; let i = index"
            >
              <div class="card h-100">
                <!-- <img src="{{cat.CATIMG}}"  class="card-img-top" style="max-height: 200px;" alt="..."> -->
                <!-- onError="this.src='assets/images/no-img.jpg'" -->
                <img
                  [src]="cat.CATIMG"
                  (error)="imagerror($event)"
                  class="card-img-top"
                  style="max-height: 200px"
                />
                <!-- <img src="{{cat.CATIMG}}" 
              class="card-img-top" style="max-height: 200px;" alt="..."> -->

                <div class="card-body">
                  <h5 class="card-title">{{ cat.CATNAMETHAI }}</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    ({{ cat.CATNAMEENG }})
                  </h6>

                  <p class="card-text">
                    {{ cat.DESCRIPTION.substring(0, 200) }} ...
                  </p>
                  <a
                    href="subcategory?cateid={{ cat.CATEID }}"
                    class="btn btn-primary"
                    >Browse {{ cat.COUNT }} Sub catagorys</a
                  >
                </div>
                <!-- <div class="card-footer">
                <small class="text-muted">Browse {{cat.COUNT}} Sub catagorys</small>
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  

<app-tag-cloud></app-tag-cloud>

  <h1 class="w-100 h1-main mt-5 mb-3" *ngIf="searchtools.length>0">Searching Tools</h1>
  <div *ngIf="searchtools.length > 0" class="col-12 px-3 mt-4">
    <div class="row row-cols-1 row-cols-lg-3 justify-content-between border rounded-3 p-3 gy-2 mx-auto" >
      <div *ngFor="let st of searchtools">
        <a href="{{st.IMG_LINKTO}}" target="_blank" role="button" class="a-walai-book a-walai-underline">
          <img src="{{st.IMG_URL}}" onError="this.src='assets/images/bookcover/noimage.jpg'" class="w-auto img-thumbnail me-2 img-max-width-searchtool" alt="{{st.IMG_DESC}}">
          <span class="wl-fs-18 text-break">{{st.IMG_DESC}}</span>
        </a>
        
      </div> 
    </div>
  
  </div>
  
  <h1 class="w-100 h1-main mt-5" *ngIf="EducationalResources.length>0" > Educational Resources</h1>
  <div *ngIf="EducationalResources.length" class="col-12 ps-3 ps-lg-4 mt-4">
    <div class="row gap-3 gap-lg-4 align-items-center ps-4">
      <div *ngFor="let edu of EducationalResources" class="col-auto">
        <a role="button" href="{{edu.IMG_LINKTO}}" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top"  title="{{edu.IMG_DESC}}">
          <img src="{{edu.IMG_URL}}" class="img-fluid img-max-width" onError="this.src='assets/images/bookcover/noimage.jpg'"
          alt="{{edu.IMG_DESC}}">
        </a>
      </div>
    </div>
  
  </div>  
  

  
  

</div>
