<div class="container  container-walai" style="margin-top: 60px!important;">
    <h1 class="w-100 h1-main" >OPAC Ultimate Version</h1>
  
    <div
      class="row row-cols-12 "
  >
  <div class="bd-example">
    <dl>
      <dt>16 Jul 2024</dt>
      <dd>1. Update Angular Version (V.17)</dd>
    </dl>
    <dl>
      <dt>17 Jul 2023</dt>
      <dd>1. Add word suggester</dd>
      <dd>2. Add keyword opertors (AND, OR, NOT)</dd>
      <dd>3. Add keyword type in Book search</dd>
      <dd>4. Add Share Facebook</dd>
    </dl>
    <dl>
      <dt>03 Jul 2023</dt>
      <dd>1. Add Tag cloud</dd>
      <dd>2. Add suggest friend</dd>
    </dl>
    <dl>
      <dt>15 June 2023</dt>
      <dd>1. Fix bug check valid bib parameter</dd>
      <dd>2. Add export MARC ISO2709</dd>
      <dd>3. Add admin dashboard</dd>
      <dd>4. Add bookCover from another source</dd>
    </dl>
    <dl>
      <dt>22 May 2023</dt>
      <dd>1. Fix bug check cover image url</dd>
      <dd>2. Fix bug Delivery service</dd>
    </dl>
    <dl>
      <dt>29 Nov 2022</dt>
      <dd>1. Fix bug Hold function in bibitem</dd>
      <dd>2. Display less item in bibitem</dd>
    </dl>
    <dl>
      <dt>3 March 2022</dt>
      <dd>1. Fix bug logo size</dd>
      <dd>2. Add Delivery service</dd>
      <dd>3. Add Virtual Bookshelf service</dd>
    </dl>

    <dl>
      <dt>15 June 2022</dt>
      <dd>1. Fix bug logo size</dd>
      <dd>2. Add delivery service</dd>
      <dd>3. Add Virtual Bookshelf service</dd>
    </dl>
  
  </div>
    </div>
</div>
