import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { environment } from 'src/environments/environment';
import {
  callNoResponse,
  CallNo,
  locationCollectionResponse,
  Keywords,
} from 'src/app/models/walai';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';
import { catchError, switchMap } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  locationList: any[];
  langs: any[];
  materialTypes: any[];
  callNoLC: CallNo[];
  callNoDC: CallNo[];
  callNoLocal: CallNo[];
  listBib: any = [];
  bibStr: any = '';
  locationCollectionResponse: locationCollectionResponse[];

  callNoType: string = '';
  qsearchForm: UntypedFormGroup;
  locationNameMain: string;
  locationIDMain: string;
  currentUser: any[] = [];
  brandheading: string;
  isAdmin: string = '0';
  moreLang: boolean = false;
  keyword: Keywords;
  Qkeyword: string;
  CurrentLang: string = 'en';
  MemberImg = '';
  logo = 'assets/images/logo_loading.png';
  // translate: TranslateService;

 logoSubcription: Subscription = new Subscription();
  ngOnDestroy(): void {
    if (this.logoSubcription) {
      this.logoSubcription.unsubscribe();
    }
  }

  constructor(
    private homeService: HomeService,
    private route: Router,
    public translate: TranslateService,
    private data: DataService,
    private adminService: AdminService
  ) {



   
    // this.adminService.getLogoBanner('logo').subscribe(async (data: any) => {
    //   this.logo = data;
    // });

    this.logoSubcription =  this.adminService.getLogoBannerPipe('logo')
    .pipe(
      switchMap(async (res: any) => {
        switch (res.status) {
          case 200:
            this.logo =res.body
            break;
          default:
        }
      }),
      catchError((e) => {
        return of([]);
      })
    )
    .subscribe();

    if (localStorage.getItem('listBib') != null) {
      this.bibStr = localStorage.getItem('listBib');
      var splitted = this.bibStr.split(',');
      splitted.forEach((element: any) => {
        if (element != '') {
          this.listBib.push(element);
        }
      });
    }
    this.data.updateUserData(this.listBib);
    this.data.currentBibList.subscribe(
      (countHold) => (this.listBib = countHold)
    );

    // this.translate = translate;
    this.translate.setDefaultLang('en');
    if (localStorage.getItem('lang')) {
      this.translate.use(localStorage.getItem('lang'));
      this.CurrentLang = localStorage.getItem('lang');
    } else {
      this.translate.use('en');
      this.CurrentLang = 'en';
    }
  }

  // translate: TranslateService;
  // constructor(translate: TranslateService) {
  //         this.translate = translate;
  //         this.translate.setDefaultLang('en');
  //     }
  // changeLanguage(lang: string) {
  //         this.translate.use(lang);
  //     }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.CurrentLang = lang;
    localStorage.setItem('lang', lang);
  }
  export() {
    this.route.navigate(['export']);
  }
  ngOnInit(): void {
    this.data.currentlogo.subscribe((logo) => (this.logo = logo));

    this.data.currentMemberImg.subscribe((data) => (this.MemberImg = data));

    this.currentUser =
      JSON.parse(localStorage.getItem('WALAIcurrentUser')) || [];
    if (this.currentUser.length > 0) {
      this.isAdmin = JSON.parse(
        localStorage.getItem('WALAIcurrentUser')
      )[0].ADMINFLAG;

      this.MemberImg = this.currentUser[0].PATHPIC;
    }

    this.brandheading = '';
    this.homeService.getParameter('brandheadingEN').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        this.brandheading = VALUE[0].VALUE;
      }
    });

    // this.currentUser=JSON.parse(localStorage.getItem('WALAIcurrentUser'));;
    // alert(  this.currentUser[0].USERID);
    // Fetch

    if (localStorage.getItem('locationIDMain')) {
      this.locationNameMain = localStorage.getItem('locationNameMain');
      this.locationIDMain = localStorage.getItem('locationIDMain');
    } else {
      if (localStorage.getItem('locationIDMain') == '') {
        this.locationNameMain = 'ทุกสาขา';
        this.locationIDMain = '';
        localStorage.setItem('locationNameMain', 'ทุกสาขา');
        localStorage.setItem('locationIDMain', '');
      } else {
        if (environment.defaultLocationID != '') {
          //กรณีที่ห้องสมุดมีหลายสาขาและต้องการให้มีค่าตั้งต้นเป็นสาขาที่ต้องการ

          localStorage.setItem(
            'locationNameMain',
            environment.defaultLocationName
          );
          localStorage.setItem('locationIDMain', environment.defaultLocationID);
          this.locationNameMain = localStorage.getItem('locationNameMain');
          this.locationIDMain = localStorage.getItem('locationIDMain');
        }
      }
    }

    this.getLocation();

    this.browse();

    this.qsearchForm = new UntypedFormGroup({
      textSearch: new UntypedFormControl(),
    });
  }

  getLocation() {
    this.homeService.getLocation().subscribe((location: any) => {
      this.locationList = location;
    });

    if (
      localStorage.getItem('locationNameMain') === null ||
      localStorage.getItem('locationNameMain') === ''
    ) {
      // กรณีไม่มีการเลือก Location ให้ตั้งเป็นทุกสาขา (ค่าว่าง)
      // Store
      this.locationNameMain = 'ทุกสาขา';
      this.locationIDMain = '';
      localStorage.setItem('locationNameMain', 'ทุกสาขา');
      localStorage.setItem('locationIDMain', '');
    }
  }

  ChangeLocation(id, name) {
    this.locationNameMain = name;
    this.locationIDMain = id;

    // Store
    localStorage.setItem('locationNameMain', name);
    localStorage.setItem('locationIDMain', id);
    window.location.reload();
  }

  SiginOut() {
    this.currentUser = [];
    localStorage.setItem('WALAIcurrentUser', JSON.stringify(this.currentUser));
    this.route.navigateByUrl('');
  }

  browse() {
    this.getLang();
    this.getMaterialTypes();
    this.getCallNumber();
    this.getCollection();
  }

  getLang() {
    this.homeService.getLang().subscribe((langs: any) => {
      this.langs = langs;
    });
  }

  getMaterialTypes() {
    this.homeService.getMaterialType().subscribe((getMaterialType: any) => {
      this.materialTypes = getMaterialType;
    });
  }

  showMore() {
    this.moreLang = true;
  }
  showLess() {
    this.moreLang = false;
  }

  getCallNumber() {
    this.homeService
      .getCallNumber()
      .subscribe((callNoResponse: callNoResponse) => {
        this.callNoDC = callNoResponse.callNoDC;
        this.callNoLC = callNoResponse.callNoLC;
        this.callNoLocal = callNoResponse.callNoLocal;
        this.callNoType = callNoResponse.type;
      });
  }

  getCollection() {
    this.homeService
      .getCollection()
      .subscribe((locationCollectionResponse: locationCollectionResponse[]) => {
        this.locationCollectionResponse = locationCollectionResponse;
      });
  }

  // สืบค้นแบบเร่งด้าน ในที่นี้คือการสืบค้นแบบ All key หรือทุกประเภท (มีการตั้งค่าไว้ใน Solr ว่า All ในที่นี่คือ Tag อะไรบ้าง)
  search() {
    if (
      this.qsearchForm.controls.textSearch.value != '' &&
      this.qsearchForm.controls.textSearch.value != null
    ) {
      this.EnterSearch(encodeURIComponent(this.qsearchForm.controls.textSearch.value));
    }
  }

  EnterSearch(keyWord: string) {
    if (this.locationIDMain != '') {
      // กรณีมีการระบุสาขา ซึ่งถ้าเป็นค่าว่าง จะไม่ระบุสาขา
      this.Qkeyword =
        'results?Ntk=KEYWORD|LOCATION&Ntt=' +
        keyWord +
        '|' +
        this.locationIDMain;
    } else {
      this.Qkeyword = 'results?Ntk=KEYWORD&Ntt=' + keyWord;
    }
    window.location.href = this.Qkeyword;
  }

}
