<button
  type="button"
  class="btn btn-primary btn-sm py-1 fw-light shadow me-2"
  data-bs-toggle="modal"
  data-bs-target="#suggestModal"
  *ngIf="USERID != ''"
>
  Suggest friends <i class="bi bi-chat-text"></i>
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="suggestModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Suggest friends</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form class="input-group" [formGroup]="qsearchForm">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'Search friend' | translate }}..."
            formControlName="textSearch"
            id="TextQSearch"
          
            (input)="auto($event.target.value)"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <!-- <div class="input-group-append">
                  <button role="button" class="btn btn-primary ms-1" (click)="search()">
                    <i class="bi bi-search"></i>
                  </button>
                </div> -->

          <ul class="dropdown-menu">
            <li *ngFor="let item of friends" class="">
              <!-- <a class="dropdown-item" (click)="Select(item)">{{
                      item.NAME
                    }}</a> -->

              <div class="d-flex text-body-secondary pt-3 px-3">
                <img
                  onError="this.src='assets/images/member-icon.jpg'"
                  [src]="item.IMAGES"
                  class="bd-placeholder-img flex-shrink-0 me-2 rounded"
                  width="32"
                  height="32"
                />
                <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                  <div class="d-flex justify-content-between">
                    <strong class="text-gray-dark">{{ item.NAME }}</strong>

                    <button
                      (click)="Select(item)"
                      type="button"
                      class="btn btn-outline-success btn-sm"
                    >
                      <i class="bi bi-check-circle-fill"></i>
                    </button>
                  </div>
                  <span class="d-block">{{ item.EMAIL }}</span>
                </div>
              </div>
            </li>
          </ul>
        </form>
        <form *ngIf="friend.USERID != 0" [formGroup]="suggestForm">
          <div class="mb-3 mt-3">
            <div class="d-flex text-body-secondary pt-3 px-3">
              <img
                onError="this.src='assets/images/member-icon.jpg'"
                [src]="friend.IMAGES"
                class="bd-placeholder-img flex-shrink-0 me-2 rounded"
                width="32"
                height="32"
              />
              <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                <div class="d-flex justify-content-between">
                  <strong class="text-gray-dark">{{ friend.NAME }}</strong>
                </div>
                <span class="d-block">{{ friend.EMAIL }}</span>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label"
              >Comment</label
            >
            <textarea   formControlName="textComment"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button (click)="Confirm()"  type="button" class="btn btn-primary">Suggest</button>
      </div>
    </div>
  </div>
</div>
